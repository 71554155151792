import User from '@/models/User';

export default class Participant extends User {
  constructor(user) {
    super(user);
    this.statistics = user.statistics;
    this.achievedBadgeCount = user.achievedBadgeCount;
    this.corporation = user.corporation;
    this.organizationUserCount = user.organizationUserCount;
  }
}
