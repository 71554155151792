<template>
  <div class="modal">
    <div class="close-icon-wrapper">
      <p class="close-icon" @click="close" />
    </div>
    <h1 class="modal-title">集合研修実績を登録する</h1>
    <p v-if="isFetching" class="loading-message mt-5">読み込み中…</p>
    <p v-else-if="isError" class="error-message mt-5">
      データの取得に失敗しました
    </p>
    <div v-else class="group-learning-container">
      <table>
        <div class="description-wrapper">
          <p class="modal-description">
            {{ modalDescription }}
          </p>
        </div>
        <div class="group-learning-info">
          <div class="data-time-wrapper">
            <h3 class="sub-title">・開催日時</h3>
            <p class="group-learning-date-time">
              {{ startDateTime }}
            </p>
          </div>
          <div class="data-wrapper">
            <h3 class="sub-title">・開催場所</h3>
            <p class="group-learning-data">{{ location }}</p>
          </div>
          <div class="data-wrapper">
            <h3 class="sub-title-long">・研修使用アカウント</h3>
            <p class="group-learning-data">{{ mainParticipantName }}</p>
          </div>
          <p v-if="payload.mainParticipant" class="main-participant-caution">
            ※研修実績の重複付与防止のため、研修実績は付与されません。
          </p>
        </div>
        <div class="lesson-plan-info">
          <div class="data-wrapper">
            <h3 class="sub-title">・視聴履歴</h3>
            <p class="lesson-plan-data">
              {{ `${lessonPlan.prefix} ${lessonPlan.name}` }}
            </p>
          </div>
          <div class="data-wrapper">
            <h3 class="sub-title">・視聴時間</h3>
            <p class="lesson-plan-data">{{ totalLessonLength }}</p>
          </div>
          <h3 class="report-notice">・参加者へのレポート提出依頼</h3>
        </div>
        <div
          v-if="
            isEdited &&
            !isMainParticipantChanged &&
            additionalUsers.length === 0 &&
            removedUsers.length === 0
          "
          class="no-change-users-wrapper"
        >
          <p class="no-change-users">
            ※研修使用アカウント・参加者の変更がないため、研修実績が追加で付与されることはありません
          </p>
        </div>
        <div v-if="additionalUsers.length > 0" class="register-users-container">
          <div class="list-info">
            <h2 class="list-title">追加対象</h2>
            <p class="count">
              {{ `${additionalUsers.length}件` }}
            </p>
            <span class="description"
              >対象参加者の研修実績が追加されます。</span
            >
          </div>
          <table class="user-list">
            <tr v-for="user in additionalUsers" :key="user.id" class="user">
              <th>{{ user.roleText }}</th>
              <td>{{ user.fullName }}</td>
            </tr>
          </table>
        </div>
        <div v-if="removedUsers.length > 0" class="remove-users-container">
          <div class="list-info">
            <h2 class="list-title">削除対象</h2>
            <p class="count">
              {{ `${removedUsers.length}件` }}
            </p>
            <span class="description"
              >対象参加者の研修実績が削除されます。</span
            >
          </div>
          <table class="user-list">
            <tr v-for="user in removedUsers" :key="user.id" class="user">
              <th>{{ user.roleText }}</th>
              <td>{{ user.fullName }}</td>
            </tr>
          </table>
        </div>
      </table>
    </div>
    <div v-if="isRequestError" class="request-error-message">
      実績の登録に失敗しました
    </div>
    <div class="button-wrapper">
      <SLButton
        black
        text="キャンセル"
        class="cancel-button"
        :on-click="close"
      />
      <SLButton
        type="submit"
        text="登録する"
        class="register-button"
        :on-click="onSubmit"
        :disabled="isSubmitting"
      />
    </div>
  </div>
</template>

<script>
import {
  dateToString,
  formatUnixTimeMillisecondsToHourMinuteString,
} from '@/libs/timeFormatter';
import repository from '@/api/apiRepositoryFactory';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export default {
  name: 'RegisterGroupLearningModal',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lessonPlan: null,
      isFetching: true,
      isError: false,
      isRequestError: false,
      isSubmitting: false,
    };
  },
  computed: {
    selectedParticipants() {
      return this.payload.selectedParticipants;
    },
    modalDescription() {
      const startDate = dateToString(this.payload.startsAt);
      const startTime = formatUnixTimeMillisecondsToHourMinuteString(
        this.payload.startsAt,
      );
      const endTime = formatUnixTimeMillisecondsToHourMinuteString(
        this.payload.endsAt,
      );

      return `「${startDate} ${startTime}〜${endTime}」開催の研修実績を登録します。`;
    },
    startDateTime() {
      const startDate = dateToString(this.payload.startsAt);
      const startTime = formatUnixTimeMillisecondsToHourMinuteString(
        this.payload.startsAt,
      );
      const endTime = formatUnixTimeMillisecondsToHourMinuteString(
        this.payload.endsAt,
      );

      return `${startDate} ${startTime}〜${endTime}`;
    },
    location() {
      return this.payload.location ? this.payload.location : '未設定';
    },
    mainParticipantName() {
      return this.payload.mainParticipant?.fullName ?? '未設定';
    },
    totalLessonLength() {
      // 24時間以上の表示が必要な為、dayjsのdurationを使って計算する
      dayjs.extend(duration);
      const durationObject = dayjs.duration(
        this.lessonPlan.totalLessonLengthSeconds,
        'seconds',
      );

      const hours = Math.floor(durationObject.asHours());
      const minutes = ('0' + durationObject.minutes()).slice(-2);
      const seconds = ('0' + durationObject.seconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
    },
    isEdited() {
      return !!this.payload.groupLearningId;
    },
    isMainParticipantChanged() {
      // 研修使用アカウントが変更されたかどうか
      if (!this.isEdited) {
        return false;
      }

      return (
        this.payload.mainParticipant?.id !== this.payload.oldMainParticipant?.id
      );
    },
    /*
    // 新規作成時は選択されたユーザー(研修使用アカウントを除いた)全員を返す
    // 編集時は既存データと比較し、新たに追加されたユーザーを特定する
    */
    additionalUsers() {
      if (!this.isEdited) {
        return this.filteredParticipants;
      }

      // 既存参加者IDリスト
      const oldParticipantIds = this.payload.oldParticipants.map(
        (oldParticipant) => oldParticipant.id,
      );

      const addedUsers = this.filteredParticipants.filter(
        (participant) => !oldParticipantIds.includes(participant.id),
      );

      // 研修使用アカウントが変更された場合、旧研修使用アカウントも追加ユーザーとして加える
      if (this.isMainParticipantChanged && this.payload.oldMainParticipant) {
        addedUsers.push(this.payload.oldMainParticipant);
      }

      return addedUsers;
    },
    // 現在の研修使用アカウントを除いた参加者を返す
    filteredParticipants() {
      if (!this.payload.mainParticipant) {
        return this.payload.selectedParticipants;
      }

      return this.payload.selectedParticipants.filter(
        (user) => user.id !== this.payload.mainParticipant.id,
      );
    },
    /*
    // 新規作成時は、空配列を返す
    // 編集時は、既存のデータと比較して、削除されたユーザーを算出する
    */
    removedUsers() {
      if (!this.isEdited) {
        return [];
      }

      // 新規参加者(研修使用アカウントを含む)のIDリストを作成
      const selectedParticipantIds = this.payload.selectedParticipants.map(
        (participant) => participant.id,
      );

      const removedUsers = this.payload.oldParticipants.filter(
        (participant) => !selectedParticipantIds.includes(participant.id),
      );

      // 既存参加者が、研修使用アカウントに変更された場合、研修実績削除対象に加える
      if (this.isMainParticipantChanged && this.payload.mainParticipant) {
        removedUsers.push(this.payload.mainParticipant);
      }

      return removedUsers;
    },
  },
  async mounted() {
    await this.getLessonPlan();
    this.isFetching = false;
  },
  methods: {
    async getLessonPlan() {
      const lessonPlanApi = repository.lessonPlans();

      try {
        this.lessonPlan = await lessonPlanApi.getForOrganizer({
          id: this.payload.lessonPlanId,
        });
      } catch (error) {
        this.isError = true;
        console.warn(error);
      }
    },
    close() {
      this.$store.dispatch('modal/close');
    },
    async onSubmit() {
      try {
        await this.submitGroupLearning();
        this.goToNextPage();
      } catch (error) {
        console.warn(error);
      }
    },
    async submitGroupLearning() {
      this.isSubmitting = true;

      try {
        if (this.isEdited) {
          await this.updateGroupLearning();
        } else {
          await this.createGroupLearning();
        }

        this.$store.commit('pages/clearSelectedUsersForGroupLearning');
        this.close();
      } catch (error) {
        this.isRequestError = true;
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },
    async createGroupLearning() {
      const groupLearningApi = repository.groupLearnings();
      const participantIds = this.payload.selectedParticipants.map(
        (participant) => participant.id,
      );

      try {
        await groupLearningApi.createByLessonPlanForOrganizer({
          lessonPlanId: this.payload.lessonPlanId,
          startsAt: this.payload.startsAt,
          endsAt: this.payload.endsAt,
          location: this.payload.location,
          participantIds: participantIds,
          mainParticipantId: this.payload.mainParticipant?.id ?? null,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async updateGroupLearning() {
      const groupLearningApi = repository.groupLearnings();
      const participantIds = this.payload.selectedParticipants.map(
        (participant) => participant.id,
      );

      try {
        await groupLearningApi.updateForOrganizer({
          id: this.payload.groupLearningId,
          startsAt: this.payload.startsAt,
          endsAt: this.payload.endsAt,
          location: this.payload.location,
          participantIds: participantIds,
          mainParticipantId: this.payload.mainParticipant?.id ?? null,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    goToNextPage() {
      // 編集時は、編集した集合研修実績の詳細画面へ遷移する
      if (this.isEdited) {
        this.$router.push({
          name: 'GroupLearningDetail',
          params: {
            groupLearningId: this.payload.groupLearningId,
          },
        });
      } else {
        // 新規作成時は、研修計画の詳細画面へ遷移する
        this.$router.push({
          name: 'LessonPlanDetail',
          params: {
            lessonPlanId: this.payload.lessonPlanId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 25px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #333;
  border-radius: 10px;
  background-color: #fff;
  z-index: 10010;

  @include mq(md) {
    padding: 16px;
    width: 95%;
    top: 3%;
  }

  .close-icon-wrapper {
    position: relative;
    width: 100%;
    height: 30px;

    .close-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url('~@/assets/img/close_icon.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .modal-title {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 16px;
    }
  }

  .loading-message,
  .error-message {
    text-align: center;
  }

  .request-error-message {
    margin-left: 32px;
    width: 100%;
    text-align: left;
    font-weight: bold;
    color: red;
  }

  .group-learning-container {
    margin-top: 40px;
    width: 100%;
    height: 460px;
    overflow-y: auto;

    @include mq(md) {
      margin-top: 20px;
      height: 340px;
    }

    table {
      width: 94%;
      margin: 0 auto;
    }

    .description-wrapper {
      display: flex;
      justify-content: center;

      .modal-description {
        text-align: left;
      }
    }

    .group-learning-info,
    .lesson-plan-info,
    .no-change-users-wrapper {
      margin-top: 30px;
      padding: 20px 24px;
      border: solid 1px #eee;
      border-radius: 5px;

      @include mq(md) {
        margin-top: 20px;
        border-radius: 10px;
      }

      .sub-title {
        min-width: 70px;
        font-weight: bold;
        font-size: 1rem;

        @include mq(md) {
          font-size: 1.2rem;
        }

        &-long {
          min-width: 140px;
          font-weight: bold;
          font-size: 1rem;

          @include mq(md) {
            font-size: 1.2rem;
          }
        }
      }

      .group-learning-date-time {
        margin-left: 14px;
        font-size: 0.9rem;
        letter-spacing: 1px;

        @include mq(md) {
          margin-left: 18px;
        }
      }

      .data-wrapper {
        margin-top: 10px;
        display: flex;

        &:first-child {
          margin-top: 0px;
        }

        @include mq(md) {
          flex-direction: column;
        }

        .group-learning-data,
        .lesson-plan-data {
          margin-left: 14px;
          width: calc(100% - 84px);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          font-size: 0.9rem;
          letter-spacing: 1px;

          @include mq(md) {
            margin-left: 18px;
          }
        }
      }

      .main-participant-caution {
        margin-left: 14px;
        color: red;
        font-size: 0.9rem;

        @include mq(md) {
          margin-left: 18px;
        }
      }

      .report-notice {
        margin-top: 10px;
        font-weight: bold;
        font-size: 1rem;

        @include mq(md) {
          font-size: 1.2rem;
        }
      }

      .no-change-users {
        font-weight: bold;
      }
    }

    .register-users-container,
    .remove-users-container {
      margin-top: 20px;

      @include mq(md) {
        margin-top: 40px;
      }

      .list-info {
        margin-left: 6px;
        display: flex;

        @include mq(md) {
          margin-left: 0px;
        }

        .list-title {
          font-weight: bold;
          font-size: 1rem;

          @include mq(md) {
            font-size: 0.9rem;
          }
        }

        .count {
          margin-left: 10px;
          font-weight: bold;

          @include mq(md) {
            margin-left: 6px;
          }
        }

        .description {
          margin-left: 14px;
          font-size: 0.9rem;
          font-weight: normal;

          @include mq(md) {
            margin-left: 10px;
          }
        }
      }

      .user-list {
        margin-top: 10px;
        padding: 10px 20px;
        width: 100%;
        border: solid 1px #eee;
        border-radius: 5px;

        @include mq(md) {
          margin-top: 6px;
          border-radius: 10px;
        }

        .user {
          margin-top: 8px;
          display: flex;

          &:first-child {
            margin-top: 10px;
          }

          th {
            width: 70px;
            text-align: left;
            font-weight: normal;
          }

          td {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }

  .button-wrapper {
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    .cancel-button,
    .register-button {
      width: 100px;

      @include mq(md) {
        width: 140px;
        height: 42px;
        font-size: 1.1rem;
      }

      &:last-child {
        margin-left: 20px;

        @include mq(md) {
          margin-left: 14px;
        }
      }

      &:disabled {
        cursor: wait;
        opacity: 0.3;
      }
    }
  }
}
</style>
