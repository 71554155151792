import axios from 'axios';

export default () => {
  // 目次（中分類）一覧を取得
  const getAll = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/mediumSections`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // ユーザーが受講可能な目次（中分類）の一覧を取得する
  const getAllForUser = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/mediumSections`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  return {
    getAll,
    getAllForUser,
  };
};
