export default {
  path: 'group-learnings',
  component: () =>
    import('@/views/staff/trainings/lessonPlans/groupLearnings/index.vue'),
  meta: {
    title: '集合研修実績',
    breadcrumb: null,
  },
  children: [
    {
      path: 'create',
      name: 'GroupLearningCreate',
      component: () =>
        import('@/views/staff/trainings/lessonPlans/groupLearnings/Create.vue'),
      props: true,
      meta: {
        isPublic: false,
        title: '集合研修実績の登録',
        desc: '集合研修実績の登録',
        breadcrumb: '集合研修実績の登録',
      },
    },
    {
      path: ':groupLearningId',
      component: () =>
        import(
          '@/views/staff/trainings/lessonPlans/groupLearnings/DetailIndex.vue'
        ),
      props: true,
      meta: {
        breadcrumb: null,
      },
      children: [
        {
          path: '',
          name: 'GroupLearningDetail',
          component: () =>
            import(
              '@/views/staff/trainings/lessonPlans/groupLearnings/Detail.vue'
            ),
          props: true,
          meta: {
            isPublic: false,
            title: '対象の集合研修',
            desc: '集合研修実績の詳細',
            breadcrumb: '対象の集合研修',
          },
        },
        {
          path: 'edit',
          name: 'GroupLearningEdit',
          component: () =>
            import(
              '@/views/staff/trainings/lessonPlans/groupLearnings/Edit.vue'
            ),
          props: true,
          meta: {
            isPublic: false,
            title: '集合研修実績の編集',
            desc: '集合研修実績の編集',
            breadcrumb: '集合研修実績の編集',
          },
        },
      ],
    },
  ],
};
