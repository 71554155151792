import repository from '@/api/apiRepositoryFactory';
import { fetchAllData } from '@/api/libs';

export const state = {
  user: null,
  corporation: null,
  organizations: [],
  organization: null, // メイン事業所
  organizationGroups: [],
  spoofedOrganizationId: null,
};

export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserInfo(
    state,
    { user, corporation, organizations, organization, organizationGroups },
  ) {
    state.user = user;
    state.corporation = corporation;
    state.organizations = organizations;
    state.organization = organization;
    state.organizationGroups = organizationGroups;
  },
  clearUserInfo(state) {
    state.user = null;
    state.corporation = null;
    state.organizations = [];
    state.organization = null;
    state.organizationGroups = [];
    state.spoofedOrganizationId = null;
  },
  setOrganization(state, organization) {
    state.organization = organization;
  },
  setOrganizationGroups(state, organizationGroups) {
    state.organizationGroups = organizationGroups;
  },
  setSpoofedOrganizationId(state, organizationId) {
    state.spoofedOrganizationId = organizationId;
  },
};

export const actions = {
  async login({ commit }, { code, password }) {
    await commit('userinfo/destroy', null, { root: true });
    const userApi = repository.users();

    await userApi.authorizeForUser({
      code,
      password,
      setsCookie: true,
    });

    const user = await userApi.getCurrentUser();
    await commit('setUser', user);
  },
  // なりすましログイン処理
  async spoofLogin({ commit, dispatch }, organizationId) {
    commit('setSpoofedOrganizationId', organizationId);

    await dispatch('getUserInfo');
  },
  // 簡易ログイン処理 (loginKeyを用いた認証)
  async simpleLogin({ commit }, loginKey) {
    await commit('userinfo/destroy', null, { root: true });
    const userApi = repository.users();

    await userApi.authorizeByLoginKeyForStudent({
      loginKey: loginKey,
      setsCookie: true,
    });

    const user = await userApi.getCurrentUser();
    await commit('setUser', user);
  },
  // ログアウト処理
  async logout({ commit }) {
    const userApi = repository.users();

    await userApi.clearAuthorizationCookieForUser();
    await commit('userinfo/destroy', null, { root: true });
    await commit('clearUserInfo');

    // cookieバナー表示に使用している為、cookieConsentのみセッションストレージに再保存する
    const cookieConsent = sessionStorage.getItem('cookieConsent');
    sessionStorage.clear();

    if (cookieConsent) {
      sessionStorage.setItem('cookieConsent', cookieConsent);
    }
  },
  // 現在の事業所に基づいて事業所グループを取得する
  async getOrganizationGroups({ commit, state }) {
    const organizationGroupApi = repository.organizationGroups();

    const organizationGroups = [];
    if (state.user.role === 'student') {
      const organizationGroupsForStudent =
        await organizationGroupApi.getAllByOrganizationForStudent({});
      organizationGroups.push(...organizationGroupsForStudent);
    } else {
      const organizationGroupsForOrganizer =
        await organizationGroupApi.getAllByOrganizationForOrganizer({
          organizationId: state.organization.id,
        });
      organizationGroups.push(...organizationGroupsForOrganizer);
    }

    commit('setOrganizationGroups', organizationGroups);
  },
  // ユーザー情報と関連情報を取得する
  async getUserInfo({ commit, dispatch, state }) {
    const userApi = repository.users();
    const corporationApi = repository.corporations();
    const organizationApi = repository.organizations();

    const user = await userApi.getCurrentUser();
    const corporation = await corporationApi.get();

    const organizations = [];

    if (user.role === 'student') {
      const organization = await organizationApi.getForStudent();
      organizations.push(organization);
    } else {
      const organizationsForOrganizer = await fetchAllData(
        organizationApi.getAllForOrganizer,
        {},
      );
      organizations.push(...organizationsForOrganizer);
    }

    let organization = null;
    if (state.spoofedOrganizationId) {
      organization = await organizations.find(
        (organization) => organization.id === state.spoofedOrganizationId,
      );

      if (!organization) {
        await dispatch('logout');
        return;
      }
    } else if (state.organization !== null) {
      // メイン事業所を設定済みの場合はそのまま使用する
      organization = state.organization;
    } else {
      organization = await organizationApi.getMain();
    }

    await commit('setUserInfo', {
      user,
      corporation,
      organizations,
      organization,
    });

    await dispatch('getOrganizationGroups');
  },
  // ユーザー情報を取得する
  async getUser({ commit }) {
    const userApi = repository.users();
    const user = await userApi.getCurrentUser();
    await commit('setUser', user);
  },
  // 事業所を選択する
  async selectOrganization({ commit, dispatch, state }, organizationId) {
    if (!organizationId) {
      return;
    }

    const organization = state.organizations.find(
      (organization) => organization.id === organizationId,
    );

    await commit('setOrganization', organization);
    await dispatch('getOrganizationGroups');
  },
};

export const getters = {
  isLogin(state) {
    return (
      !!state.user &&
      !!state.corporation &&
      !!state.organizations &&
      !!state.organization &&
      !!state.organizationGroups
    );
  },
  isFreeOrPauseUser(state, getters) {
    return (
      getters.isLogin &&
      (state.user.state === 'free' || state.user.state === 'pause')
    );
  },
  isOrganizer(state, getters) {
    return getters.isLogin && state.user.role === 'organizer';
  },
  isSpoofed(state) {
    return !!state.spoofedOrganizationId;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
