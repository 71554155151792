<template>
  <div id="update-all-participant-status-modal">
    <div class="close-icon-wrapper">
      <p class="close-icon" @click="close" />
    </div>
    <h2 class="modal-title">
      {{ `受講状況を${stateText}に変更しますか？` }}
    </h2>
    <p v-if="isFetching" class="status-message">読み込み中…</p>
    <p v-else-if="isError" class="status-message">データの取得に失敗しました</p>
    <div v-else-if="payload.participants.length > 0" class="mt-8 main">
      <div class="text-container">
        <p>
          「
          <PrefixIcon text="カスタム研修" black />
          {{
            `${payload.trainingEvent.name}」の受講状況を${stateText}に変更します。`
          }}
        </p>
        <div v-if="payload.selectState === 'upcoming'" class="mt-7">
          <p>
            未完了に変更すると、対象のレポートは削除されます。削除されたレポートは元に戻せません。
          </p>
          <p class="mt-5">未完了に変更しますか？</p>
        </div>
      </div>
      <div class="list-container">
        <b class="participant-count">{{
          `変更対象 ${payload.participants.length}人`
        }}</b>
        <table class="mt-4 participant-list">
          <tr
            v-for="participant in payload.participants"
            :key="participant.id"
            class="participant"
          >
            <th>{{ participant.roleText }}</th>
            <td>{{ participant.fullName }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="mt-6 button-wrapper">
      <label
        v-if="payload.selectState === 'upcoming'"
        class="caution-check"
        :class="{ checked: isCautionChecked }"
      >
        <input
          class="hidden-checkbox"
          type="checkbox"
          @click="isCautionChecked = !isCautionChecked"
        />
        レポートが削除されることを確認しました
      </label>

      <div class="mt-3 operate-buttons">
        <SLButton
          ref="cancelButton"
          class="cancel-button"
          black
          text="キャンセル"
          :on-click="close"
          tabindex="1"
        />
        <SLButton
          tabindex="2"
          text="変更する"
          class="update-button"
          :on-click="updateAllParticipantsState"
          :disabled="payload.selectState === 'upcoming' && !isCautionChecked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PrefixIcon from '@/components/common/PrefixIcon.vue';
import repository from '@/api/apiRepositoryFactory';

export default {
  name: 'UpdateParticipantsStatusForTrainingEventModal',
  components: {
    PrefixIcon,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFetching: true,
      isError: false,
      isCautionChecked: false,
    };
  },
  computed: {
    stateText() {
      return this.payload.selectState === 'upcoming' ? '未完了' : '完了';
    },
  },
  async mounted() {
    // モーダルを開いた時に、モーダル内にフォーカスする
    this.$refs.cancelButton.$el.focus();
    this.isFetching = false;
  },
  methods: {
    close() {
      this.$store.dispatch('modal/close');
    },
    async updateAllParticipantsState() {
      const trainingEventApi = repository.trainingEvents();

      try {
        if (this.payload.selectState === 'upcoming') {
          await trainingEventApi.updateAllUsersStateToNotCompletedForOrganizer({
            id: this.payload.trainingEvent.id,
          });
        } else {
          await trainingEventApi.updateAllUsersStateToCompletedForOrganizer({
            id: this.payload.trainingEvent.id,
          });
        }

        this.payload.getPageData();
        this.close();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#update-all-participant-status-modal {
  padding: 25px 35px 50px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #333;
  border-radius: 10px;
  background-color: #fff;
  z-index: 10010;

  @include mq(md) {
    padding: 16px;
    padding-bottom: 50px;
    width: 95%;
    top: 3%;
  }

  .close-icon-wrapper {
    position: relative;
    width: 100%;
    height: 30px;

    .close-icon {
      @include icon-modal-close;
    }
  }

  .modal-title {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 16px;
    }
  }

  .status-message {
    text-align: center;
    margin-bottom: 100px;
  }

  .main {
    width: 100%;
    max-height: 460px;
    overflow-y: auto;

    @include mq(md) {
      margin-top: 20px;
      max-height: 300px;
    }

    .participant-count {
      display: block;
    }

    .text-container {
      width: 94%;
      margin: 0 auto;
    }

    .list-container {
      width: 94%;
      margin: 0 auto;
      margin-top: 28px;

      .participant-list {
        padding: 10px 20px;
        width: 100%;
        border: solid 1px #eee;
        border-radius: 5px;

        @include mq(md) {
          margin-top: 6px;
          border-radius: 10px;
        }

        .participant {
          margin-top: 8px;
          display: flex;

          &:first-child {
            margin-top: 10px;
          }

          th {
            width: 70px;
            text-align: left;
            font-weight: normal;
          }

          td {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }

  .button-wrapper {
    margin-bottom: 30px;
    width: 94%;
    display: flex;
    flex-direction: column;

    .caution-check {
      padding-left: 22px;
      display: flex;
      position: relative;

      .hidden-checkbox {
        // filter: alpha(opacity=0);
        -moz-opacity: 0;
        opacity: 0;
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 3%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border: solid 1px #4d4d4d;
        background-color: white;
      }

      &.checked {
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 3%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border: solid 1px #4d4d4d;
          background-color: #4d4d4d;
        }

        &::after {
          content: '';
          position: absolute;
          top: 45%;
          left: 3%;
          width: 6px;
          height: 8px;
          border-right: solid 2px white;
          border-bottom: solid 2px white;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }

    .operate-buttons {
      display: flex;
      justify-content: center;
      width: 100%;

      .cancel-button,
      .update-button {
        width: 100px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }

        @include mq(md) {
          width: 140px;
          height: 42px;
          font-size: 1.1rem;
        }

        &:last-child {
          margin-left: 20px;

          @include mq(md) {
            margin-left: 14px;
          }
        }
      }
    }
  }
}
</style>
