import axios from 'axios';

export default () => {
  // 事業所管理者による指定の集合研修実績取得
  const getForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/groupLearnings/${id}`,
    );
    return response.data;
  };

  // 事業所管理者による研修計画と紐づく集合研修一覧取得
  const getAllByLessonPlanForOrganizer = async ({
    lessonPlanId,
    query,
    limit,
    offset,
    sort,
    order,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/groupLearnings`,
      {
        params: {
          q: query,
          limit,
          offset,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  // 事業所管理者による集合研修の参加者一覧取得
  const getParticipantsForOrganizer = async ({ id, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/groupLearnings/${id}/participants`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 事業所管理者による研修計画と紐づく集合研修作成
  const createByLessonPlanForOrganizer = async ({
    lessonPlanId,
    startsAt,
    endsAt,
    location,
    participantIds,
    mainParticipantId,
  }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/groupLearnings`,
      {
        startsAt,
        endsAt,
        location,
        participantIds,
        mainParticipantId,
      },
    );
    return response.data;
  };

  // 事業所管理者による指定した集合研修を更新
  const updateForOrganizer = async ({
    id,
    startsAt,
    endsAt,
    location,
    participantIds,
    mainParticipantId,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/groupLearnings/${id}`,
      {
        startsAt,
        endsAt,
        location,
        participantIds,
        mainParticipantId,
      },
    );
    return response.data;
  };

  // 研修計画に紐付いた集合研修の数を取得
  const countAllByLessonPlanForOrganizer = async ({ lessonPlanId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/groupLearnings/count`,
    );

    return response.data;
  };

  // 事業所管理者による集合研修の参加者総数取得
  const countParticipantsForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/groupLearnings/${id}/participants/count`,
    );
    return response.data;
  };

  // 集合研修を削除
  const trashForOrganizer = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/groupLearnings/${id}`,
    );

    return response.data;
  };

  return {
    getForOrganizer,
    getAllByLessonPlanForOrganizer,
    getParticipantsForOrganizer,
    createByLessonPlanForOrganizer,
    updateForOrganizer,
    countAllByLessonPlanForOrganizer,
    countParticipantsForOrganizer,
    trashForOrganizer,
  };
};
