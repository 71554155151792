export default {
  path: '/courses',
  component: () => import('@/views/courses/index.vue'),
  meta: {
    isPublic: true,
    breadcrumb: {
      parent: 'Home',
    },
  },
  children: [
    {
      path: '',
      name: 'CourseList',
      component: () => import('@/views/courses/CourseListPage.vue'),
      meta: {
        isPublic: true,
        title: 'コース一覧',
        desc: 'コース一覧',
        breadcrumb: {
          label: 'コース一覧',
          parent: 'Home',
        },
      },
    },
    {
      path: ':courseId',
      name: 'CourseDetail',
      component: () => import('@/views/courses/CourseDetail.vue'),
      props: true,
      meta: {
        isPublic: true,
        title: 'コース詳細',
        desc: 'コース詳細',
      },
    },
  ],
};
