<template>
  <div class="login-modal">
    <div class="modal-main">
      <div class="menu-icon" @click="close"></div>
      <div class="logo"></div>
      <form class="login-form" @submit.prevent="login">
        <p class="login-label">ユーザーID</p>
        <input
          v-model="code"
          type="text"
          class="login-input"
          inputmode="text"
          autocomplete="username"
          autocorrect="off"
          autocapitalize="off"
          autofocus
        />
        <p class="login-label">パスワード</p>
        <input
          v-model="password"
          :type="inputType"
          class="login-input"
          autocomplete="current-password"
          @keydown.enter="login"
        />
        <p class="password-show-label">
          <label
            ><input
              type="checkbox"
              class="password-show-check"
              @click="toggle"
            />パスワードを表示する</label
          >
        </p>
        <div v-if="isError" class="error-area">
          <div>
            <p v-if="errorStatus === 423" class="error-message">
              連続してログインに失敗したため、アカウントがロックされました<br />
              解除する場合は、施設内の管理者にお問い合わせください
            </p>
            <p v-else class="error-message">
              ユーザーID、またはパスワードが正しくありません
            </p>
          </div>
        </div>
        <div class="button-wrapper">
          <SLButton class="login-button" text="ログイン" :on-click="login" />
        </div>
        <p class="login-help">
          ログイン情報がわからない場合は、<br />
          施設内の管理者にお問い合わせください
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginModal',
  data() {
    return {
      code: '',
      password: '',
      isError: false,
      isChecked: false,
      errorStatus: null,
    };
  },
  computed: {
    inputType() {
      return this.isChecked ? 'text' : 'password';
    },
  },
  methods: {
    async login() {
      try {
        this.errorStatus = null;

        await this.$store.dispatch('user/login', {
          code: this.code,
          password: this.password,
        });

        await this.$store.dispatch('user/getUserInfo');

        this.$router.go(0);
      } catch (error) {
        console.warn(error);
        this.isError = true;
        this.errorStatus = error.response.status;
      }
    },
    close() {
      this.$store.dispatch('modal/close');
    },
    toggle() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-modal {
  position: fixed;
  top: 10%;
  left: calc(50% - 90% / 2);
  width: 90%;
  height: auto;
  margin: 0 auto;
  padding: 50px 35px;
  background-color: #fff;
  border-radius: 10px;
  z-index: 10010;

  @include mq(md) {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.modal-main {
  height: 100%;
}

.menu-icon {
  display: block;
  top: 0;
  left: auto;
  right: 0;
  background-size: 20px;
  background-position: 15px;
  background-color: transparent;
  background-image: url('@/assets/img/close_icon.png');
  position: absolute;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.logo {
  height: 45px;
}

.login-form {
  position: relative;
}

.login-label {
  margin-top: 30px;
  font-size: 0.9rem;
  font-weight: bold;
}

.login-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 1.3rem;
}

.password-show-label {
  margin-top: 5px;
  padding-bottom: 10px;
}

.password-show-check {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;
  vertical-align: text-bottom;
}

.error-area {
  padding: 16px;
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
  background-color: #ffcccc;
  color: #bb2624;
  line-height: 1.6;
  font-size: 0.8rem;
  font-weight: bold;

  div {
    position: relative;
    padding-left: calc(1rem + 8px);
  }
}

.error-message::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('@/assets/img/icon_alert.png');
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: text-bottom;
}

.button-wrapper {
  text-align: center;
}

.login-button {
  width: 70%;
  margin: 32px auto 0;
  padding: 5px;
  font-size: 1.5rem;
}

.login-help {
  margin-top: 10px;
  text-align: center;
  color: #666;
  font-size: 0.8rem;
}

@media screen and (max-height: 500px) {
  .login-modal {
    height: 80%;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 1024px) {
  .login-modal {
    left: calc(50% - 450px / 2);
    width: 450px;
    padding: 50px;
  }

  .login-help {
    font-size: 0.9rem;
  }
}
</style>
