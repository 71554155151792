<template>
  <div class="overlay" @click="send"></div>
</template>

<script>
export default {
  name: 'BackdropOverlay',
  methods: {
    send() {
      this.$emit('closeOverlay');
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #000;
  opacity: 0.7;
  z-index: 10000;
  transition: all 0.5s ease;
}
</style>
