import axios from 'axios';

export default () => {
  // 生徒のカスタム研修レポートの一覧を取得
  const getAllForStudent = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // 事業所管理者による指定カスタム研修レポート取得
  const getForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/reports/${id}`,
    );
    return response.data;
  };

  // 事業所管理者による指定カスタム研修内のカスタム研修レポート一覧取得
  const getAllByTrainingEventForOrganizer = async ({
    id,
    limit,
    offset,
    states,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}/reports`,
      {
        params: {
          limit,
          offset,
          states,
        },
      },
    );
    return response.data;
  };

  // 指定事業所内の指定ユーザーのカスタム研修レポート一覧取得
  const getAllByUserAndOrganizationForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/trainingEventReports`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 生徒自身の指定カスタム研修のカスタム研修レポート一覧を取得
  const getByTrainingEventForStudent = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/${id}/reports`,
    );

    return response.data;
  };

  // 事業所管理者の手番のカスタム研修レポート一覧を取得
  const getAllTurnForOrganizer = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/reports/@turn`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // 生徒自身の手番のカスタム研修レポート一覧を取得
  const getAllTurnForStudent = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/@turn`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 生徒自身のカスタム研修レポートの詳細を取得
  const getForStudent = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/${id}`,
    );
    return response.data;
  };

  // カスタム研修レポートの提出状況を取得
  const getSummariesByTrainingEventsForOrganizer = async ({
    limit,
    offset,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/reports/summary`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 生徒自身のカスタム研修レポートを編集・提出
  const updateForStudent = async ({ id, contents, isDraft }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/${id}`,
      {
        contents,
        isDraft,
      },
    );
    return response.data;
  };

  // 生徒自身のカスタム研修レポートを完了状態にする
  const setToCompletedForStudent = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/${id}/completed`,
    );

    return response.data;
  };

  // 生徒自身の指定カスタム研修レポートに画像を追加
  const registerPictureForStudent = async ({ id, pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/${id}/pictures`,
      formData,
    );
    return response.data;
  };

  // 生徒自身の指定カスタム研修レポートの画像を削除
  const removePictureForStudent = async ({ id, pictureId }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/${id}/pictures/${pictureId}`,
    );
    return response.data;
  };

  // 生徒自身の指定カスタム研修レポートの画像の並び順を変更
  const reorderPicturesForStudent = async ({ id, pictureIds }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/trainingEvents/reports/${id}/pictures/order`,
      pictureIds,
    );
    return response.data;
  };

  // カスタム研修レポートのフィードバックを作成
  const updateForOrganizer = async ({ id, contents, state }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/reports/${id}`,
      {
        contents,
        state,
      },
    );
    return response.data;
  };

  return {
    getAllForStudent,
    getForOrganizer,
    getAllByTrainingEventForOrganizer,
    getAllByUserAndOrganizationForOrganizer,
    getByTrainingEventForStudent,
    getAllTurnForOrganizer,
    getAllTurnForStudent,
    getForStudent,
    getSummariesByTrainingEventsForOrganizer,
    updateForStudent,
    setToCompletedForStudent,
    registerPictureForStudent,
    removePictureForStudent,
    reorderPicturesForStudent,
    updateForOrganizer,
  };
};
