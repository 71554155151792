import axios from 'axios';

export default () => {
  // 生徒のコースレポートの一覧を取得
  const getReportSummariesByOrganizationForOrganizer = async ({
    organizationId,
    limit,
    offset,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/courseReports/summary`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // 生徒のコースレポートの一覧を取得
  const getCourseReportsForStudent = async ({ limit, offset, states } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports`,
      {
        params: {
          limit,
          offset,
          states,
        },
      },
    );

    return response.data;
  };

  // 生徒自身のコースレポートの詳細を取得
  const getForStudent = async ({ id, limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // 生徒自身のコースレポートを編集・提出
  const updateForStudent = async ({ id, contents, isDraft }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}`,
      {
        contents,
        isDraft,
      },
    );
    return response.data;
  };

  // 生徒自身の指定コースレポートの画像一覧を取得
  const getPicturesByCourseReportForStudent = async ({ id } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}/pictures`,
    );

    return response.data;
  };

  // 生徒自身の指定コースレポートに画像を追加
  const registerPictureForStudent = async ({ id, pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}/pictures`,
      formData,
    );
    return response.data;
  };

  // 生徒自身の指定コースレポートの画像を削除
  const removePictureForStudent = async ({ id, pictureId }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}/pictures/${pictureId}`,
    );
    return response.data;
  };

  // 生徒自身の指定コースレポートの画像の並び順を変更
  const reorderPicturesForStudent = async ({ id, pictureIds }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}/pictures/order`,
      pictureIds,
    );
    return response.data;
  };

  // 事業所管理者による指定コースレポート取得
  const getForOrganizer = async ({ id } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/courseReports/${id}`,
    );

    return response.data;
  };

  // 事業所管理者による指定コースレポートの画像一覧取得
  const getPicturesForOrganizer = async ({ id } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/courseReports/${id}/pictures`,
    );

    return response.data;
  };

  // 指定事業所内の指定ユーザーのコースレポート一覧取得
  const getAllByUserAndOrganizationForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
    states,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/courseReports`,
      {
        params: {
          limit,
          offset,
          states,
        },
      },
    );
    return response.data;
  };

  // 事業所内の指定コースのコースレポート一覧取得
  const getAllByCourseAndOrganizationForOrganizer = async ({
    organizationId,
    courseId,
    limit,
    offset,
    states,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/courses/${courseId}/courseReports`,
      {
        params: {
          limit,
          offset,
          states,
        },
      },
    );
    return response.data;
  };

  // コースレポートのフィードバックを作成
  const updateForOrganizer = async ({ id, contents, state } = {}) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/courseReports/${id}`,
      {
        contents,
        state,
      },
    );

    return response.data;
  };

  // 生徒自身のコースレポートを完了状態にする
  const setToCompletedForStudent = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/${id}/completed`,
    );

    return response.data;
  };

  // 事業所管理者の手番のコースレポート一覧取得
  const getTurnForOrganizer = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/courseReports/@turn`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // 生徒の手番のコースレポートの一覧を取得
  const getTurnForStudent = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/courseReports/@turn`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  return {
    getReportSummariesByOrganizationForOrganizer,
    getCourseReportsForStudent,
    getForStudent,
    updateForStudent,
    getPicturesByCourseReportForStudent,
    registerPictureForStudent,
    removePictureForStudent,
    reorderPicturesForStudent,
    getForOrganizer,
    getPicturesForOrganizer,
    getAllByUserAndOrganizationForOrganizer,
    getAllByCourseAndOrganizationForOrganizer,
    updateForOrganizer,
    setToCompletedForStudent,
    getTurnForOrganizer,
    getTurnForStudent,
  };
};
