<template>
  <div id="undo-complete-training-event-modal">
    <div class="close-icon" @click="close" />
    <div class="messages">
      <h2 class="mt-2">受講未完了にする</h2>
      <p class="mt-5">
        「
        <PrefixIcon text="カスタム研修" black />
        {{ `${payload.trainingEvent.name}」の受講状況を未完了に変更します。` }}
      </p>
      <!-- 管理者はレポート提出がないため、警告不要 -->
      <div v-if="user.role !== 'organizer'" class="mt-7">
        <p>
          未完了に変更すると、対象のレポートは削除されます。削除されたレポートは元に戻せません。
        </p>
        <p class="mt-5">未完了に変更しますか？</p>
      </div>
      <div class="mt-3 button-wrapper">
        <!-- 管理者はレポート提出がないため、警告不要 -->
        <label
          v-if="user.role !== 'organizer'"
          class="caution-check"
          :class="{ checked: isCautionChecked }"
        >
          <input
            class="hidden-checkbox"
            type="checkbox"
            @click="isCautionChecked = !isCautionChecked"
          />
          レポートが削除されることを確認しました
        </label>

        <div class="operate-buttons">
          <SLButton
            ref="cancelButton"
            black
            text="キャンセル"
            :on-click="close"
            tabindex="1"
          />
          <SLButton
            text="受講未完了にする"
            :on-click="updateStatusToComplete"
            tabindex="2"
            :disabled="isDisabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrefixIcon from '@/components/common/PrefixIcon.vue';
import repository from '@/api/apiRepositoryFactory';
import { mapState } from 'vuex';

export default {
  name: 'UndoCompleteTrainingEventModal',
  components: {
    PrefixIcon,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCautionChecked: false,
    };
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.user,
    }),
    isDisabled() {
      if (this.user.role === 'organizer') {
        return false;
      }

      return !this.isCautionChecked;
    },
  },
  mounted() {
    // モーダルを開いた時に、モーダル内にフォーカスする
    this.$refs.cancelButton.$el.focus();
  },
  methods: {
    close() {
      this.$store.dispatch('modal/close');
    },
    // カスタム研修の参加状況を参加済みに更新
    async updateStatusToComplete() {
      const trainingEventApi = repository.trainingEvents();
      try {
        await trainingEventApi.updateUserStateToNotCompletedForUser({
          id: this.payload.trainingEvent.id,
        });
        this.close();
        this.payload.getTrainingEvent();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#undo-complete-training-event-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 95%;
  max-width: 450px;
  height: auto;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;

  @include mq(md) {
    padding: 40px;
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/img/close_icon.png');
    background-size: 20px;
    background-position: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .messages {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .caution-check {
        padding-left: 22px;
        display: flex;
        position: relative;

        .hidden-checkbox {
          // filter: alpha(opacity=0);
          -moz-opacity: 0;
          opacity: 0;
          -webkit-appearance: none;
          appearance: none;
          position: absolute;
        }

        // TODO: mixinのCSSを使うと微妙にずれるため、ここで書いているできればmixinにまとめたい
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 3%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border: solid 1px #4d4d4d;
          background-color: white;
        }

        // TODO: mixinのCSSを使うと微妙にずれるため、ここで書いているできればmixinにまとめたい
        &.checked {
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 3%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border: solid 1px #4d4d4d;
            background-color: #4d4d4d;
          }

          // TODO: mixinのCSSを使うと微妙にずれるため、ここで書いているできればmixinにまとめたい
          &::after {
            content: '';
            position: absolute;
            top: 45%;
            left: 3%;
            width: 6px;
            height: 8px;
            border-right: solid 2px white;
            border-bottom: solid 2px white;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }

    .operate-buttons {
      width: 300px;
      margin: 0 auto;
      margin-top: 24px;
      display: flex;
      justify-content: space-around;

      @include mq(md) {
        width: 100%;
        gap: 16px;
      }

      > button {
        width: 120px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
