import userRepository from './repositories/userRepository';
import lessonRepository from './repositories/lessonRepository';
import lessonCategoryRepository from './repositories/lessonCategoryRepository';
import lessonTagRepository from './repositories/lessonTagRepository';
import teacherRepository from './repositories/teacherRepository';
import corporationRepository from './repositories/corporationRepository';
import organizationRepository from './repositories/organizationRepository';
import organizationGroupRepository from './repositories/organizationGroupRepository';
import lessonPlanRepository from './repositories/lessonPlanRepository';
import lessonPlanReportRepository from './repositories/lessonPlanReportRepository';
import reportRepository from './repositories/reportRepository';
import carouselRepository from './repositories/carouselRepository';
import badgeRepository from './repositories/badgeRepository';
import surveyResponseRepository from './repositories/surveyResponseRepository';
import statisticsRepository from './repositories/statisticsRepository';
import settingsRepository from './repositories/settingsRepository';
import informationsRepository from './repositories/informationsRepository';
import courseRepository from './repositories/courseRepository';
import courseReportRepository from './repositories/courseReportRepository';
import mediumSectionRepository from './repositories/mediumSectionRepository';
import groupLearningRepository from './repositories/groupLearningRepository';
import seminarRepository from './repositories/seminarRepository';
import trainingEventRepository from './repositories/trainingEventRepository';
import trainingTagRepository from './repositories/trainingTagRepository';
import trainingEventReportRepository from './repositories/trainingEventReportRepository';

const repository = {
  users: userRepository,
  lessons: lessonRepository,
  lessonCategories: lessonCategoryRepository,
  lessonTags: lessonTagRepository,
  teachers: teacherRepository,
  corporations: corporationRepository,
  organizations: organizationRepository,
  organizationGroups: organizationGroupRepository,
  lessonPlans: lessonPlanRepository,
  lessonPlanReports: lessonPlanReportRepository,
  reports: reportRepository,
  carousels: carouselRepository,
  badges: badgeRepository,
  surveyResponses: surveyResponseRepository,
  statistics: statisticsRepository,
  settings: settingsRepository,
  informations: informationsRepository,
  courses: courseRepository,
  courseReports: courseReportRepository,
  mediumSections: mediumSectionRepository,
  groupLearnings: groupLearningRepository,
  seminars: seminarRepository,
  trainingEvents: trainingEventRepository,
  trainingTags: trainingTagRepository,
  trainingEventReports: trainingEventReportRepository,
};

export default repository;
