import axios from 'axios';

export default () => {
  // 公開レッスンを担当している講師一覧取得
  const get = async ({ query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/teachers`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 講師一覧取得
  const getForUser = async ({ query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 公開レッスンを担当している講師取得
  const getById = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/teachers/${id}`,
    );
    return response.data;
  };

  // 講師取得
  const getByIdForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers/${id}`,
    );
    return response.data;
  };

  // 公開レッスンを担当している講師取得
  const getByCode = async ({ code }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/teachers/@code=${encodeURI(code)}`,
    );
    return response.data;
  };

  // 講師取得
  const getByCodeForUser = async ({ code }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers/@code=${encodeURI(
        code,
      )}`,
    );
    return response.data;
  };

  // レッスンを担当する講師一覧を取得
  const getAllByLesson = async ({ lessonId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessons/${lessonId}/teachers`,
    );
    return response.data;
  };

  // レッスンを担当する講師一覧を取得(ユーザー用)
  const getAllByLessonForUser = async ({ lessonId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${lessonId}/teachers`,
    );
    return response.data;
  };

  // セミナーと紐づく講師一覧取得
  const getAllBySeminarForUser = async ({ seminarId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/seminars/${seminarId}/teachers`,
    );
    return response.data;
  };

  // 公開レッスンを担当している講師総数取得
  const countAllByPublicLesson = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/teachers/count`,
    );
    return response.data;
  };

  // ユーザーが学習可能なレッスンを担当している講師総数取得
  const countAllByLessonAttendableByUser = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers/count`,
    );
    return response.data;
  };

  return {
    get,
    getForUser,
    getById,
    getByIdForUser,
    getAllByLesson,
    getAllByLessonForUser,
    getByCode,
    getByCodeForUser,
    getAllBySeminarForUser,
    countAllByPublicLesson,
    countAllByLessonAttendableByUser,
  };
};
