<template>
  <div id="clear-tags-modal">
    <div class="close-icon" @click="close" />
    <section>
      <h2>すべての関連タグを削除しますか？</h2>
      <p class="mt-4">設定した関連タグが一括で削除されます。</p>
      <div class="mt-6 buttons">
        <SLButton black text="キャンセル" :on-click="close" />
        <SLButton text="削除する" :on-click="clearTags" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ClearTagsModal',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clearTags() {
      this.payload.clearTags();
      this.close();
    },
    close() {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>

<style lang="scss" scoped>
#clear-tags-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 95%;
  max-width: 460px;
  height: auto;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/img/close_icon.png');
    background-size: 20px;
    background-position: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  > section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-message {
      font-size: 0.9em;
      font-weight: bold;
      color: red;
    }

    .buttons {
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      > button {
        width: 120px;
      }
    }
  }
}
</style>
