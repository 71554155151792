<template>
  <div class="modal">
    <div class="close-icon-wrapper">
      <p class="close-icon" @click="close" />
    </div>
    <h2 class="modal-title">参加者を追加する</h2>
    <p class="modal-discription">
      集合研修に参加したスタッフを選択してください。
    </p>
    <p v-if="isFetching" class="loading-message mt-5">読み込み中…</p>
    <p v-else-if="isError" class="error-message mt-5">
      データの取得に失敗しました
    </p>
    <div v-else class="participant-container">
      <div class="search-and-select-wrapper">
        <p>{{ `${selectedUsers.length}件選択中` }}</p>
        <div class="right-colum">
          <input
            v-model="searchWords"
            type="search"
            class="search-input"
            placeholder="氏名、ユーザーID"
            inputmode="search"
            @keydown.enter="search"
          />
          <SLButton text="検索" class="search-button" :on-click="search" />
        </div>
      </div>
      <div class="table-container">
        <table class="participant-table">
          <thead>
            <tr>
              <th
                class="header-checkbox"
                :class="selectionState"
                :style="hideStyle"
                @click="updateAllUsersSelection"
              ></th>
              <th class="header-code">ユーザーID</th>
              <th class="header-role">権限</th>
              <th class="header-name">氏名</th>
              <th class="header-organization">所属事業所</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="lessonPlanUsers.length === 0">
              <td class="non-display">－</td>
              <td>－</td>
              <td>－</td>
              <td>－</td>
            </tr>
            <AddParticipantsModalTableRow
              v-for="user in lessonPlanUsers"
              v-else
              :key="user.id"
              :user="user"
              :is-selected="isSelectedUser(user.id)"
              @onToggleSelection="updateUserSelection"
            />
          </tbody>
        </table>
      </div>
      <p v-if="lessonPlanUsers.length === 0">
        該当するユーザーが見つかりませんでした
      </p>
      <div class="button-wrapper">
        <SLButton
          black
          text="キャンセル"
          class="cansel-button"
          :on-click="close"
        />
        <SLButton
          type="submit"
          text="参加者を追加する"
          class="submit-button"
          :on-click="updateParticipants"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddParticipantsModalTableRow from '@/components/Modal/addParticipantsModal/AddParticipantsModalTableRow';
import repository from '@/api/apiRepositoryFactory';
import { fetchAllData } from '@/api/libs';
import { mapState } from 'vuex';
import Participant from '@/models/Participant';

export default {
  name: 'AddParticipantsModal',
  components: {
    AddParticipantsModalTableRow,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lessonPlanUsers: [],
      selectedUsers: [],
      isCheckAll: false,
      isFetching: true,
      isError: false,
      searchWords: '',
    };
  },
  computed: {
    ...mapState('pages', {
      userSelection: (state) => state.groupLearning.selectedUsers,
    }),
    hideStyle() {
      // ユーザーがいない場合はチェックボックスを非表示にする
      return this.lessonPlanUsers.length === 0 ? 'display: none' : '';
    },
    selectedUserIds() {
      return this.selectedUsers.map(({ id }) => id);
    },
    areAllUsersSelected() {
      // `getAllUsersByLessonPlanId`で取得したユーザーが`this.selectedUsers`に含まれているかを判定
      return this.lessonPlanUsers.every((user) =>
        this.selectedUserIds.includes(user.id),
      );
    },
    selectionState() {
      if (!this.isCheckAll) {
        return 'non-selected';
      }

      return this.areAllUsersSelected ? 'all-selected' : 'partly-selected';
    },
  },
  async mounted() {
    await this.getAllUsersByLessonPlanId();
    this.selectedUsers = this.userSelection ?? [];
    this.isCheckAll = this.areAllUsersSelected;
    this.isFetching = false;
  },
  methods: {
    // 研修計画の対象の全ユーザーを取得
    async getAllUsersByLessonPlanId(keyword = '') {
      const userApi = repository.users();

      try {
        const users = await fetchAllData(userApi.getAllLessonPlanForOrganizer, {
          lessonPlanId: this.payload.lessonPlanId,
          query: keyword,
        });

        this.lessonPlanUsers = users.map((user) => {
          return new Participant(user);
        });

        this.isError = false;
      } catch (error) {
        this.isError = true;
        console.warn(error);
      }
    },
    updateAllUsersSelection() {
      this.isCheckAll = !this.isCheckAll;

      if (this.isCheckAll) {
        this.selectAllUsers();
      } else {
        this.clearSelectedUsers();
      }
    },
    updateUserSelection(user) {
      if (!this.selectedUserIds.includes(user.id)) {
        this.selectedUsers = [...this.selectedUsers, user];
      } else {
        this.deselectUser(user);
      }
    },
    selectAllUsers() {
      this.selectedUsers = [...this.lessonPlanUsers];
    },
    clearSelectedUsers() {
      this.selectedUsers = [];
    },
    deselectUser(user) {
      this.selectedUsers = this.selectedUsers.filter(({ id }) => {
        return id !== user.id;
      });
    },
    isSelectedUser(userId) {
      return this.selectedUsers.some((user) => user.id === userId);
    },
    close() {
      this.$store.dispatch('modal/close');
    },
    updateParticipants() {
      this.$store.commit('pages/updateSelectedUsersForGroupLearning', [
        ...this.selectedUsers,
      ]);

      this.close();
    },
    async search() {
      // 半角スペース、',[コンマ]'で検索ワードを分割し、半角スペースで結合する
      const splitPattern = /[,]+/;
      const searchKeywords = this.searchWords.split(splitPattern);
      const searchKeyword = searchKeywords.join(' ');

      await this.getAllUsersByLessonPlanId(searchKeyword);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 40px 35px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #333;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  z-index: 10010;

  @include mq(lg) {
    padding: 16px;
    top: 3%;
    max-width: 360px;
  }

  .close-icon-wrapper {
    position: relative;
    width: 100%;
    height: 30px;

    .close-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url('~@/assets/img/close_icon.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .modal-title {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .modal-discription {
    margin-top: 20px;
    font-size: 1rem;
  }

  .loading-message,
  .error-message {
    text-align: center;
  }

  .participant-container {
    width: 90%;

    @include mq(lg) {
      width: 100%;
    }

    .search-and-select-wrapper {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mq(lg) {
        margin-bottom: 6px;
        align-items: flex-start;
        flex-direction: column-reverse;
      }

      p {
        margin-left: 6px;
        font-size: 1rem;
        align-self: end;

        @include mq(lg) {
          margin-left: 0px;
          align-self: start;
        }
      }

      .right-colum {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq(lg) {
          margin-bottom: 20px;
          width: 100%;
        }
      }

      input {
        padding: 0 10px 0 30px;
        width: 180px;
        height: 32px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        font-size: 0.9rem;
        background-image: url('~@/assets/img/search_icon.png');
        background-size: 1.2rem;
        background-repeat: no-repeat;
        background-position: 5px center;

        @include mq(lg) {
          width: 90%;
          height: 40px;
        }
      }

      .search-button {
        margin-left: 12px;
        width: 60px;
        height: 32px;
        font-size: 1rem;
        font-weight: normal;
        border: solid 2px #aaa;
        border-radius: 50px;
        color: #999;
        background-color: #fff;

        @include mq(lg) {
          margin-left: 10px;
          width: 80px;
          height: 40px;
        }
      }
    }

    .table-container {
      margin-top: 14px;
      width: 100%;
      max-height: 360px;
      overflow-y: auto;

      @include mq(lg) {
        max-height: 200px;
      }

      .participant-table {
        width: 100%;
        border: solid 1px #ccc;
        border-collapse: collapse;

        tr {
          width: 100%;
          border-bottom: solid 1px #ccc;

          th {
            padding: 14px 6px;
            position: relative;
            text-align: left;
            background-color: #f2f2f2;
            border-right: solid 1px #ccc;

            &.header-checkbox {
              @include custom-base-checkbox;
              width: 5%;

              @include mq(lg) {
                width: 10%;
                max-width: 30px;
              }

              &.all-selected {
                @include checkmark-checkbox;
              }

              &.partly-selected {
                @include hyphen-checkbox;
              }
            }

            &.header-code {
              width: 20%;
              min-width: 100px;

              @include mq(lg) {
                display: none;
              }
            }

            &.header-role {
              width: 15%;
              min-width: 95px;

              @include mq(lg) {
                min-width: 55px;
              }
            }

            &.header-name,
            &.header-organization {
              width: 30%;
              min-width: 100px;
            }
          }

          td {
            padding: 14px 6px;
            border-right: solid 1px #ccc;

            &.non-display {
              @include mq(md) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.button-wrapper {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  .submit-button {
    margin-left: 20px;
  }
}
</style>
