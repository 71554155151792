import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import redirects from './redirects';
import isLogin from '@/libs/isLogin';
import { setDescription, setRobots } from '@/libs/meta';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [...routes, ...redirects],
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(async (to, _, next) => {
  if (to.meta.desc) {
    setDescription(to.meta.desc);
  }

  if (!to.meta.isPublic) {
    setRobots('nofollow,noindex');
  } else {
    setRobots('');
  }

  const loggedIn = await isLogin();
  if (to.matched.some((record) => !record.meta.isPublic)) {
    // 遷移先が非公開画面の場合

    if (loggedIn) {
      // ログイン済みなら、目的の画面に遷移
      next();
    } else {
      // 未ログインなら、ログイン画面に遷移(ログイン後、目的の画面にリダイレクト)
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else if (to.name === 'Login') {
    // 遷移先がログイン画面の場合

    if (loggedIn && !to.query.loginKey) {
      // ログイン済みかつ簡易ログイン用の情報も無い場合は、ログイン画面をホーム画面にリダイレクトする
      next({
        name: 'Home',
      });
    }

    next();
  } else {
    // その他の公開画面の場合、そのまま遷移
    next();
  }
});

export default router;
