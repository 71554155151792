import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import createPersistedState from 'vuex-persistedstate';

import user from '@/store/modules/user';
import auth from '@/store/modules/auth';
import api from '@/store/modules/api';
import userinfo from '@/store/modules/userinfo';
import pages from '@/store/modules/pages';
import notifications from '@/store/modules/notifications';
import modal from '@/store/modules/modal';

const store = new Vuex.Store({
  modules: {
    user,
    auth,
    api,
    userinfo,
    pages,
    notifications,
    modal,
  },
  plugins: [
    createPersistedState({
      key: 'user',
      paths: ['user', 'auth', 'api', 'userinfo', 'pages', 'notifications'],
      storage: window.localStorage,
    }),
  ],
});

export default store;
