import axios from 'axios';

export default () => {
  // 事業所管理者に紐づいているカスタム研修一覧取得
  const getAllForOrganizer = async ({
    query,
    limit,
    offset,
    sort,
    order,
    organizationIds,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents`,
      {
        params: {
          q: query,
          limit,
          offset,
          sort,
          order,
          organizations: organizationIds,
        },
      },
    );
    return response.data;
  };

  /** 自分が参加するカスタム研修の一覧取得 */
  const getAllForUser = async ({ query, since, until, limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/trainingEvents`,
      {
        params: {
          q: query,
          since,
          until,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 事業所管理者による指定のカスタム研修取得
  const getForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}`,
    );
    return response.data;
  };

  // 自分が参加するカスタム研修の取得
  const getForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/trainingEvents/${id}`,
    );
    return response.data;
  };

  // カスタム研修を作成
  const createForOrganizer = async ({
    name,
    description,
    location,
    state,
    startsAt,
    endsAt,
    links,
    trainingTagIds,
    userIds,
  }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents`,
      {
        name,
        description,
        location,
        state,
        startsAt,
        endsAt,
        links,
        trainingTagIds,
        userIds,
      },
    );
    return response.data;
  };

  // カスタム研修を更新
  const updateForOrganizer = async ({
    id,
    name,
    description,
    location,
    state,
    startsAt,
    endsAt,
    links,
    trainingTagIds,
    userIds,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}`,
      {
        name,
        description,
        location,
        state,
        startsAt,
        endsAt,
        links,
        trainingTagIds,
        userIds,
      },
    );
    return response.data;
  };

  // カスタム研修を削除
  const trashForOrganizer = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}`,
    );
    return response.data;
  };

  // カスタム研修の全参加者の参加状況を参加済みに更新する
  const updateAllUsersStateToCompletedForOrganizer = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}/complete`,
    );
    return response.data;
  };

  // カスタム研修の全参加者の参加状況を未参加に更新する
  const updateAllUsersStateToNotCompletedForOrganizer = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}/complete`,
    );
    return response.data;
  };

  // カスタム研修の参加者の参加状況を参加済みに更新する
  const updateUsersStateToCompletedForOrganizer = async ({ id, userId }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}/users/${userId}/complete`,
    );
    return response.data;
  };

  // カスタム研修の参加者の参加状況を未参加に更新する
  const updateUsersStateToNotCompletedForOrganizer = async ({ id, userId }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${id}/users/${userId}/complete`,
    );
    return response.data;
  };

  // カスタム研修の参加状況を参加済みに更新する
  const updateUserStateToCompletedForUser = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/trainingEvents/${id}/complete`,
    );
    return response.data;
  };

  // カスタム研修の参加状況を未参加に更新する
  const updateUserStateToNotCompletedForUser = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/trainingEvents/${id}/complete`,
    );
    return response.data;
  };

  return {
    getAllForOrganizer,
    getForOrganizer,
    getAllForUser,
    getForUser,
    createForOrganizer,
    updateForOrganizer,
    trashForOrganizer,
    updateAllUsersStateToCompletedForOrganizer,
    updateAllUsersStateToNotCompletedForOrganizer,
    updateUsersStateToCompletedForOrganizer,
    updateUsersStateToNotCompletedForOrganizer,
    updateUserStateToCompletedForUser,
    updateUserStateToNotCompletedForUser,
  };
};
