<template>
  <span class="prefix-icon" :class="styles">{{ text }}</span>
</template>

<script>
export default {
  name: 'PrefixIcon',
  props: {
    text: {
      type: String,
      required: true,
    },
    black: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        black: this.black,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.prefix-icon {
  padding: 2px 4px;
  font-weight: bold;
  color: white;
  background-color: #ff7171;
  border-radius: 3px;
  white-space: nowrap;

  &.black {
    background-color: #999;
  }
}
</style>
