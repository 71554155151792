<template>
  <div class="modal">
    <div class="close-icon" @click="close" />
    <BadgeCarousel :badges="badges" />
    <SLButton text="バッジを確認する" :on-click="goBadgeList" arrow />
  </div>
</template>

<script>
import BadgeCarousel from '@/components/gamification/BadgeCarousel.vue';
import SLButton from '@/components/common/SLButton.vue';
import { mapState } from 'vuex';

export default {
  name: 'BadgeModal',
  components: {
    BadgeCarousel,
    SLButton,
  },
  computed: {
    ...mapState('user', {
      organization: (state) => state.organization,
    }),
    ...mapState('notifications', {
      badges: (state) => state.badges,
    }),
  },
  methods: {
    async goBadgeList() {
      this.confirmBadges();

      this.$router.push({
        name: 'BadgeList',
        params: {
          organizationId: this.organization.id,
        },
      });
      this.$store.dispatch('modal/close');
    },
    close() {
      this.confirmBadges();
      this.$store.dispatch('modal/close');
    },
    confirmBadges() {
      // ユーザーが獲得した未確認のバッジを確認済みにする
      this.$store.dispatch('notifications/confirmAchievedBadges', this.badges);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 95%;
  max-width: 400px;
  height: auto;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/img/close_icon.png');
    background-size: 20px;
    background-position: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
</style>
