<template>
  <span class="required-icon">必須</span>
</template>

<script>
export default {
  name: 'RequiredIcon',
};
</script>

<style lang="scss" scoped>
.required-icon {
  padding: 2px 4px;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  background-color: #ff7171;
  border-radius: 3px;
  white-space: nowrap;
}
</style>
