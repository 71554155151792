import axios from 'axios';

export default () => {
  // コース一覧取得
  const getAll = async ({ limit, offset } = {}) => {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/courses`, {
      params: {
        limit,
        offset,
      },
    });

    return response.data;
  };

  // ユーザーによるコース一覧取得
  const getAllForUser = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/courses`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // コースを取得
  const get = async ({ courseId } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/courses/${courseId}`,
    );

    return response.data;
  };

  // ユーザーによるコース取得
  const getForUser = async ({ courseId } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/courses/${courseId}`,
    );

    return response.data;
  };

  // コース数取得
  const countAll = async ({ query } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/courses/count`,
      {
        params: {
          query,
        },
      },
    );

    return response.data;
  };

  // ユーザーによるコース数取得
  const countAllForUser = async ({ query } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/courses/count`,
      {
        params: {
          query,
        },
      },
    );

    return response.data;
  };

  // 事業所内の指定ユーザーが受講済みのレッスンが一つでもあるコース一覧取得
  const getStudiedAllByUserAndOrganizationForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/courses/@studied`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  return {
    getAll,
    getAllForUser,
    get,
    getForUser,
    countAll,
    countAllForUser,
    getStudiedAllByUserAndOrganizationForOrganizer,
  };
};
