import axios from 'axios';

export default () => {
  const getById = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/informations/${id}`,
    );
    return response.data;
  };

  const getAll = async ({ query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/informations`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getCount = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/informations/count`,
    );
    return response.data;
  };

  return {
    getById,
    getAll,
    getCount,
  };
};
