<template>
  <div class="topic-title">{{ text }}</div>
</template>

<script>
export default {
  name: 'TopicTitle',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.topic-title {
  margin: 0 auto;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  white-space: pre-wrap;

  @include mq(lg) {
    font-size: 1.5rem;
  }

  @include mq(xl) {
    max-width: 1200px;
  }

  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 6px;
    margin: 10px auto;
    border-radius: 5px;
    background-color: $theme-color;
  }
}
</style>
