const BREAKPOINTS = {
  MD: 864,
  LG: 1024,
  XL: 1200,
};

const BADGETYPES = [
  { type: 'LessonCategory', label: 'カテゴリー' },
  { type: 'Theme', label: 'テーマ' },
  { type: 'StudyTime', label: '学習時間' },
  { type: 'Course', label: 'コース' },
];

const CONTINUOUS_PLAY_LIMIT = 30;

const URL = {
  LANDING_PAGE: 'https://special-l.link',
  LANDING_PAGE_CONTACT_FORM: 'https://special-l.link/#contact',
};

/*
CPサイトでポリシーを変更した場合、ポリシーのバージョンを変更する
ポリシーのバージョンを変更すると、Cookie利用同意を求めるポップアップが表示される
更新日時は'YYYY-MM-DD'形式で入力する （例： '2023-06-01'）
*/
const PRIVACY_POLICY_VERSION = '1.0.0';
const COOKIE_POLICY_VERSION = '1.0.0';
const POLICY_LAST_UPDATED = '2023-06-01';

export {
  BREAKPOINTS,
  BADGETYPES,
  CONTINUOUS_PLAY_LIMIT,
  URL,
  PRIVACY_POLICY_VERSION,
  COOKIE_POLICY_VERSION,
  POLICY_LAST_UPDATED,
};
