import courses from '@/router/routes/courses';
import settings from '@/router/routes/settings';
import seminars from '@/router/routes/seminars';
import lessonPlans from '@/router/routes/lessonPlans';
import trainingEvents from '@/router/routes/trainingEvents';

export default [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      isPublic: true,
      title: 'Special Learning',
      desc: 'Special Learning は、障がい福祉サービス従事者や障がい者雇用をしている企業の研修などの【悩み】を解決するサポートツールです。パソコンやスマホ・タブレットを通じてeラーニング形式でオンライン研修が可能です。',
      breadcrumb: 'ホーム',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      isPublic: true,
      title: 'ログイン',
      desc: 'Special Learning のログイン画面です。',
    },
  },
  {
    path: '/categorylist',
    name: 'CategoryList',
    component: () => import('../views/CategoryList.vue'),
    meta: {
      isPublic: true,
      title: 'すべてのカテゴリーをみる',
      desc: 'Special Learning のすべてのカテゴリーをみる',
    },
  },
  {
    path: '/category/:code/:mediumSectionId?',
    name: 'Category',
    component: () => import('../views/Category.vue'),
    props: true,
    meta: {
      isPublic: true,
      title: 'レッスンカテゴリー',
      desc: 'レッスンカテゴリー',
    },
  },
  {
    path: '/popular',
    name: 'Popular',
    component: () => import('../views/Popular.vue'),
    meta: {
      isPublic: true,
      title: '人気・注目の動画',
      desc: 'Special Learning の人気・注目の動画',
    },
  },
  {
    path: '/freelesson',
    name: 'FreeLesson',
    component: () => import('../views/FreeLesson.vue'),
    meta: {
      isPublic: false,
      title: '無料コンテンツ',
      desc: '無料コンテンツ',
    },
  },
  {
    path: '/proficiency',
    name: 'ProficiencySummary',
    component: () => import('../views/ProficiencySummary.vue'),
    meta: {
      isPublic: true,
      title: '動画を習熟度で探す',
      desc: 'Special Learning の動画を習熟度で探す',
    },
  },
  {
    path: '/proficiency/:rank',
    name: 'Proficiency',
    component: () => import('../views/Proficiency.vue'),
    meta: {
      isPublic: true,
      title: '習熟度',
      desc: '習熟度',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      isPublic: true,
      title: '検索ページ',
      desc: 'Special Learning の動画をキーワード検索する',
    },
  },
  {
    path: '/search_lesson/:lessonTagId',
    name: 'SearchLesson',
    component: () => import('../views/SearchLesson.vue'),
    props: true,
    meta: {
      isPublic: true,
      title: 'タグ検索ページ',
      desc: 'タグ検索ページ',
    },
  },
  {
    path: '/lesson/:code/:prefix',
    name: 'Lesson',
    component: () => import('../views/Lesson.vue'),
    props: true,
    meta: {
      isPublic: true,
      title: '動画詳細ページ',
      desc: '動画詳細ページ',
    },
  },
  {
    path: '/informationlist',
    name: 'InformationList',
    component: () => import('../views/informations/List.vue'),
    meta: {
      isPublic: true,
      title: 'お知らせ',
      desc: 'Special Learningからの「お知らせ」を掲載しています。',
      breadcrumb: {
        label: 'お知らせ',
        parent: 'Home',
      },
    },
  },
  {
    path: '/information/:informationId',
    name: 'Information',
    component: () => import('../views/informations/Detail.vue'),
    meta: {
      isPublic: true,
      title: 'お知らせ',
      desc: 'お知らせ',
    },
  },
  {
    path: '/mypage/:organization_id',
    name: 'Mypage',
    component: () => import('../views/Mypage.vue'),
    meta: {
      isPublic: false,
      title: 'マイページ',
      desc: 'マイページ',
    },
  },
  {
    path: '/mypage',
    name: 'MypageCurrent',
    component: () => import('../views/Mypage.vue'),
    meta: {
      isPublic: false,
      title: 'マイページ',
      desc: 'マイページ',
      breadcrumb: {
        label: 'マイページ',
        parent: 'Home',
      },
    },
  },
  {
    path: '/viewingmanage/:organization_id',
    name: 'ViewingManage',
    component: () => import('../views/ViewingManage.vue'),
    meta: {
      isPublic: false,
      title: 'スタッフ管理',
      desc: 'スタッフ管理',
    },
  },
  {
    path: '/staffdetail/:organization_id/:user_id',
    name: 'StaffDetail',
    component: () => import('../views/StaffDetail.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'スタッフ管理',
      desc: 'スタッフ管理',
    },
  },
  {
    path: '/contentsdetail/:organization_id/:lesson_id',
    name: 'ContentsDetail',
    component: () => import('../views/ContentsDetail.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'スタッフ管理',
      desc: 'スタッフ管理',
    },
  },

  /***
   * レポート管理画面系
   */
  {
    path: '/staff/reports/:organizationId',
    name: 'OrganizationSummary',
    component: () => import('../views/staff/reports/OrganizationSummary.vue'),
    meta: {
      isPublic: false,
      title: 'レポート提出状況',
      desc: 'レポート提出状況',
      breadcrumb: {
        label: 'レポート管理',
        parent: 'MypageCurrent',
      },
    },
  },
  {
    path: '/staff/reports/:organizationId/:categoryId',
    name: 'UserStatuses',
    component: () => import('../views/staff/reports/UserStatuses.vue'),
    meta: {
      isPublic: false,
      title: '事業所内のレポート一覧',
      desc: '事業所内のレポート一覧',
    },
  },
  {
    path: '/staff/reports/:organizationId/user/:userId',
    name: 'UserSummary',
    component: () => import('../views/staff/reports/UserReportStatuses.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: '学習者が提出したレポート',
      desc: '学習者が提出したレポート',
    },
  },
  {
    path: '/staff/reports/lesson-plans/:lessonPlanId/users',
    name: 'LessonPlanUserStatuses',
    component: () =>
      import('../views/staff/reports/lessonPlans/UserStatuses.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: '研修計画のレポート一覧',
      desc: '研修計画のレポート一覧',
    },
  },
  {
    path: '/staff/reports/courses/:courseId/users',
    name: 'CourseUserReportStatuses',
    component: () =>
      import('../views/staff/reports/courses/CourseUserStatuses.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'コース毎のレポート提出ユーザー一覧',
      desc: 'コース毎のレポート提出ユーザー一覧',
    },
  },
  {
    path: '/staff/reports/training-events/:trainingEventId/users',
    name: 'TrainingEventUserReportStatuses',
    component: () =>
      import('@/views/staff/reports/trainingEvents/UserReportStatuses.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'カスタム研修毎のレポート提出ユーザー一覧',
      desc: 'カスタム研修毎のレポート提出ユーザー一覧',
    },
  },

  /***
   * 研修管理画面系
   */
  {
    path: '/staff/trainings',
    component: () => import('@/views/staff/trainings/index.vue'),
    meta: {
      isPublic: false,
      title: '研修管理',
      desc: '研修管理',
      breadcrumb: {
        label: '研修管理',
        parent: 'MypageCurrent',
      },
    },
    children: [
      {
        path: '',
        name: 'TrainingLists',
        component: () => import('@/views/staff/trainings/Lists.vue'),
        meta: {
          isPublic: false,
          title: '研修一覧',
          desc: '研修一覧',
          breadcrumb: '研修一覧',
        },
      },
      lessonPlans,
      trainingEvents,
    ],
  },
  {
    path: '/reports/detail/preview/:reportId',
    name: 'ReportPreview',
    component: () => import('../views/staff/reports/ReportPreview.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: '学習者が提出したレポート',
      desc: '学習者が提出したレポート',
    },
  },
  {
    path: '/reports/detail/feedback/:reportId',
    name: 'ReportFeedback',
    component: () => import('../views/staff/reports/ReportFeedback.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'フィードバック',
      desc: 'フィードバック',
    },
  },
  {
    path: '/reports/detail/reject/:reportId',
    name: 'ReportReject',
    component: () => import('../views/staff/reports/ReportReject.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'レポートを差し戻す',
      desc: 'レポートを差し戻す',
    },
  },
  {
    path: '/studentreportpreview/:reportId',
    name: 'StudentReportPreview',
    component: () =>
      import('../views/student/reports/StudentReportPreview.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'レポート作成',
      desc: 'レポート作成',
    },
  },
  {
    path: '/studentreportform/:report_id',
    name: 'StudentReportForm',
    component: () => import('../views/student/reports/StudentReportForm.vue'),
    meta: {
      isPublic: false,
      title: 'レポート作成',
      desc: 'レポート作成',
    },
  },
  {
    path: '/question',
    name: 'QuestionsAndAnswers',
    component: () => import('../views/QuestionsAndAnswers.vue'),
    meta: {
      isPublic: true,
      title: 'よくある質問',
      desc: 'Special Learning をご利用の皆さまからの「よくある質問」を掲載しています。',
    },
  },
  {
    path: '/usercontentslist',
    name: 'UserContentsList',
    component: () => import('../views/UserContentsList.vue'),
    meta: {
      isPublic: true,
      title: 'コンテンツ一覧',
      desc: 'コンテンツ一覧',
      breadcrumb: {
        label: 'コンテンツ一覧',
        parent: 'Home',
      },
    },
  },
  {
    path: '/simple-contents',
    name: 'SimpleContents',
    component: () => import('../views/SimpleContents.vue'),
    meta: {
      isPublic: false,
      title: '簡易コンテンツ一覧',
      desc: '簡易コンテンツ一覧',
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: {
      isPublic: true,
      title: '「Special Learning」利用規約',
      desc: 'この利用規約は、株式会社Lean on Meが提供する障がい者支援者向けオンライン研修サービス「Special Learning」を利用される全てのお客様に適用されます。',
    },
  },
  {
    path: '/keyword',
    name: 'Keyword',
    component: () => import('../views/Keyword.vue'),
    meta: {
      isPublic: true,
      title: 'すべてのキーワードをみる',
      desc: 'Special Learning のすべてのキーワードをみる',
    },
  },
  {
    path: '/teachers',
    component: () => import('../views/teachers/index.vue'),
    meta: {
      isPublic: true,
      title: '講師情報',
      desc: '講師情報',
    },
    children: [
      {
        path: '',
        name: 'TeacherList',
        component: () => import('../views/teachers/TeacherList.vue'),
        meta: {
          isPublic: true,
          title: '講師で探す',
          desc: 'Special Learning にご講演頂いている講師陣は、各領域トップクラスの専門家ばかりなのが、特徴の一つです。',
        },
      },
      {
        path: ':teacherCode',
        name: 'TeacherDetail',
        component: () => import('../views/teachers/Detail.vue'),
        props: true,
        meta: {
          isPublic: true,
          title: '講師詳細',
          desc: '講師詳細',
        },
      },
    ],
  },
  {
    path: '/sections',
    component: () => import('../views/sections/index.vue'),
    meta: {
      isPublic: true,
      title: 'Special Learning おすすめ動画',
      desc: 'Special Learning おすすめ動画',
      breadcrumb: {
        parent: 'Home',
      },
    },
    children: [
      {
        path: '',
        name: 'MediumSectionList',
        component: () => import('../views/sections/MediumSectionList.vue'),
        meta: {
          isPublic: true,
          title: 'Special Learning おすすめ動画一覧',
          desc: 'Special Learning おすすめ動画一覧',
          breadcrumb: {
            label: 'Special Learning おすすめ動画',
          },
        },
      },
    ],
  },
  {
    path: '/lesson-plans/:lessonPlanId',
    name: 'LessonPlanDetailForUser',
    component: () => import('../views/lessonPlans/Detail.vue'),
    meta: {
      isPublic: false,
      title: '研修計画',
      desc: '研修計画',
    },
  },
  {
    path: '/lesson-plans',
    component: () => import('../views/lessonPlans/index.vue'),
    meta: {
      isPublic: false,
      title: '研修計画',
      desc: '研修計画',
    },
    children: [
      {
        path: '',
        name: 'LessonPlanList',
        component: () => import('../views/lessonPlans/LessonPlanList.vue'),
        meta: {
          isPublic: false,
          title: '研修計画一覧',
          desc: '研修計画一覧',
        },
      },
    ],
  },
  {
    path: '/training-events/:trainingEventId',
    name: 'TrainingEventDetailForUser',
    component: () => import('@/views/TrainingEventDetail.vue'),
    props: true,
    meta: {
      isPublic: false,
      title: 'カスタム研修詳細',
      desc: 'カスタム研修詳細',
    },
  },
  {
    path: '/badges',
    name: 'BadgeList',
    component: () => import('@/views/badges/index.vue'),
    meta: {
      isPublic: false,
      title: 'バッジ一覧',
      desc: 'バッジ一覧',
      breadcrumb: {
        label: 'バッジ一覧',
        parent: 'MypageCurrent',
      },
    },
  },
  {
    path: '/staff/badges',
    name: 'UserBadge',
    component: () => import('@/views/staff/badges/index.vue'),
    meta: {
      isPublic: false,
      title: 'スタッフのバッジ',
      desc: 'スタッフのバッジ',
      breadcrumb: {
        label: 'バッジ一覧',
        parent: 'MypageCurrent',
      },
    },
    children: [
      {
        path: ':organizationId/users/:userId',
        name: 'UserBadgeList',
        component: () => import('@/views/staff/badges/UserBadgeList.vue'),
        props: true,
        meta: {
          isPublic: false,
          title: 'スタッフのバッジ獲得状況',
          desc: 'スタッフのバッジ獲得状況',
        },
      },
    ],
  },
  {
    path: '/calendar',
    name: 'TrainingEventCalendar',
    component: () => import('@/views/TrainingEventCalendar.vue'),
    meta: {
      isPublic: false,
      title: '年間研修管理',
      desc: '年間研修管理',
      breadcrumb: {
        label: '年間研修管理',
        parent: 'MypageCurrent',
      },
    },
  },
  {
    path: '/summit',
    name: 'Summit',
    component: () => import('../views/summit/index.vue'),
    meta: {
      isPublic: true,
      title: 'サミット特設ページ',
      desc: 'サミット特設ページ',
    },
    children: [
      {
        path: '2022spring',
        name: '2022spring',
        component: () => import('../views/summit/2022spring.vue'),
        meta: {
          isPublic: true,
          title:
            'Special Learning Summit Spring | スペシャルラーニングサミットスプリング',
          desc: '障がい福祉を取り巻く環境として、継続的な人手不足、長引くコロナ渦での事業所運営に加え、令和４年度からの虐待防止研修義務化など変化の多い春を迎えています。この状況を受け入れ、今改めて「教育」を見直し、あるべき障がい者支援・事業所運営を実現すべく、障がい福祉業界を代表するプロフェッショナルを集めた、障がい福祉の未来を考える1日、それがSpecial Learning Summitです。',
        },
      },
    ],
  },

  /***
   * エラー系
   */

  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/error/NotFound.vue'),
    meta: {
      isPublic: true,
      title: 'ページが存在しません',
      desc: 'ページが存在しません',
    },
  },

  courses,
  settings,
  seminars,
];
