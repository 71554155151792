import axios from 'axios';

export default () => {
  // 指定のセミナー情報取得
  const getForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/seminars/${id}`,
    );
    return response.data;
  };

  // セミナー一覧取得
  const getAllForUser = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/seminars`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 講師が担当するセミナー一覧を取得
  const getAllByTeacherForUser = async ({ teacherId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers/${teacherId}/seminars`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // セミナー数取得
  const getCountAllForUser = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/seminars/count`,
    );
    return response.data;
  };

  return {
    getForUser,
    getAllForUser,
    getAllByTeacherForUser,
    getCountAllForUser,
  };
};
