<template>
  <div class="pager-area">
    <div v-if="showPrev" class="previous-button" @click="prev()">前へ</div>
    <div v-if="showNext" class="next-button" @click="next()">次へ</div>
  </div>
</template>

<script>
export default {
  name: 'SLPagination',
  props: {
    dataCount: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    urlParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    showPrev() {
      return this.currentPage !== 1;
    },
    showNext() {
      return this.limit * this.currentPage < this.dataCount;
    },
  },
  watch: {
    urlParams: {
      handler: function () {
        this.currentPage = 1;
        this.updateURL();
      },
      deep: true,
    },
  },
  mounted() {
    this.currentPage = this.page;
  },
  methods: {
    next() {
      const url = new URL(location);

      if (this.currentPage === 1) {
        /*
        // 同じhistoryに書き換えると、ブラウザバック時にライフサイクルフックが動かないため
        // paramによって登録するhistoryを変える
        */
        url.searchParams.get('page')
          ? url.searchParams.delete('page')
          : url.searchParams.set('page', 1);

        history.replaceState(null, '', url.href);
      }

      this.currentPage++;
      sessionStorage.setItem('pagerAction', 'next');
      this.updateURL();
    },
    prev() {
      this.currentPage--;
      sessionStorage.setItem('pagerAction', 'prev');
      this.updateURL();
    },
    updateURL() {
      const url = new URL(location);

      url.searchParams.set('page', this.currentPage);

      if (this.urlParams !== null) {
        Object.keys(this.urlParams).forEach((key) => {
          url.searchParams.delete(key);
          url.searchParams.append(key, this.urlParams[key]);
        });
      }

      history.pushState(null, '', url.href);
      this.$emit('click', this.currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.pager-area {
  font-weight: bold;

  .previous-button,
  .next-button {
    display: inline-block;
    width: 50px;
    height: 35px;
    margin: 0 5px;
    padding: 0 8px;
    line-height: 35px;
    color: #fff;
    background-image: url('~@/assets/img/pager_pink.png');
    background-repeat: no-repeat;
    background-size: 100px 35px;
    cursor: pointer;
  }

  .next-button {
    text-align: left;
    background-position: -50px 0;
  }

  .previous-button {
    text-align: right;
    background-position: 0 0;
  }

  .next-button:hover,
  .previous-button:hover {
    opacity: 0.6;
  }
}
</style>
