<template>
  <span class="datepicker-wrapper">
    <datepicker
      :language="ja"
      :format="customFormatter"
      :value="value"
      @input="updateValue"
    />
    <span class="arrow-icon" />
  </span>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'SLDatepicker',
  components: {
    Datepicker,
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      ja,
    };
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    },
    customFormatter: function (dateStr) {
      const date = new Date(dateStr);

      return `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日`;
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker-wrapper {
  display: flex;
  position: relative;

  .arrow-icon {
    margin: auto;
    width: 3rem;
    height: 1rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    content: '';
    background-image: url('../../assets/img/arrow_under.png');
    background-repeat: no-repeat;
    background-size: 3rem;
    background-position: center;
    pointer-events: none;
  }

  @include mq(md) {
    ::v-deep .vdp-datepicker__calendar {
      right: 0;
    }
  }

  ::v-deep input {
    width: 100%;
  }
}
</style>
