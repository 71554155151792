const setPageTitle = (content) => {
  window.document.title = `${content} | Special Learning`;
};

const setDescription = (content) => {
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', content);
};

const setRobots = (content) => {
  document
    .querySelector('meta[name="robots"]')
    .setAttribute('content', content);
};

export { setPageTitle, setDescription, setRobots };
