import axios from 'axios';

export default () => {
  const trashForOrganizer = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${id}`,
    );
    return response.data;
  };

  // 事業所管理者による研修計画一覧取得
  const getAllForOrganizer = async ({
    query,
    limit,
    offset,
    sort,
    order,
    organizationIds,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans`,
      {
        params: {
          q: query,
          limit,
          offset,
          sort,
          order,
          organizations: organizationIds,
        },
      },
    );
    return response.data;
  };

  /** ユーザーが所属している事業所に紐付いた研修計画一覧取得 */
  const getForUser = async ({
    query,
    isIncludeUpcoming,
    since,
    until,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans`,
      {
        params: {
          q: query,
          isIncludeUpcoming,
          since,
          until,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getInProgressForUser = async ({ query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/@inProgress`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${id}`,
    );
    return response.data;
  };

  const registerPictureForOrganizer = async ({ pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/picture`,
      formData,
    );
    return response.data;
  };

  const updatePictureForOrganizer = async ({ id, pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${id}/picture`,
      formData,
    );
    return response.data;
  };

  const createForOrganizer = async ({
    name,
    description,
    organizationIds,
    startsAt,
    endsAt,
    contents,
    links,
    state,
    pictureId,
  }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans`,
      {
        name,
        description,
        organizationIds,
        startsAt,
        endsAt,
        contents,
        links,
        state,
        pictureId,
      },
    );
    return response.data;
  };

  const updateForOrganizer = async ({
    id,
    name,
    description,
    organizationIds,
    startsAt,
    endsAt,
    contents,
    links,
    state,
    pictureId,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${id}`,
      {
        name,
        description,
        organizationIds,
        startsAt,
        endsAt,
        contents,
        links,
        state,
        pictureId,
      },
    );
    return response.data;
  };

  const getBylessonPlan = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/${id}`,
    );
    return response.data;
  };

  const getCount = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/count`,
    );
    return response.data;
  };

  // 研修計画数取得(管理者用)
  const getCountForOrganizer = async ({ query, organizationIds }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/count`,
      {
        params: {
          q: query,
          organizationIds,
        },
      },
    );
    return response.data;
  };

  const getStudyMemoForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/${id}/studyMemo`,
    );
    return response.data;
  };

  const updateStudyMemoForUser = async ({ id, contents }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/${id}/studyMemo`,
      {
        contents,
      },
    );
    return response.data;
  };

  const getContentsForOrganizer = async ({ id, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${id}/contents`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  return {
    trashForOrganizer,
    getAllForOrganizer,
    getForOrganizer,
    getForUser,
    getInProgressForUser,
    registerPictureForOrganizer,
    updatePictureForOrganizer,
    createForOrganizer,
    updateForOrganizer,
    getBylessonPlan,
    getCount,
    getCountForOrganizer,
    getStudyMemoForUser,
    updateStudyMemoForUser,
    getContentsForOrganizer,
  };
};
