<template>
  <fieldset class="sl-checkbox-wrapper">
    <template v-for="(option, index) in options">
      <span :key="index" class="sl-checkbox">
        <input
          :id="option.label"
          type="checkbox"
          name="label"
          :value="option.value"
          :checked="checked(option)"
          @change="updateValue"
        />
        <label class="checkbox-label" :for="option.label">
          <span class="checkbox-input" />
          <p class="label-text">
            {{ option.label }}
          </p>
        </label>
      </span>
    </template>
  </fieldset>
</template>

<script>
export default {
  name: 'SLCheckbox',
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      values: [],
    };
  },
  mounted() {
    this.values.push(...this.$attrs.value);
  },
  methods: {
    updateValue(event) {
      if (event.target.checked) {
        this.values.push(event.target.value);
      } else {
        this.values = this.$attrs.value.filter(
          (value) => value !== event.target.value,
        );
      }

      this.$emit('input', this.values);
    },
    checked(option) {
      return !!this.$attrs.value.find((value) => value === option.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.sl-checkbox-wrapper {
  border: none;

  .sl-checkbox {
    display: flex;
    align-items: center;

    .checkbox-label {
      cursor: pointer;
      display: flex;
      align-items: flex-start;

      .label-text {
        margin-left: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
      }
    }

    .checkbox-input {
      box-sizing: border-box;
      cursor: pointer;
    }

    input[type='checkbox'] {
      display: none;

      & + .checkbox-label {
        > .checkbox-input {
          &::before {
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 12px;
            content: '';
            border: solid 1px #4d4d4d;
          }
        }
      }

      &:checked {
        & + .checkbox-label {
          font-weight: bold;

          > .checkbox-input {
            margin-right: 3px;

            &::before {
              display: inline-block;
              vertical-align: middle;
              width: 12px;
              height: 12px;
              content: '';
              border: solid 1px #4d4d4d;
              background-color: #4d4d4d;
            }

            &::after {
              opacity: 1;
              display: inline-block;
              vertical-align: middle;
              margin-top: -4px;
              margin-left: -11px;
              width: 6px;
              height: 8px;
              content: '';
              border-right: solid 2px white;
              border-bottom: solid 2px white;
              transform: rotate(45deg);
            }

            & + .checkbox-label {
              margin-left: 7px;
            }
          }
        }
      }
    }
  }
}
</style>
