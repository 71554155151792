<template>
  <div id="complete-training-event-modal">
    <div class="close-icon" @click="close" />
    <div class="messages">
      <h2 class="mt-2">受講完了にする</h2>
      <p class="mt-5">
        「
        <PrefixIcon text="カスタム研修" black />
        {{ `${payload.trainingEvent.name}」の受講状況を完了に変更します。` }}
      </p>
      <p class="mt-5">
        受講完了後、カスタム研修レポートを作成することができます。通知またはマイページのレポートより、レポート作成に進んでください。
      </p>
      <div class="buttons">
        <SLButton
          ref="cancelButton"
          black
          text="キャンセル"
          :on-click="close"
          tabindex="1"
        />
        <SLButton
          text="受講完了にする"
          :on-click="updateStatusToComplete"
          tabindex="2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PrefixIcon from '@/components/common/PrefixIcon.vue';
import repository from '@/api/apiRepositoryFactory';

export default {
  name: 'CompleteTrainingEventModal',
  components: {
    PrefixIcon,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // モーダルを開いた時に、モーダル内にフォーカスする
    this.$refs.cancelButton.$el.focus();
  },
  methods: {
    close() {
      this.$store.dispatch('modal/close');
    },
    // カスタム研修の参加状況を参加済みに更新
    async updateStatusToComplete() {
      const trainingEventApi = repository.trainingEvents();
      try {
        await trainingEventApi.updateUserStateToCompletedForUser({
          id: this.payload.trainingEvent.id,
        });

        this.close();
        this.payload.getTrainingEvent();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#complete-training-event-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 95%;
  max-width: 450px;
  height: auto;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/img/close_icon.png');
    background-size: 20px;
    background-position: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .messages {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttons {
      margin-top: 24px;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      > button {
        width: 120px;
      }
    }
  }
}
</style>
