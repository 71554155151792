<template>
  <div id="event-list-modal">
    <div class="close-icon" @click="close"></div>
    <div class="modal-wrapper">
      <div class="mt-4 dates">
        <p class="day-of-week">{{ dayOfWeek }}</p>
        <b class="date">{{ payload.date.format('DD') }}</b>
      </div>
      <ul class="event-list">
        <router-link
          v-for="eventItem in payload.events"
          :key="eventItem.event.id"
          :to="getLinkParams(eventItem)"
          class="event-item"
          :class="getEventTypeClass(eventItem.type)"
          tag="li"
          @click.native="close"
        >
          <div>
            <p class="event-name">{{ eventItem.event.name }}</p>
            <p v-if="eventItem.type === 'lessonPlan'">
              {{ getDuration(eventItem.event) }}
            </p>
          </div>
        </router-link>
      </ul>
      <div class="buttons">
        <SLButton
          ref="cancelButton"
          black
          text="閉じる"
          :on-click="close"
          tabindex="1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { translateDayOfWeek, getDurationText } from '@/libs/timeFormatter';

export default {
  name: 'EventListModal',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dayOfWeek() {
      return translateDayOfWeek(this.payload.date.day());
    },
  },
  methods: {
    getDuration(event) {
      const duration = getDurationText(event.startsAt, event.endsAt);

      if (duration.endsAt === null) {
        return duration.startsAt;
      } else {
        return `${duration.startsAt} ～ ${duration.endsAt}`;
      }
    },
    getLinkParams(eventItem) {
      if (eventItem.type === 'lessonPlan') {
        return {
          name: 'LessonPlanDetailForUser',
          params: {
            lessonPlanId: eventItem.event.id,
          },
        };
      } else {
        return {
          name: 'TrainingEventDetailForUser',
          params: {
            trainingEventId: eventItem.event.id,
          },
        };
      }
    },
    getEventTypeClass(eventType) {
      if (eventType === 'lessonPlan') {
        return 'lesson-plan';
      } else {
        return 'training-event';
      }
    },
    close() {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>

<style lang="scss" scoped>
#event-list-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 20px;
  width: 95%;
  max-width: 450px;
  height: auto;
  max-height: 600px;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-size: 14px;

  .close-icon {
    @include icon-modal-close;
  }

  .modal-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dates {
      display: flex;
      flex-direction: column;
      align-items: center;

      .day-of-week {
        font-size: 0.9rem;
      }
      .date {
        font-size: 1.3rem;
      }
    }

    .event-list {
      margin-top: 24px;
      width: 100%;
      max-height: 70%;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .event-item {
        padding: 8px;
        border-radius: 8px;
        border: 2px solid #7f7f7f;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          margin-left: 3px;
          display: inline-block;
          vertical-align: middle;
          min-width: 1rem;
          height: 1rem;
          content: '';
          background-repeat: no-repeat;
          background-size: contain;
        }

        .event-name {
          @include one-line-ellipsis;
        }

        &.lesson-plan {
          background-color: #7f7f7f;
          color: white;

          &::after {
            background-image: url('~@/assets/img/arrow_white.png');
          }
        }

        &.training-event {
          background-color: white;
          color: #7f7f7f;

          &::after {
            background-image: url('~@/assets/img/arrow.png');
          }
        }
      }
    }

    .buttons {
      margin-top: 24px;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      > button {
        width: 120px;
      }
    }
  }
}
</style>
