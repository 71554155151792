<template>
  <button class="sl-button" :type="type" :class="styles" @click="onClick">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'SLButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    text: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    black: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return {
        black: this.black,
        arrow: this.arrow,
        download: this.icon === 'download',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sl-button {
  padding: 6px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff7171;
  border: solid 2px #ff7171;
  border-radius: 24px;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  font-family: YuGothic, 'Yu Gothic', 'Noto Sans JP', sans-serif;
  font-weight: bold;

  &:hover {
    opacity: 0.6;
  }

  &.arrow {
    &::after {
      margin-left: 3px;
      display: inline-block;
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
      content: '';
      background-image: url('~@/assets/img/arrow_white.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &.download {
    &::before {
      margin-right: 3px;
      vertical-align: middle;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      content: '';
      background-image: url('~@/assets/img/download_white_icon.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &.black {
    // black時のCSSはこのブロック内に記述していく
    background-color: white;
    border: solid 2px #4d4d4d;
    color: #4d4d4d;

    &:hover {
      // hover時のアイコンの変更などはこのブロック内に記述していく
      background-color: #4d4d4d;
      opacity: unset;
      color: white;

      &.download {
        &::before {
          background-image: url('~@/assets/img/download_white_icon.png');
        }
      }

      &.arrow {
        &::after {
          background-image: url('~@/assets/img/arrow_white.png');
        }
      }
    }

    &.arrow {
      &::after {
        margin-left: 3px;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        content: '';
        background-image: url('~@/assets/img/arrow.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &.download {
      &::before {
        margin-right: 3px;
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        content: '';
        background-image: url('~@/assets/img/download_icon.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
