import groupLearnings from '@/router/routes/groupLearnings';

export default {
  path: 'lesson-plans',
  component: () => import('@/views/staff/trainings/lessonPlans/index.vue'),
  meta: {
    title: '研修計画',
    breadcrumb: null,
  },
  children: [
    {
      path: 'create',
      name: 'LessonPlanCreate',
      component: () => import('@/views/staff/trainings/lessonPlans/Create.vue'),
      meta: {
        isPublic: false,
        title: '研修計画の作成',
        desc: '研修計画の作成',
        breadcrumb: '研修計画の作成',
      },
    },
    {
      path: ':lessonPlanId',
      component: () =>
        import('@/views/staff/trainings/lessonPlans/DetailIndex.vue'),
      props: true,
      meta: {
        breadcrumb() {
          // 研修計画名を取得
          const lessonPlanName = localStorage.getItem('breadcrumbName');
          return lessonPlanName ?? '研修計画の詳細';
        },
      },
      children: [
        {
          path: '',
          name: 'LessonPlanDetailForOrganizer',
          component: () =>
            import('@/views/staff/trainings/lessonPlans/Detail.vue'),
          props: true,
          meta: {
            isPublic: false,
            title: '研修計画の詳細',
            desc: '研修計画の詳細',
          },
        },
        {
          path: 'edit',
          name: 'LessonPlanEdit',
          component: () =>
            import('@/views/staff/trainings/lessonPlans/Edit.vue'),
          props: true,
          meta: {
            isPublic: false,
            title: '研修計画の編集',
            desc: '研修計画の編集',
            breadcrumb: '研修計画の編集',
          },
        },
        groupLearnings,
      ],
    },
  ],
};
