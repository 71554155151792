export default {
  path: '/settings',
  name: 'UserSettings',
  component: () => import('@/views/settings/index.vue'),
  meta: {
    isPublic: false,
    title: 'ユーザー設定',
    desc: 'ユーザー情報の確認・設定画面',
  },
  children: [
    {
      path: 'email/reset',
      name: 'ResetEmail',
      component: () => import('@/views/settings/email/Reset.vue'),
      meta: {
        isPublic: false,
        title: 'メールアドレス変更',
        desc: 'メールアドレス変更',
      },
    },
  ],
};
