export default class User {
  constructor(user) {
    this.id = user.id;
    this.role = user.role;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.code = user.code;
    this.targetStudyTimeSeconds = user.targetStudyTimeSeconds;
    this.picture = user.picture;
    this.isEnabledEmailNotification = user.isEnabledEmailNotification;
    this.state = user.state;
    this.organization = user.organization;
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }

  get roleText() {
    switch (this.role) {
      case 'organizer':
        return '管理者';
      case 'student':
        return '学習者';
      default:
        return '';
    }
  }
}
