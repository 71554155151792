import axios from 'axios';

export default () => {
  // 研修タグの一覧を取得
  const getAll = async ({ query, limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/trainingTags`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 研修タグを作成
  const createForOrganizer = async ({ name }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingTags`,
      {
        name,
      },
    );
    return response.data;
  };

  return {
    getAll,
    createForOrganizer,
  };
};
