import axios from 'axios';

export default () => {
  // レッスンタグを取得
  const get = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonTags/${id}`,
    );
    return response.data;
  };

  const getAll = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonTags`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // レッスンに属するレッスンタグ一覧を取得
  const getAllByLesson = async ({ lessonId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessons/${lessonId}/lessonTags`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // レッスンに属するレッスンタグ一覧を取得(ユーザー用)
  const getAllByLessonForUser = async ({ lessonId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${lessonId}/lessonTags`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByTeacher = async ({ teacherId, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/teachers/${teacherId}/lessonTags`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByTeacherForUser = async ({ teacherId, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers/${teacherId}/lessonTags`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // コース内のレッスンタグ一覧取得
  const getAllByCourse = async ({ courseId, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/courses/${courseId}/lessonTags`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // ユーザーによるコースのレッスンタグ一覧取得
  const getAllByCourseForUser = async ({ courseId, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/courses/${courseId}/lessonTags`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  return {
    get,
    getAll,
    getAllByLesson,
    getAllByLessonForUser,
    getByTeacher,
    getByTeacherForUser,
    getAllByCourse,
    getAllByCourseForUser,
  };
};
