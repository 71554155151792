<template>
  <fieldset class="sl-radio-button-wrapper">
    <span
      v-for="(option, index) in options"
      :key="index"
      class="sl-radio-button"
    >
      <input
        :id="`${name}-${option.value}`"
        type="radio"
        :name="name"
        :value="option.value"
        :checked="checked(option)"
        @change="updateValue"
      />
      <label class="radio-button-label" :for="`${name}-${option.value}`">
        <span class="radio-button-input" />
        {{ option.label }}
      </label>
    </span>
  </fieldset>
</template>

<script>
export default {
  name: 'SLRadioButton',
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
    checked(option) {
      return this.$attrs.value === option.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.sl-radio-button-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: none;

  @include mq(md) {
    flex-direction: column;
  }

  .sl-radio-button {
    display: flex;
    align-items: center;
    margin-right: 60px;

    @include mq(md) {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }

    .radio-button-label {
      cursor: pointer;
      white-space: nowrap;
    }

    .radio-button-input {
      box-sizing: border-box;
    }

    input[type='radio'] {
      display: none;

      & + .radio-button-label {
        > .radio-button-input {
          display: inline-block;
          vertical-align: middle;
          width: 14px;
          height: 14px;
          content: '';
          border: solid 1px #4d4d4d;
          border-radius: 50%;
        }
      }

      &:checked {
        & + .radio-button-label {
          font-weight: bold;

          > .radio-button-input {
            display: inline-block;
            vertical-align: middle;
            width: 14px;
            height: 14px;
            content: '';
            border: solid 4px #4d4d4d;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
