import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import VueGtag from 'vue-gtag';
import VueGtm from 'vue-gtm';
import axios from 'axios';
import VueSlideUpDown from 'vue-slide-up-down';
import { VueAgile } from 'vue-agile';
import Pager from '@/components/common/Pager.vue';
import SLPagination from '@/components/common/SLPagination.vue';
import SLButton from '@/components/common/SLButton.vue';
import SLInputFile from '@/components/common/SLInputFile.vue';
import SLCheckbox from '@/components/common/SLCheckbox.vue';
import SLRadioButton from '@/components/common/SLRadioButton.vue';
import VueClipboard from 'vue-clipboard2';
import VueMq from 'vue-mq';
import SLDatepicker from '@/components/common/SLDatepicker.vue';
import VueBreadcrumbs from 'vue-2-breadcrumbs';
import RequiredIcon from '@/components/common/RequiredIcon.vue';
import PrefixIcon from '@/components/common/PrefixIcon.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/plugins/VeeValidate';
import VueScrollTo from 'vue-scrollto';
import 'material-icons/iconfont/material-icons.css';
import Toasted from 'vue-toasted';
import FormErrorMessages from '@/components/common/FormErrorMessages.vue';
import TopicTitle from '@/components/common/TopicTitle.vue';
import NetworkMessages from '@/components/common/NetworkMessages.vue';
import drag from 'v-drag';

Vue.use(VueClipboard);

/* eslint-disable camelcase */
// ライブラリの仕様でプロパティ名がsnake_caseになっているため
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GA,
    },
    params: {
      send_page_view: true,
    },
  },
  router,
);
/* eslint-enable camelcase */

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM,
});

Vue.use(VueMq, {
  breakpoints: {
    sp: 1025,
    pc: Infinity,
  },
  defaultBreakpoint: 'pc',
});
Vue.use(VueBreadcrumbs);
Vue.use(VueScrollTo);
Vue.use(Toasted);
Vue.use(drag);

Vue.component('SlideUpDown', VueSlideUpDown);
Vue.component('SLDatepicker', SLDatepicker);
Vue.component('SLPager', Pager);
Vue.component('SLPagination', SLPagination);
Vue.component('SLButton', SLButton);
Vue.component('SLInputFile', SLInputFile);
Vue.component('SLCheckbox', SLCheckbox);
Vue.component('SLRadioButton', SLRadioButton);
Vue.component('RequiredIcon', RequiredIcon);
Vue.component('PrefixIcon', PrefixIcon);
Vue.component('FormErrorMessages', FormErrorMessages);
Vue.component('TopicTitle', TopicTitle);
Vue.component('NetworkMessages', NetworkMessages);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('VueAgile', VueAgile);

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Cookie期限切れ等で認証エラーの場合、ログアウト処理する
    if (error?.response?.status === 401) {
      await store.dispatch('user/logout');
    }

    return Promise.reject(error);
  },
);

new Vue({
  router,
  store,
  VueScrollTo,
  render: (h) => h(App),
}).$mount('#app');

Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_DEVTOOLS;
