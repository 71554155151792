export default {
  namespaced: true,
  state: {
    token: '',
    selected_organization_id: '',
    reset_organization_id: false,
    select_staff: {
      code: '',
      organization_id: '',
      name: '',
    },
    select_lesson: {
      lesson_id: '',
      organization_id: '',
      prefix: '',
      name: '',
    },
    select_report_check_staff: {
      code: '',
      organization_id: '',
      name: '',
    },
    select_list: '',
    spoofed_organization_id: null,
  },
  getters: {
    organizations(state) {
      return state.organizations;
    },
    token: (state) => state.token,
    isSpoofed(state) {
      return !!state.spoofed_organization_id;
    },
  },
  mutations: {
    setToken(state, token) {
      this.state.userinfo = {};
      this.state.userinfo.token = token;
      this.state.auth.token = token;
    },
    setSelectedOrganizationId(state, org_id) {
      this.state.userinfo.selected_organization_id = org_id;
    },
    setResetOrganizationId(state, bool) {
      this.state.userinfo.reset_organization_id = bool;
    },
    setSelectedStaff(state, staff) {
      this.state.userinfo.select_staff = {};
      this.state.userinfo.select_staff.code = staff.code;
      this.state.userinfo.select_staff.name = staff.name;
      this.state.userinfo.select_staff.organization_id = staff.organization_id;
    },
    setSelectedReportCheckStaff(state, staff) {
      this.state.userinfo.select_report_check_staff = {};
      this.state.userinfo.select_report_check_staff.code = staff.code;
      this.state.userinfo.select_report_check_staff.name = staff.name;
      this.state.userinfo.select_report_check_staff.organization_id =
        staff.organization_id;
    },
    setSelectedLesson(state, lesson) {
      this.state.userinfo.select_lesson = {};
      this.state.userinfo.select_lesson.name = lesson.name;
      this.state.userinfo.select_lesson.prefix = lesson.prefix;
      this.state.userinfo.select_lesson.lesson_id = lesson.lesson_id;
      this.state.userinfo.select_lesson.organization_id =
        lesson.organization_id;
    },
    setSelectedListType(state, list) {
      this.state.userinfo.select_list = list;
    },
    setSpoof(state, spoofedOrganizationId) {
      state.spoofed_organization_id = spoofedOrganizationId;
    },
    destroy(state) {
      state.selected_organization_id = '';
      state.reset_organization_id = false;
      state.select_list = '';
      state.select_staff = {};
      state.select_lesson = {};
      state.select_report_check_staff = {};
      state.token = '';
      state.spoofed_organization_id = null;
    },
  },
  actions: {
    spoofLogin({ commit }, spoofedOrganizationId) {
      commit('setSpoof', spoofedOrganizationId);
    },
  },
};
