export default {
  namespaced: true,
  state: {
    token: '',
  },
  mutations: {
    create(state, data) {
      state.status = 200;
      state.token = data.token;
    },
    destroy(state) {
      state.status = '';
      state.token = '';
    },
    error(state) {
      state.status = 403;
    },
  },

  watch: {
    token: function (value) {
      this.setCookie('token', value);
    },
  },

  methods: {
    getCookie: function (key) {
      return this.$cookies.get(key);
    },
    setCookie: function (key, value) {
      this.$cookies.config(60 * 60 * 24 * 30, '');
      this.$cookies.set(key, value);
    },
  },
  getters: {
    getStatus: (state) => {
      return state.status;
    },
    getToken: (state) => {
      return state.token;
    },
  },
};
