import axios from 'axios';

export default () => {
  const getAllByOrganizationForStudent = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/organization/organizationGroups`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getAllByOrganizationForOrganizer = async ({
    organizationId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/organizationGroups`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getAllForStudent = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/organization/organizationGroups`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  return {
    getAllByOrganizationForStudent,
    getAllByOrganizationForOrganizer,
    getAllForStudent,
  };
};
