const FETCH_LIMIT = 100;

const fetchAllData = async (
  fetchFunction,
  parameters = {},
  result = [],
  offset = 0,
  limit = FETCH_LIMIT,
) => {
  const fetchedData = await fetchFunction({ offset, limit, ...parameters });
  result.push(...fetchedData);

  if (fetchedData.length === limit) {
    await fetchAllData(fetchFunction, parameters, result, offset + limit);
  }

  return result;
};

export { fetchAllData };
