import axios from 'axios';

const state = {
  code: '',
  data: '',
  timeout: 60 * 1000,
  message: '',
};

const getters = {
  getApiInfoStatus(state) {
    return state.code;
  },
  getApiInfo(state) {
    return state.data;
  },
  postApiInfo(state) {
    return state.data;
  },
  putApiInfo(state) {
    return state.data;
  },
  message(state) {
    return state.message;
  },
  code(state) {
    return state.code;
  },
};

const mutations = {
  getApiInfo(state, payload) {
    state.data = payload.data;
    state.code = payload.code;
  },
  postApiInfo(state, payload) {
    state.data = payload.data;
    state.code = payload.code;
  },
  putApiInfo(state, payload) {
    state.data = payload.data;
    state.code = payload.code;
  },
  message(state, payload) {
    state.message = payload;
  },
  code(state, payload) {
    state.code = payload;
  },
};

const actions = {
  async getApiInfoAction(context, payload) {
    const payload_data = {
      code: '',
      data: '',
    };
    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .get(payload.url, {
          headers: global.headers,
          withCredentials: true,
          params: payload.params,
          timeout: state.timeout,
        })
        .then((response) => {
          payload_data.code = response.status;
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.code = error.status;
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('getApiInfo', payload_data);
        });
    });
  },

  async authAction(context, payload) {
    const payload_data = {
      data: '',
    };

    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .post(payload.url, payload.params, {
          headers: global.headers,
          withCredentials: true,
        })
        .then((response) => {
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('postApiInfo', payload_data);
        });
    });
  },

  async postApiInfoAction(context, payload) {
    const payload_data = {
      data: '',
    };
    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .post(payload.url, payload.params, {
          headers: global.headers,
          withCredentials: true,
        })
        .then((response) => {
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('postApiInfo', payload_data);
        });
    });
  },

  async deleteApiInfoAction(context, payload) {
    const payload_data = {
      data: '',
    };

    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .delete(payload.url, {
          data: payload.params,
          timeout: state.timeout,
          headers: global.headers,
          withCredentials: true,
        })
        .then((response) => {
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('getApiInfo', payload_data);
        });
    });
  },
  async putApiInfoAction(context, payload) {
    const payload_data = {
      data: '',
    };

    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .put(payload.url, payload.params, {
          headers: global.headers,
          withCredentials: true,
        })
        .then((response) => {
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('putApiInfo', payload_data);
        });
    });
  },
  async putFileApiInfoAction(context, payload) {
    const payload_data = {
      data: '',
    };
    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .put(payload.url, payload.params, {
          headers: global.headers,
          withCredentials: true,
        })
        .then((response) => {
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('putApiInfo', payload_data);
        });
    });
  },
  async postFileApiInfoAction(context, payload) {
    const payload_data = {
      data: '',
    };

    global.headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };

    await new Promise((resolve, reject) => {
      axios
        .post(payload.url, payload.params, {
          headers: global.headers,
          withCredentials: true,
        })
        .then((response) => {
          payload_data.data = response.data;
          resolve(response);
        })
        .catch((error) => {
          payload_data.data = error;
          reject(error);
        })
        .finally(() => {
          context.commit('postApiInfo', payload_data);
        });
    });
  },
};

const requestApi = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default requestApi;
