import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * 秒数を受け取り、HH:mm:ss形式にフォーマットして返す
 * @param durationSeconds 秒数
 * @returns {string} HH:mm:ss形式の文字列
 */
const format = (durationSeconds) => {
  const durationObject = dayjs.duration(durationSeconds, 'seconds');

  return durationObject.hours() > 0
    ? durationObject.format('HH:mm:ss')
    : durationObject.format('mm:ss');
};

const dateTimeToString = (unixTimeMilliseconds) => {
  const date = new Date(unixTimeMilliseconds);
  return (
    date.getFullYear() +
    '年' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '月' +
    ('0' + date.getDate()).slice(-2) +
    '日' +
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2)
  );
};

const dateToString = (unixTimeMilliseconds) => {
  const date = new Date(unixTimeMilliseconds);
  return (
    date.getFullYear() +
    '年' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '月' +
    ('0' + date.getDate()).slice(-2) +
    '日'
  );
};

const yearToString = (unixTimeMilliseconds) => {
  const date = new Date(unixTimeMilliseconds);
  return date.getFullYear() + '年';
};

const monthDayToString = (unixTimeMilliseconds) => {
  const date = new Date(unixTimeMilliseconds);
  return (
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '月' +
    ('0' + date.getDate()).slice(-2) +
    '日'
  );
};

// unixtime[ms]を 時分 形式に変換
const formatUnixTimeMillisecondsToHourMinuteString = (unixTimeMilliseconds) => {
  const date = new Date(unixTimeMilliseconds);
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);

  return `${hour}:${minute}`;
};

// unixtime[ms]を 時分秒 形式に変換
const formatUnixTimeMillisecondsToHourMinuteSecondString = (
  unixTimeMilliseconds,
) => {
  const date = new Date(unixTimeMilliseconds);
  const hour = ('0' + date.getHours()).slice(-2);
  const minute = ('0' + date.getMinutes()).slice(-2);
  const second = ('0' + date.getSeconds()).slice(-2);

  return `${hour}:${minute}:${second}`;
};

/*
 * 日付と時刻を結合してUnixTime[ms]に変換する
 * 次の形式に対応している
 * dateString - 例) 'Tue Mar 1 2023 12:00:00 GMT+0900 (日本標準時)'
 * startTime - 例) '14:35'
 */
const convertDateTimeToUnixTimeMilliseconds = (dateString, startTime) => {
  const date = new Date(dateString);
  const hour = startTime.slice(0, 2);
  const minute = startTime.slice(3, 5);

  date.setHours(hour);
  date.setMinutes(minute);
  const unixTimeMilliseconds = date.getTime();

  return unixTimeMilliseconds;
};

/**
 * 期間を表すテキストを取得する
 * startsAtとendsAtが同日の場合は、endsAt側の年月日を省略する
 * @param {dayjs.Dayjs} startsAt 開始日時
 * @param {dayjs.Dayjs} endsAt 終了日時
 * @param {string} [DATE_TIME_FORMAT=YYYY年MM月DD日] 日時のフォーマット
 * @param {string=} END_DATE_TIME_FORMAT 終了日時のフォーマット
 * @returns フォーマット後の日時テキスト
 */
const getDurationText = (
  startsAt,
  endsAt,
  DATE_TIME_FORMAT = 'YYYY年MM月DD日',
  END_DATE_TIME_FORMAT = null,
) => {
  let formattedStartsAt = null;
  let formattedEndsAt = null;

  if (!endsAt || startsAt.isSame(endsAt, 'day')) {
    formattedStartsAt = startsAt.format(DATE_TIME_FORMAT);
  } else {
    formattedStartsAt = startsAt.format(DATE_TIME_FORMAT);
    formattedEndsAt = endsAt.format(END_DATE_TIME_FORMAT ?? DATE_TIME_FORMAT);
  }

  return {
    startsAt: formattedStartsAt,
    endsAt: formattedEndsAt,
  };
};

/**
 * 日本語の曜日を取得
 * @param index 曜日を表す数値 (0-6)
 * @returns 数値に対応する曜日(日本語)
 */
const translateDayOfWeek = (index) => {
  const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];

  return daysOfWeek[index];
};

export {
  format,
  dateTimeToString,
  dateToString,
  yearToString,
  monthDayToString,
  formatUnixTimeMillisecondsToHourMinuteString,
  formatUnixTimeMillisecondsToHourMinuteSecondString,
  convertDateTimeToUnixTimeMilliseconds,
  getDurationText,
  translateDayOfWeek,
};
