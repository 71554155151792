<template>
  <div id="remove-group-learning-modal">
    <div class="close-icon-wrapper">
      <p class="close-icon" @click="close" />
    </div>
    <h2 class="modal-title">集合研修を削除しますか？</h2>
    <div class="group-learning-container">
      <table>
        <div class="description-wrapper">
          <p class="modal-description">
            {{ modalDescription }}
          </p>
        </div>
        <div class="group-learning-info">
          <div class="data-time-wrapper">
            <h3 class="sub-title">・開催日時</h3>
            <p class="group-learning-date-time">
              {{ startDateTime }}
            </p>
          </div>
          <div class="data-wrapper">
            <h3 class="sub-title">・開催場所</h3>
            <p class="group-learning-data">{{ location }}</p>
          </div>
          <div class="data-wrapper">
            <h3 class="sub-title-long">・研修使用アカウント</h3>
            <p class="group-learning-data">{{ mainParticipantName }}</p>
          </div>
        </div>
        <div class="lesson-plan-info">
          <div class="data-wrapper">
            <h3 class="sub-title">・視聴履歴</h3>
            <p class="lesson-plan-data">
              {{ `${lessonPlan.prefix} ${lessonPlan.name}` }}
            </p>
          </div>
          <div class="data-wrapper">
            <h3 class="sub-title">・視聴時間</h3>
            <p class="lesson-plan-data">{{ totalLessonLength }}</p>
          </div>
          <h3 class="report-notice">・参加者へのレポート提出依頼</h3>
        </div>
        <div class="users-wrapper">
          <div class="list-info">
            <h2 class="list-title">削除対象</h2>
            <p class="count">
              {{ `${groupLearning.participantCount}件` }}
            </p>
            <span class="description"
              >対象参加者の研修実績が削除されます。</span
            >
          </div>
          <table class="user-list">
            <tr
              v-for="participant in participants"
              :key="participant.id"
              class="user"
            >
              <th>{{ participant.roleText }}</th>
              <td>{{ participant.fullName }}</td>
            </tr>
          </table>
        </div>
      </table>
    </div>
    <div class="button-wrapper">
      <SLButton
        black
        text="キャンセル"
        class="cancel-button"
        :on-click="close"
      />
      <SLButton
        type="submit"
        text="削除する"
        class="remove-button"
        :on-click="removeGroupLearning"
      />
    </div>
  </div>
</template>

<script>
import {
  dateToString,
  formatUnixTimeMillisecondsToHourMinuteString,
} from '@/libs/timeFormatter';
import repository from '@/api/apiRepositoryFactory';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

export default {
  name: 'ReportSettingsModal',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lessonPlan() {
      return this.payload.lessonPlan;
    },
    groupLearning() {
      return this.payload.groupLearning;
    },
    participants() {
      return this.payload.participants;
    },
    modalDescription() {
      const { startsAt, endsAt } = this.groupLearning;
      const startDate = dateToString(startsAt);
      const startTime = formatUnixTimeMillisecondsToHourMinuteString(startsAt);
      const endTime = formatUnixTimeMillisecondsToHourMinuteString(endsAt);

      return `「${startDate} ${startTime}〜${endTime}」開催の集合研修情報および対象参加者の研修実績を削除します。`;
    },
    startDateTime() {
      const { startsAt, endsAt } = this.groupLearning;
      const startDate = dateToString(startsAt);
      const startTime = formatUnixTimeMillisecondsToHourMinuteString(startsAt);
      const endTime = formatUnixTimeMillisecondsToHourMinuteString(endsAt);

      return `${startDate} ${startTime}〜${endTime}`;
    },
    location() {
      return this.payload.groupLearning.location
        ? this.payload.groupLearning.location
        : '未設定';
    },
    mainParticipantName() {
      return this.payload.groupLearning.mainParticipant?.fullName ?? '未設定';
    },
    totalLessonLength() {
      // 24時間以上の表示が必要な為、dayjsのdurationを使って計算する
      dayjs.extend(duration);
      const durationObject = dayjs.duration(
        this.lessonPlan.totalLessonLengthSeconds,
        'seconds',
      );

      const hours = Math.floor(durationObject.asHours());
      const minutes = ('0' + durationObject.minutes()).slice(-2);
      const seconds = ('0' + durationObject.seconds()).slice(-2);

      return `${hours}:${minutes}:${seconds}`;
    },
  },
  methods: {
    close() {
      this.$store.dispatch('modal/close');
    },
    async removeGroupLearning() {
      const groupLearningApi = repository.groupLearnings();

      try {
        await groupLearningApi.trashForOrganizer({
          id: this.groupLearning.id,
        });

        this.$store.dispatch('modal/close');
        this.$router.push({
          name: 'LessonPlanDetailForOrganizer',
          params: {
            lessonPlanId: this.lessonPlan.id,
          },
        });
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#remove-group-learning-modal {
  padding: 20px 25px;
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #333;
  border-radius: 10px;
  background-color: #fff;
  z-index: 10010;

  @include mq(md) {
    padding: 16px;
    width: 95%;
    top: 3%;
  }

  .close-icon-wrapper {
    position: relative;
    width: 100%;
    height: 30px;

    .close-icon {
      width: 26px;
      height: 26px;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url('~@/assets/img/close_icon.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .modal-title {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;

    @include mq(md) {
      margin-top: 16px;
    }
  }

  .group-learning-container {
    margin-top: 40px;
    width: 100%;
    height: 460px;
    overflow-y: auto;

    @include mq(md) {
      margin-top: 20px;
      height: 340px;
    }

    table {
      width: 94%;
      margin: 0 auto;
    }

    .description-wrapper {
      display: flex;
      justify-content: center;

      .modal-description {
        text-align: left;
      }
    }

    .group-learning-info,
    .lesson-plan-info {
      margin-top: 30px;
      padding: 20px 24px;
      border: solid 1px #eee;
      border-radius: 5px;

      @include mq(md) {
        margin-top: 20px;
        border-radius: 10px;
      }

      .sub-title {
        min-width: 70px;
        font-weight: bold;
        font-size: 1rem;

        @include mq(md) {
          font-size: 1.2rem;
        }

        &-long {
          min-width: 140px;
          font-weight: bold;
          font-size: 1rem;

          @include mq(md) {
            font-size: 1.2rem;
          }
        }
      }

      .group-learning-date-time {
        margin-left: 14px;
        font-size: 0.9rem;
        letter-spacing: 1px;

        @include mq(md) {
          margin-left: 18px;
        }
      }

      .data-wrapper {
        margin-top: 10px;
        display: flex;

        &:first-child {
          margin-top: 0px;
        }

        @include mq(md) {
          flex-direction: column;
        }

        .group-learning-data,
        .lesson-plan-data {
          margin-left: 14px;
          width: calc(100% - 84px);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          font-size: 0.9rem;
          letter-spacing: 1px;

          @include mq(md) {
            margin-left: 18px;
          }
        }
      }

      .report-notice {
        margin-top: 10px;
        font-weight: bold;
        font-size: 1rem;

        @include mq(md) {
          font-size: 1.2rem;
        }
      }
    }

    .users-wrapper {
      margin-top: 20px;

      @include mq(md) {
        margin-top: 40px;
      }

      .list-info {
        margin-left: 6px;
        display: flex;

        @include mq(md) {
          margin-left: 0px;
        }

        .list-title {
          font-weight: bold;
          font-size: 1rem;

          @include mq(md) {
            font-size: 0.9rem;
          }
        }

        .count {
          margin-left: 10px;
          font-weight: bold;

          @include mq(md) {
            margin-left: 6px;
          }
        }

        .description {
          margin-left: 14px;
          font-size: 0.9rem;
          font-weight: normal;

          @include mq(md) {
            margin-left: 10px;
          }
        }
      }

      .user-list {
        margin-top: 10px;
        padding: 10px 20px;
        width: 100%;
        border: solid 1px #eee;
        border-radius: 5px;

        @include mq(md) {
          margin-top: 6px;
          border-radius: 10px;
        }

        .user {
          margin-top: 8px;
          display: flex;

          &:first-child {
            margin-top: 10px;
          }

          th {
            width: 70px;
            text-align: left;
            font-weight: normal;
          }

          td {
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }

  .button-wrapper {
    margin-top: 40px;
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    .cancel-button,
    .remove-button {
      width: 100px;

      @include mq(md) {
        width: 140px;
        height: 42px;
        font-size: 1.1rem;
      }

      &:last-child {
        margin-left: 20px;

        @include mq(md) {
          margin-left: 14px;
        }
      }
    }
  }
}
</style>
