import repository from '@/api/apiRepositoryFactory';

export const state = {
  badges: [],
};

export const mutations = {
  setBadges(state, badges) {
    state.badges = badges;
  },
  removeBadges(state, badges) {
    const badgeIds = badges.map((badge) => badge.id);

    state.badges = state.badges.filter(
      (badge) => !badgeIds.find((badgeId) => badgeId === badge.id),
    );
  },
};

const actions = {
  async getAchievedBadges(context) {
    const { commit } = context;

    // なりすましログインの場合は、処理を中断する
    if (context.rootState.userinfo.spoofed_organization_id) {
      return;
    }

    const badgeApi = repository.badges();
    try {
      const badges = await badgeApi.getForUser({
        questAchievementState: 'achieved',
        isConfirmed: 'unconfirmed',
        sort: 'achievedAt',
      });
      commit('setBadges', badges);
    } catch (error) {
      console.warn(error);
    }
  },
  async confirmAchievedBadges({ commit }, badges) {
    const badgeApi = repository.badges();
    const badgeIds = badges.map((badge) => badge.id);

    try {
      await badgeApi.confirmAchievedForUser({
        badgeIds,
      });
      commit('removeBadges', badges);
    } catch (error) {
      console.warn(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
