<template>
  <span>
    <transition name="fade">
      <component :is="currentModalName" :payload="modalProps"></component>
    </transition>
    <Overlay v-if="!!currentModalName" @closeOverlay="close"></Overlay>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import Overlay from '@/components/Overlay.vue';
import LoginModal from '@/components/Modal/LoginModal.vue';
import GetBadgeModal from '@/components/gamification/GetBadgeModal.vue';
import AddParticipantsModal from '@/components/Modal/addParticipantsModal';
import AddParticipantsModalForTrainingEvent from '@/components/Modal/trainingEvent/AddParticipantsModalForTrainingEvent';
import RegisterGroupLearningModal from '@/components/Modal/RegisterGroupLearningModal.vue';
import RemoveGroupLearningModal from '@/components/Modal/RemoveGroupLearningModal.vue';
import ExternalLinkModal from '@/components/Modal/ExternalLinkModal.vue';
import RemoveTrainingModal from '@/components/Modal/RemoveTrainingModal.vue';
import ClearLinksModal from '@/components/Modal/trainingEvent/ClearLinksModal';
import ClearTagsModal from '@/components/Modal/trainingEvent/ClearTagsModal';
import EventListModal from '@/components/Modal/EventListModal.vue';
import CompleteTrainingEventModal from '@/components/Modal/trainingEvent/CompleteTrainingEventModal.vue';
import UndoCompleteTrainingEventModal from '@/components/Modal/trainingEvent/UndoCompleteTrainingEventModal.vue';
import UpdateAllParticipantsStatusForTrainingEventModal from '@/components/Modal/trainingEvent/UpdateAllParticipantsStatusForTrainingEventModal.vue';
import UpdateSpecifiedParticipantStatusForTrainingEventModal from '@/components/Modal/trainingEvent/UpdateSpecifiedParticipantStatusForTrainingEventModal.vue';

export default {
  name: 'BaseModal',
  components: {
    Overlay,
    LoginModal,
    GetBadgeModal,
    AddParticipantsModal,
    AddParticipantsModalForTrainingEvent,
    RegisterGroupLearningModal,
    RemoveGroupLearningModal,
    ExternalLinkModal,
    RemoveTrainingModal,
    ClearLinksModal,
    ClearTagsModal,
    EventListModal,
    CompleteTrainingEventModal,
    UndoCompleteTrainingEventModal,
    UpdateAllParticipantsStatusForTrainingEventModal,
    UpdateSpecifiedParticipantStatusForTrainingEventModal,
  },
  computed: {
    ...mapState('modal', {
      currentModalName: (state) => state.currentModalName,
      modalProps: (state) => state.modalProps,
    }),
  },
  methods: {
    close() {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>
