import axios from 'axios';

export default () => {
  const getRespondableForUser = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/surveyResponses/@respondable`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  return {
    getRespondableForUser,
  };
};
