import axios from 'axios';

export default () => {
  const getAllForStudent = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByIdForStudent = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}`,
    );
    return response.data;
  };

  const getByIdForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/reports/${id}`,
    );
    return response.data;
  };

  const updateForStudent = async ({ id, contents, isDraft }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}`,
      {
        contents,
        isDraft,
      },
    );
    return response.data;
  };

  const updateForOrganizer = async ({ id, feedback, state }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/reports/${id}`,
      {
        feedback,
        state,
      },
    );
    return response.data;
  };

  const registerPictureForStudent = async ({ id, pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}/pictures`,
      formData,
    );
    return response.data;
  };

  const getPicturesByLessonPlanReportForStudent = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}/pictures`,
    );
    return response.data;
  };

  const getPicturesByLessonPlanReportForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/reports/${id}/pictures`,
    );
    return response.data;
  };

  const removePictureForStudent = async ({ id, pictureId }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}/pictures/${pictureId}`,
    );
    return response.data;
  };

  const reorderPicturesForStudent = async ({ id, pictureIds }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}/pictures/order`,
      pictureIds,
    );
    return response.data;
  };

  const setToCompletedForStudent = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/reports/${id}/completed`,
    );
    return response.data;
  };

  const getForStudent = async ({ lessonPlanId, limit, offset, states }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/${lessonPlanId}/reports`,
      {
        params: {
          limit,
          offset,
          states,
        },
      },
    );
    return response.data;
  };

  const createForStudent = async ({ lessonPlanId, contents, isDraft }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonPlans/${lessonPlanId}/reports`,
      {
        contents,
        isDraft,
      },
    );
    return response.data;
  };

  return {
    getAllForStudent,
    getByIdForStudent,
    getByIdForOrganizer,
    updateForStudent,
    updateForOrganizer,
    registerPictureForStudent,
    getPicturesByLessonPlanReportForStudent,
    getPicturesByLessonPlanReportForOrganizer,
    removePictureForStudent,
    reorderPicturesForStudent,
    setToCompletedForStudent,
    getForStudent,
    createForStudent,
  };
};
