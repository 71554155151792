import axios from 'axios';

export default () => {
  const get = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/corporation`,
    );
    return response.data;
  };

  return {
    get,
  };
};
