<template>
  <div id="app">
    <div v-if="!isUserDataLoaded" class="mt-4 mb-4 loading-message">
      読み込み中…
    </div>
    <router-view v-else :key="$route.fullPath" />
    <Footer
      v-if="!$route.path.match(/summit/)"
      ref="footer"
      :class="footerMargin"
    />
    <CookieBanner v-if="isShowCookieBanner" @close="closeCookieBanner" />
    <SATORIContainer />
    <BaseModal />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import BaseModal from '@/components/Modal/BaseModal.vue';
import { mapGetters } from 'vuex';
import repository from '@/api/apiRepositoryFactory';
import CookieBanner from '@/components/common/CookieBanner.vue';
import SATORIContainer from '@/components/SATORIContainer.vue';
import { useCookieConsent } from '@/libs/cookieConsentManager';
import { insertChatplus } from '@/libs/chatplus';

export default {
  name: 'App',
  components: {
    Footer,
    BaseModal,
    CookieBanner,
    SATORIContainer,
  },
  data() {
    return {
      isShowCookieBanner: useCookieConsent().isUpdated,
      isUserDataLoaded: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isLogin', 'isSpoofed']),
    footerMargin() {
      // トップページの最下部に限り、特定の条件に一致した場合marginなしでセミナー情報を表示する
      // ログインしてない場合か、トップページ以外もしくはトップページでセミナー情報がない場合marginをつける
      return !this.isLogin ||
        this.$route.name !== 'Home' ||
        (this.$route.name === 'Home' && this.$refs.footer?.seminarCount === 0)
        ? 'mt-12'
        : '';
    },
  },
  watch: {
    async $route(routeInstance) {
      if (this.isLogin && !this.isSpoofed) {
        // アクセスログ送信
        this.postAccessLog();

        // 取得したバッジがあるかチェック
        this.$store.dispatch('notifications/getAchievedBadges');
      }

      this.createTitleDesc(routeInstance);
    },
  },
  async mounted() {
    // ログイン済みのReactSLからアクセスしたとき、ユーザー情報を取得する
    try {
      await this.$store.dispatch('user/getUserInfo');
    } catch (error) {
      console.warn(error);
    }
    this.isUserDataLoaded = true;

    const routeInstance = this.$route;
    this.createTitleDesc(routeInstance);

    if (this.isLogin) {
      insertChatplus();
    }
    this.$forceUpdate();
  },
  beforeDestroy() {
    this.$forceUpdate();
  },
  methods: {
    createTitleDesc(routeInstance) {
      if (routeInstance.meta.title) {
        const setTitle = routeInstance.meta.title + ' | Special Learning';
        document.title = setTitle;
      } else {
        document.title = 'Special Learning';
      }
    },
    async postAccessLog() {
      const settingsApi = repository.settings();

      // どの画面にアクセスしたかわかるようにパスをコードとして送る
      try {
        await settingsApi.createAccessLogForUser({
          code: this.$router.currentRoute.fullPath,
        });
      } catch (error) {
        console.warn(error);
      }
    },
    closeCookieBanner() {
      this.isShowCookieBanner = false;
    },
  },
};
</script>

<style lang="scss">
@import './assets/css/common.css';
@import '~@/assets/css/toasted.scss';

.loading-message {
  text-align: center;
}
</style>
