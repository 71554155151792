import axios from 'axios';

export default () => {
  // 受講可能なレッスン情報を取得
  const getForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${id}`,
    );
    return response.data;
  };

  // レッスン一覧を取得
  const getAll = async ({ level, q, limit, offset } = {}) => {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/lessons`, {
      params: {
        level,
        q,
        limit,
        offset,
      },
    });
    return response.data;
  };

  // ログイン中のユーザー用のレッスン一覧を取得
  const getAllForUser = async ({ level, q, limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons`,
      {
        params: {
          level,
          q,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 人気・注目のレッスン一覧を取得
  const getPopularAll = async ({ level, limit } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessons/@popular`,
      {
        params: {
          level,
          limit,
        },
      },
    );
    return response.data;
  };

  // ユーザー用人気・注目のレッスン一覧を取得
  const getPopularAllForUser = async ({ level, limit } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/@popular`,
      {
        params: {
          level,
          limit,
        },
      },
    );
    return response.data;
  };

  // 無料ユーザー用レッスン一覧取得
  const getFreeAll = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/@free`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // レッスンプレフィックスから公開レッスン情報を取得
  const getByPrefix = async ({ prefix }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessons/@prefix=${prefix}`,
    );
    return response.data;
  };

  // プレフィックスを指定して受講可能なレッスン情報を取得
  const getByPrefixForUser = async ({ prefix }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/@prefix=${prefix}`,
    );
    return response.data;
  };

  const getByTeacher = async ({ teacherId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/teachers/${teacherId}/lessons`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByTeacherForUser = async ({ teacherId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/teachers/${teacherId}/lessons`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // レッスンカテゴリーに属するレッスン一覧を取得
  const getAllByLessonCategory = async ({
    lessonCategoryId,
    limit,
    offset,
    sort,
    order,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonCategories/${lessonCategoryId}/lessons`,
      {
        params: {
          limit,
          offset,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  // レッスンカテゴリーに属し、ログイン中のユーザーが受講可能なレッスン一覧を取得
  const getAllByLessonCategoryForUser = async ({
    lessonCategoryId,
    limit,
    offset,
    sort,
    order,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/${lessonCategoryId}/lessons`,
      {
        params: {
          limit,
          offset,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  const getCountByLessonPlan = async ({ lessonPlanId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/${lessonPlanId}/lessons/count`,
    );
    return response.data;
  };

  const getByLessonPlanForUser = async ({
    lessonPlanId,
    query,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/${lessonPlanId}/lessons`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByLessonPlanForOrganizer = async ({
    lessonPlanId,
    query,
    limit,
    offset,
  }) => {
    // 研修計画内のレッスン一覧取得
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/lessons`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // レッスンタグに属するレッスンー一覧を取得
  const getByLessonTag = async ({ lessonTagId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonTags/${lessonTagId}/lessons`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // レッスンタグに属するログイン中のユーザーが受講可能なレッスンー一覧を取得
  const getByLessonTagForUser = async ({ lessonTagId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonTags/${lessonTagId}/lessons`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // コース内のレッスン一覧取得
  const getAllByCourse = async ({ courseId, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/courses/${courseId}/lessons`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // コース内のレッスン数を取得
  const getCountByCourse = async ({ courseId, query }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/courses/${courseId}/lessons/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  // ユーザーによるコース内のレッスン一覧取得
  const getAllByCourseForUser = async ({ courseId, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/courses/${courseId}/lessons`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // ユーザーによるコース内のレッスン数取得
  const getCountByCourseForUser = async ({ courseId, query }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/courses/${courseId}/lessons/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  // 目次（中分類）内のレッスン一覧を取得する
  const getAllByMediumSections = async ({
    mediumSectionId,
    limit,
    offset,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/mediumSections/${mediumSectionId}/lessons`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // ユーザーが受講可能な目次（中分類）内のレッスン一覧を取得する
  const getAllByMediumSectionsForUser = async ({
    mediumSectionId,
    query,
    limit,
    offset,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/mediumSections/${mediumSectionId}/lessons`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 管理者用: 研修計画内のレッスン数取得
  const getCountByLessonPlanForOrganizer = async ({ lessonPlanId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/lessons/count`,
    );
    return response.data;
  };

  // 研修計画内のレッスン数取得
  const getCountByLessonPlanForUser = async ({ lessonPlanId, query }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonPlans/${lessonPlanId}/lessons/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  const getSummariesByLessonCategoryCodeForUser = async ({
    lessonCategoryCode,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/@code=${lessonCategoryCode}/lessons/summary`,
    );
    return response.data;
  };

  const getStudiedAllByLessonCategoryForUser = async ({
    lessonCategoryId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/${lessonCategoryId}/lessons/@studied`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 事業所内の指定ユーザーが受講済みのレッスン一覧取得
  const getStudiedAllByUsersForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/lessons/@studied`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 事業所内の指定ユーザーが指定のレッスンカテゴリー内で受講済みのレッスン一覧取得
  const getStudiedAllByLessonCategoryAndUserAndOrganizationForOrganizer =
    async ({ organizationId, lessonCategoryId, userId, limit, offset }) => {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/lessonCategories/${lessonCategoryId}/lessons/@studied`,
        {
          params: {
            limit,
            offset,
          },
        },
      );
      return response.data;
    };

  // 事業所内の指定ユーザーが指定のレッスンカテゴリー内で受講済みのレッスン一覧取得
  const getStudiedAllByCourseAndUserAndOrganizationForOrganizer = async ({
    organizationId,
    courseId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/courses/${courseId}/lessons/@studied`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 「あとで見る」に入っているレッスン一覧取得
  const getBookmarkedLessonsForUser = async ({ limit, offset } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/@bookmarked`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const recordLessonStudyForUser = async ({
    id,
    studyTimeSeconds,
    lessonPlanId,
    courseId,
  }) => {
    await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${id}/study`,
      {
        studyTimeSeconds,
        lessonPlanId,
        courseId,
      },
    );
  };

  const addBookmarkForUser = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${id}/bookmarked`,
    );
    return response.data;
  };

  const removeBookmarkForUser = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${id}/bookmarked`,
    );
    return response.data;
  };

  // 指定のレッスンにいいねを登録
  const addLikeForUser = async ({ id }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${id}/like`,
    );
    return response.data;
  };

  // 指定のいいね済みレッスンからいいねを削除
  const removeLikeForUser = async ({ id }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/lessons/${id}/like`,
    );
    return response.data;
  };

  return {
    getForUser,
    getAll,
    getAllForUser,
    getPopularAll,
    getPopularAllForUser,
    getFreeAll,
    getByPrefix,
    getByPrefixForUser,
    getByTeacher,
    getByTeacherForUser,
    getAllByLessonCategory,
    getAllByLessonCategoryForUser,
    getByLessonPlanForUser,
    getByLessonPlanForOrganizer,
    getByLessonTag,
    getByLessonTagForUser,
    getAllByCourse,
    getAllByCourseForUser,
    getAllByMediumSections,
    getAllByMediumSectionsForUser,
    getSummariesByLessonCategoryCodeForUser,
    getStudiedAllByLessonCategoryForUser,
    getStudiedAllByUsersForOrganizer,
    getStudiedAllByLessonCategoryAndUserAndOrganizationForOrganizer,
    getStudiedAllByCourseAndUserAndOrganizationForOrganizer,
    getBookmarkedLessonsForUser,
    recordLessonStudyForUser,
    getCountByLessonPlan,
    getCountByLessonPlanForOrganizer,
    getCountByLessonPlanForUser,
    getCountByCourse,
    getCountByCourseForUser,
    addBookmarkForUser,
    removeBookmarkForUser,
    addLikeForUser,
    removeLikeForUser,
  };
};
