/**
 * テキストが最大長を超える場合、短縮されたテキストを返却する
 * @param {string} text 短縮するテキスト
 * @param {number} maxLength 最大長
 * @returns 短縮されたテキスト
 */
const shortenText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}…` : text;
};

export { shortenText };
