import axios from 'axios';

export default () => {
  const getForUser = async ({
    query,
    limit,
    offset,
    questAchievementState,
    isConfirmed,
    type,
    sort,
    order,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/badges`,
      {
        params: {
          q: query,
          limit,
          offset,
          questAchievementState,
          isConfirmed,
          type,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  const getAllByUserAndOrganizationForOrganizer = async ({
    organizationId,
    userId,
    query,
    limit,
    offset,
    questAchievementState,
    isConfirmed,
    type,
    sort,
    order,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/badges`,
      {
        params: {
          q: query,
          limit,
          offset,
          questAchievementState,
          isConfirmed,
          type,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  // ユーザーの学習時間バッジ一覧取得
  const getStudyTimeBadgesForUser = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/badges/@studyTime`,
    );
    return response.data;
  };

  // ユーザーの獲得できるバッジ数取得
  const getCount = async ({ questAchievementState } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/badges/count`,
      {
        params: {
          questAchievementState,
        },
      },
    );
    return response.data;
  };

  const getCountForOrganizer = async ({ organizationId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/badges/count`,
    );
    return response.data;
  };

  const confirmAchievedForUser = async ({ badgeIds }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/badges/confirmed`,
      {
        BadgeIds: badgeIds,
      },
    );
    return response.data;
  };

  return {
    getForUser,
    getAllByUserAndOrganizationForOrganizer,
    getStudyTimeBadgesForUser,
    getCount,
    getCountForOrganizer,
    confirmAchievedForUser,
  };
};
