import { localize, extend } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);
localize('ja', ja);

// 必須項目
extend('required', {
  validate: (value) => {
    return ['', null, undefined].indexOf(value) === -1;
  },
  computesRequired: true,
  message: '{_field_}を入力してください',
});

// 必須項目(選択)
extend('select_required', {
  validate: (value) => {
    return ['', null, undefined].indexOf(value) === -1;
  },
  computesRequired: true,
  message: '{_field_}を選択してください',
});

// 必須項目(２つのフィールド)
extend('two_field_required', {
  validate: (value, { subValue }) => {
    const isValueError = ['', null, undefined].indexOf(value) !== -1;
    const isSubValueError = ['', null, undefined].indexOf(subValue) !== -1;

    if (isValueError && isSubValueError) {
      return `{_field_}/{subField}を入力してください`;
    }

    if (isValueError) {
      return `{_field_}を入力してください`;
    }

    if (isSubValueError) {
      return `{subField}を入力してください`;
    }

    return true;
  },
  params: ['subField', 'subValue'],
  computesRequired: true,
});

// 複数選択
extend('at_least', {
  validate: (value, { length }) => {
    return value.length >= length;
  },
  params: ['length', 'unit'],
  computesRequired: true,
  message: '{length}{unit}以上の{_field_}を選択してください',
});

// ラジオボタン
extend('radio', {
  validate: (value) => {
    return ['', null, undefined].indexOf(value) === -1;
  },
  computesRequired: true,
  message: '{_field_}を選択してください',
});

// 最大文字数
extend('max', {
  validate: (value, { maxLength }) => {
    return value.length <= maxLength;
  },
  params: ['maxLength'],
  computesRequired: true,
  message: '最大{maxLength}文字です',
});

// 最大文字数(フィールド名付き)
extend('max_field', {
  validate: (value, { maxLength }) => {
    return value.length <= maxLength;
  },
  params: ['field', 'maxLength'],
  computesRequired: true,
  message: '{field}は{maxLength}文字以内で入力してください',
});

// 指定フィールドの最大文字数
extend('max_sub_field', {
  validate: (_value, { subValue, maxLength }) => {
    return subValue.length <= maxLength;
  },
  params: ['field', 'subValue', 'maxLength'],
  computesRequired: true,
  message: '{field}は、最大{maxLength}文字です',
});

// 開始時間 < 終了時間
extend('start_time_before_end_time', {
  validate: (value, { subValue }) => {
    const [startHours, startMinutes] = value.split(':').map(Number);

    const [endHours, endMinutes] = subValue.split(':').map(Number);

    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;

    return totalStartMinutes < totalEndMinutes;
  },
  params: ['subField', 'subValue'],
  computesRequired: true,
  message: '{_field_}は{subField}よりも前の時間を選択してください',
});

// 開始日 <= 終了日
extend('start_date_same_or_before_end_date', {
  validate: (value, { subValue }) => {
    if (!subValue) {
      return true;
    }

    return dayjs(value).isSameOrBefore(dayjs(subValue));
  },
  params: ['subField', 'subValue'],
  computesRequired: true,
  message: '{_field_}は{subField}以前の日付を選択してください',
});

/**
 * 参加者数 > 0
 * このバリデーションルールは、研修参加ユーザー数の判定に使用する
 */
extend('is_participants_exists', {
  validate: (value, { participants }) => {
    return participants.length > 0;
  },
  params: ['participants'],
  computesRequired: true,
  message: '1人以上の参加者を追加してください',
});

// 有効なURLか
extend('url', {
  validate: (value) => {
    // URL形式でないと許容しない
    // ?,&,%は許容
    // ?以降はクエリストリングスのため空白文字以外は許容
    // eslint-disable-next-line no-useless-escape
    const pattern = /(https?:\/\/)[^\s\/$.?#].[^\s]*(\?[^\s]+)?$/;

    return pattern.test(value);
  },
  message: '有効なURLを入力してください',
});

// URLの重複チェック
extend('unique_url', {
  validate: (value, { links }) => {
    if (links.length === 0) {
      return true;
    }

    return !links.some((link) => link.url === value);
  },
  params: ['links'],
  message: '未登録のURLを入力してください',
});
