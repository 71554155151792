<template>
  <span class="sl-input-file">
    <p v-if="fileName" class="file-name">{{ fileName }}</p>
    <label class="input-button">
      <input
        id="file"
        type="file"
        name="file"
        accept="image/*"
        :value="value"
        @change="updateValue"
      />
      画像ファイルを選択
    </label>
  </span>
</template>

<script>
export default {
  name: 'SLInputFile',
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      value: '',
      fileName: null,
    };
  },
  methods: {
    async updateValue(event) {
      this.$emit('input', event.target.value);
      await this.onChange(event);

      this.fileName = document.getElementById('file').files[0].name;
    },
  },
};
</script>

<style lang="scss" scoped>
.sl-input-file {
  display: flex;
  align-items: center;

  input[type='file'] {
    display: none;
  }

  .file-name {
    margin-right: 16px;
    color: #ff7171;
    font-weight: bold;
  }

  .input-button {
    padding: 6px 14px;
    color: #4d4d4d;
    background-color: white;
    border: solid 2px #4d4d4d;
    border-radius: 24px;
    font-weight: bold;
    font-family: YuGothic, 'Yu Gothic', 'Noto Sans JP', sans-serif;
    cursor: pointer;

    &:hover {
      background-color: #4d4d4d;
      color: white;
      opacity: unset;
    }
  }
}
</style>
