import axios from 'axios';

export default () => {
  const getCurrentUser = async () => {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}/users/~`);
    return response.data;
  };

  // ユーザーに画像を登録
  const registerPictureForUser = async ({ pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/picture`,
      formData,
    );
    return response.data;
  };

  // 事業所内の指定コース内の指定レッスンの受講者一覧取得
  const getAllByOrganizationAndLessonForOrganizer = async ({
    organizationId,
    lessonId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/lessons/${lessonId}/users`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  // 事業所内の指定コース内の指定レッスンの受講者一覧取得
  const getAllByLessonInCourseAndOrganizationForOrganizer = async ({
    organizationId,
    courseId,
    lessonId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/courses/${courseId}/lessons/${lessonId}/users`,
      {
        params: {
          limit,
          offset,
        },
      },
    );

    return response.data;
  };

  const getAllLessonPlanForOrganizer = async ({
    lessonPlanId,
    query,
    limit,
    offset,
  }) => {
    // 研修計画の対象ユーザー一覧取得
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/users`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 研修計画の対象ユーザー数取得
  const countAllByLessonPlanForOrganizer = async ({ lessonPlanId, query }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/users/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  // 事業所管理者が所属する全ての事業所内の全てのユーザーを取得する
  const getAllByOrganizer = async ({
    query,
    limit,
    offset,
    organizationIds,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/users`,
      {
        params: {
          q: query,
          limit,
          offset,
          organizations: organizationIds,
        },
      },
    );
    return response.data;
  };

  // 事業所内のユーザー一覧取得
  const getAllByOrganizationsForOrganizer = async ({
    organizationId,
    role,
    query,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users`,
      {
        params: {
          role,
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 事業所内のユーザー取得
  const getByOrganizationsForOrganizer = async ({ organizationId, userId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}`,
    );
    return response.data;
  };

  // 事業所管理者による,指定の事業所に所属するユーザー数を取得
  const getCountByOrganizationForOrganizer = async ({
    organizationId,
    role,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/count`,
      {
        params: {
          role,
        },
      },
    );
    return response.data;
  };

  // ログイン中のユーザー情報を変更
  const updateForUser = async ({
    firstName,
    lastName,
    targetStudyTimeSeconds,
    isEnabledEmailNotification = null,
  }) => {
    const response = await axios.put(`${process.env.VUE_APP_API_URL}/users/~`, {
      firstName,
      lastName,
      targetStudyTimeSeconds,
      isEnabledEmailNotification,
    });
    return response.data;
  };
  // ユーザー認証（認証トークン発行）
  const authorizeForUser = async ({ code, password, setsCookie }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/authorize/user`,
      {
        code,
        password,
        setsCookie,
      },
    );
    return response.data;
  };
  // ユーザー認証Cookieを削除
  const clearAuthorizationCookieForUser = async () => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/authorize/user`,
    );
    return response.data;
  };

  const issueLoginKeyForStudent = async ({ organizationId, id }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${id}/loginKey`,
    );
    return response.data;
  };

  const authorizeByLoginKeyForStudent = async ({ loginKey, setsCookie }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/authorize/user/loginKey`,
      {
        loginKey,
        setsCookie,
      },
    );
    return response.data;
  };

  const issueEmailAuthenticationCodeForUser = async ({ email }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/email/issueAuthenticationCode`,
      {
        email,
      },
    );
    return response.data;
  };

  const verifyEmailAuthenticationCodeForUser = async ({
    authenticationCode,
  }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/email/verifyAuthenticationCode`,
      {
        authenticationCode,
      },
    );
    return response.data;
  };

  // 事業所内のユーザー数取得
  const getCountByOrganizationsForOrganizer = async ({ organizationId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/count`,
    );
    return response.data;
  };

  // カスタム研修の参加者取得
  const getAllByTrainingEventForOrganizer = async ({
    trainingEventId,
    query,
    limit,
    offset,
    participationStates,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${trainingEventId}/users`,
      {
        params: {
          q: query,
          limit,
          offset,
          participationStates,
        },
      },
    );
    return response.data;
  };

  // カスタム研修に参加するユーザー数取得
  const getCountByTrainingEventForOrganizer = async ({
    trainingEventId,
    query,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/trainingEvents/${trainingEventId}/users/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  return {
    getCurrentUser,
    registerPictureForUser,
    getAllByOrganizationAndLessonForOrganizer,
    getAllByLessonInCourseAndOrganizationForOrganizer,
    getAllLessonPlanForOrganizer,
    countAllByLessonPlanForOrganizer,
    getAllByOrganizer,
    getAllByOrganizationsForOrganizer,
    getByOrganizationsForOrganizer,
    getCountByOrganizationForOrganizer,
    updateForUser,
    authorizeForUser,
    clearAuthorizationCookieForUser,
    issueLoginKeyForStudent,
    authorizeByLoginKeyForStudent,
    issueEmailAuthenticationCodeForUser,
    verifyEmailAuthenticationCodeForUser,
    getCountByOrganizationsForOrganizer,
    getAllByTrainingEventForOrganizer,
    getCountByTrainingEventForOrganizer,
  };
};
