<template>
  <tr :class="selectionStyle" @click="toggleSelection">
    <td class="user-checkbox" :class="selectionStyle"></td>
    <td class="user-code">
      <p class="table-body-text">
        {{ user.code }}
      </p>
    </td>
    <td>
      {{ user.roleText }}
    </td>
    <td>
      <p class="table-body-text">
        {{ user.fullName }}
      </p>
    </td>
    <td>
      <p class="table-body-text">
        {{ user.organization.name }}
      </p>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'AddParticipantsModalTableRow',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    selectionStyle() {
      return this.isSelected ? 'selected' : '';
    },
    checkboxId() {
      return `checkbox-${this.user.id}`;
    },
  },
  methods: {
    toggleSelection() {
      this.$emit('onToggleSelection', this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  width: 100%;
  border-bottom: solid 1px #ccc;

  &.selected {
    background-color: #feebea;
  }

  td {
    padding: 14px 6px;
    border-right: solid 1px #ccc;

    &.user-checkbox {
      @include custom-base-checkbox;

      &.selected {
        @include checkmark-checkbox;
      }
    }

    &.user-code {
      @include mq(lg) {
        display: none;
      }
    }

    p {
      &.table-body-text {
        @include two-line-ellipsis;
      }
    }
  }
}
</style>
