import axios from 'axios';

export default () => {
  const getMain = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/organization`,
    );
    return response.data;
  };

  const getForStudent = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/organization`,
    );
    return response.data;
  };

  const getAllForOrganizer = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByRole = async ({ role, query, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@${role}/organizations`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getByLessonPlanForOrganizer = async ({
    lessonPlanId,
    query,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/organizations`,
      {
        params: {
          q: query,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const countByLessonPlanForOrganizer = async ({ lessonPlanId, query }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/${lessonPlanId}/organizations/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  return {
    getMain,
    getForStudent,
    getAllForOrganizer,
    getByRole,
    getByLessonPlanForOrganizer,
    countByLessonPlanForOrganizer,
  };
};
