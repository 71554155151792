<template>
  <div class="carousel">
    <h3 class="carousel-title">{{ badgeName }}</h3>
    <p class="carousel-text">バッジを獲得しました！</p>
    <agile ref="carousel" :options="carouselConfig">
      <div v-for="badge in badges" :key="badge.id" class="carousel-img-wrapper">
        <img class="carousel-img" :src="pictureUrl" :alt="badge.name" />
      </div>
    </agile>
    <p class="carousel-info" :class="showStyle">
      {{ currentPageText }}
    </p>
    <button v-if="showPrev" class="prev-arrow" @click="goToPrev"></button>
    <button v-if="showNext" class="next-arrow" @click="goToNext"></button>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile';

export default {
  name: 'BadgeCarousel',
  components: {
    agile: VueAgile,
  },
  props: {
    badges: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      carousels: [],
      carouselConfig: {
        dots: false,
        infinite: false,
        navButtons: false,
        fade: true,
      },
      currentPage: 1,
    };
  },
  computed: {
    badgeName() {
      return this.badges[this.currentPage - 1].name;
    },
    pictureUrl() {
      return this.badges[this.currentPage - 1].picture
        ? this.badges[this.currentPage - 1].picture.url
        : '';
    },
    showStyle() {
      return this.badges.length > 1 ? 'show' : '';
    },
    currentPageText() {
      return `獲得バッジ：${this.currentPage}/${this.badges.length}`;
    },
    showPrev() {
      return this.currentPage > 1;
    },
    showNext() {
      if (this.currentPage <= 0) {
        return false;
      }
      return this.currentPage < this.badges.length;
    },
  },
  methods: {
    goToPrev() {
      this.currentPage--;
      this.$refs.carousel.goToPrev();
    },
    goToNext() {
      this.currentPage++;
      this.$refs.carousel.goToNext();
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &-title {
    width: 100%;
    text-align: center;
    color: $theme-color;
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-text {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }

  &-img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 120px;

    &-wrapper {
      align-items: center;
      box-sizing: border-box;
      color: #fff;
      display: flex;
      height: 140px;
      justify-content: center;
      padding: 10px;
      position: relative;

      &::after {
        content: '';
        background-image: url('~@/assets/img/gamification/glitter_w600.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 120px;
        height: 120px;
        position: absolute;
        top: 10px;
        display: block;

        @media screen and (max-width: 600px) {
          background-image: url('~@/assets/img/gamification/glitter_w300.png');
        }
      }
    }
  }

  &-info {
    margin-bottom: 10px;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }

  .prev-arrow {
    position: absolute;
    left: -45px;
    top: 50%;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    background-image: url('~@/assets/img/arrow.png');
    transform: rotateZ(180deg) scale(0.4);
    cursor: pointer;
  }

  .next-arrow {
    position: absolute;
    right: -45px;
    top: 50%;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    background-image: url('~@/assets/img/arrow.png');
    transform: scale(0.4);
    cursor: pointer;
  }
}

.agile {
  width: 100%;
  margin-bottom: 5px;
}
</style>
