const state = {
  currentModalName: '',
  modalProps: null,
  onClose: null,
};

const mutations = {
  setCurrentModalName(state, modalName) {
    state.currentModalName = modalName;
  },
  setModalProps(state, modalProps) {
    state.modalProps = modalProps;
  },
  setOnClose(state, onClose) {
    state.onClose = onClose;
  },
};

const actions = {
  show({ commit }, { modalName, modalProps = null, onClose = null }) {
    commit('setCurrentModalName', modalName);
    commit('setModalProps', modalProps);
    commit('setOnClose', onClose);
  },
  close({ commit, state }) {
    if (state.onClose) {
      state.onClose();
    }

    commit('setCurrentModalName', '');
    commit('setModalProps', null);
    commit('setOnClose', null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
