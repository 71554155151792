export default {
  path: '/seminars',
  component: () => import('@/views/seminars/index.vue'),
  meta: {
    isPublic: false,
    breadcrumb: {
      parent: 'Home',
    },
  },
  children: [
    {
      path: '',
      name: 'SeminarList',
      component: () => import('@/views/seminars/SeminarList.vue'),
      meta: {
        isPublic: false,
        title: '外部セミナー',
        desc: '外部セミナー',
        breadcrumb: {
          label: '外部セミナー',
        },
      },
    },
    {
      path: ':seminarId',
      name: 'SeminarDetail',
      component: () => import('@/views/seminars/SeminarDetail.vue'),
      props: true,
      meta: {
        isPublic: false,
        breadcrumb() {
          // セミナー名を取得
          const seminarName = localStorage.getItem('breadcrumbName');
          return seminarName ?? 'セミナーの詳細';
        },
        title: 'セミナー詳細',
        desc: 'セミナー詳細',
      },
    },
  ],
};
