<template>
  <div class="pager-area">
    <div v-if="showPrev" class="previous-button" @click="prev()">前へ</div>
    <div v-if="showNext" class="next-button" @click="next()">次へ</div>
  </div>
</template>

<script>
export default {
  name: 'SLPager',
  props: {
    dataCount: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    updatePager: {
      type: Function,
      required: true,
    },
    resetPager: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    showPrev() {
      return this.currentPage !== 1;
    },
    showNext() {
      return this.limit * this.currentPage < this.dataCount;
    },
  },
  watch: {
    currentPage: function (currentPage) {
      this.updatePager(currentPage);
    },
    resetPager: function (resetPager) {
      if (resetPager) {
        this.currentPage = 1;
      }
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page;
    }
  },
  methods: {
    next() {
      this.currentPage++;
    },
    prev() {
      this.currentPage--;
    },
  },
};
</script>

<style lang="scss" scoped>
.pager-area {
  font-weight: bold;

  .previous-button,
  .next-button {
    display: inline-block;
    width: 50px;
    height: 35px;
    margin: 0 5px;
    padding: 0 8px;
    line-height: 35px;
    color: #fff;
    background-image: url('../../assets/img/pager_pink.png');
    background-repeat: no-repeat;
    background-size: 100px 35px;
    cursor: pointer;
  }

  .next-button {
    text-align: left;
    background-position: -50px 0;
  }

  .previous-button {
    text-align: right;
    background-position: 0 0;
  }

  .next-button:hover,
  .previous-button:hover {
    opacity: 0.6;
  }
}
</style>
