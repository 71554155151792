import { render, staticRenderFns } from "./SelectOrganizationsMenu.vue?vue&type=template&id=1498904b&scoped=true&"
import script from "./SelectOrganizationsMenu.vue?vue&type=script&lang=js&"
export * from "./SelectOrganizationsMenu.vue?vue&type=script&lang=js&"
import style0 from "./SelectOrganizationsMenu.vue?vue&type=style&index=0&id=1498904b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1498904b",
  null
  
)

export default component.exports