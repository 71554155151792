import repository from '../api/apiRepositoryFactory';

export default async () => {
  const userApi = repository.users();
  try {
    await userApi.getCurrentUser();
    return true;
  } catch (error) {
    return false;
  }
};
