import {
  PRIVACY_POLICY_VERSION,
  COOKIE_POLICY_VERSION,
  POLICY_LAST_UPDATED,
} from '@/libs/constants';

export const useCookieConsent = () => {
  const token =
    localStorage.getItem('cookieConsent') ||
    sessionStorage.getItem('cookieConsent');

  const policyLastUpdated = new Date(POLICY_LAST_UPDATED);
  const policyLastUpdatedUnixTime = Math.floor(
    policyLastUpdated.getTime() / 1000,
  );
  const newToken = `${policyLastUpdatedUnixTime}_${PRIVACY_POLICY_VERSION}_${COOKIE_POLICY_VERSION}`;

  if (!token) {
    return { isUpdated: true, token: newToken };
  }

  if (token !== newToken) {
    localStorage.removeItem('cookieConsent');
    sessionStorage.removeItem('cookieConsent');
    return { isUpdated: true, token: newToken };
  }

  return { isUpdated: false, token };
};
