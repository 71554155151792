<template>
  <div class="pa-1 form-error-messages">
    <p v-for="message in errorMessages" :key="message" class="error-message">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FormErrorMesssages',
  props: {
    errorMessages: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-error-messages {
  position: relative;
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.6;
  color: #bb2624;
  border-radius: 5px;
  background-color: #ffcccc;
}

.error-message {
  white-space: pre-wrap;

  &::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
    background-image: url('~@/assets/img/icon_alert.png');
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: text-bottom;
  }
}
</style>
