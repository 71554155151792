import axios from 'axios';

export default () => {
  // 事業所のプレイヤーに関する設定の取得
  const getOrganizationPlayerSettingByOrganizationForOrganizer = async ({
    organizationId,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/settings/player`,
    );
    return response.data;
  };

  // 事業所のプレイヤーに関する設定の更新
  const updateOrganizationPlayerSettingByOrganizationForOrganizer = async ({
    organizationId,
    isAllowedContinuousPlay,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/settings/player`,
      {
        isAllowedContinuousPlay,
      },
    );
    return response.data;
  };

  // ユーザーのプレイヤーに関する設定の取得
  const getUserPlayerSettingForUser = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/settings/player`,
    );
    return response.data;
  };

  // ユーザーのプレイヤーに関する設定の更新
  const updateUserPlayerSettingForUser = async ({
    playbackSpeedPercent,
    isEnabledContinuousPlay,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/settings/player`,
      {
        playbackSpeedPercent,
        isEnabledContinuousPlay,
      },
    );
    return response.data;
  };

  // 事業所のサンプル研修計画の表示に関する設定の取得
  const getOrganizationLessonPlanSettingByOrganizationForOrganizer = async ({
    organizationId,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/settings/lessonPlan`,
    );
    return response.data;
  };

  // 事業所のサンプル研修計画の表示に関する設定の更新
  const updateOrganizationLessonPlanSettingByOrganizationForOrganizer = async ({
    organizationId,
    isAvailableLessonPlanExample,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/settings/lessonPlan`,
      {
        isAvailableLessonPlanExample,
      },
    );
    return response.data;
  };

  // アクセス記録
  const createAccessLogForUser = async ({ code }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/access`,
      {
        code,
      },
    );
    return response.data;
  };

  return {
    getOrganizationPlayerSettingByOrganizationForOrganizer,
    updateOrganizationPlayerSettingByOrganizationForOrganizer,
    getUserPlayerSettingForUser,
    updateUserPlayerSettingForUser,
    getOrganizationLessonPlanSettingByOrganizationForOrganizer,
    updateOrganizationLessonPlanSettingByOrganizationForOrganizer,
    createAccessLogForUser,
  };
};
