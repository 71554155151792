import { render, staticRenderFns } from "./AddParticipantsModalForTrainingEvent.vue?vue&type=template&id=0ef16075&scoped=true&"
import script from "./AddParticipantsModalForTrainingEvent.vue?vue&type=script&lang=js&"
export * from "./AddParticipantsModalForTrainingEvent.vue?vue&type=script&lang=js&"
import style0 from "./AddParticipantsModalForTrainingEvent.vue?vue&type=style&index=0&id=0ef16075&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef16075",
  null
  
)

export default component.exports