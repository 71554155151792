export const state = {
  lessonPlansForm: {
    name: '',
    description: '',
    organizationIds: [],
    startsAt: null,
    endsAt: null,
    contents: [],
    state: 'open',
    pictureId: null,
  },
  lessons: {
    continuousPlayCount: 0,
    isContinuousPlay: false,
  },
  groupLearning: {
    // FIXME: Next.js以降時にparticipantsに変更する
    selectedUsers: [],
    isSelectedUsersEdited: false,
  },
  trainingEvent: {
    name: '',
    startsAt: null,
    endsAt: null,
    location: '',
    description: '',
    state: 'open',
    links: [],
    trainingTags: [],
    participants: [],
  },
  totalLessonLengthSeconds: 0,
};

export const mutations = {
  setFormDataForLessonPlan(state, lessonPlan) {
    state.lessonPlansForm = lessonPlan;
  },
  removeContentsForLessonPlan(state, contents) {
    state.lessonPlansForm.contents = state.lessonPlansForm.contents.filter(
      (selectedContents) => selectedContents.contents.id !== contents.id,
    );
  },
  setTotalLessonLengthSeconds(state, seconds) {
    state.totalLessonLengthSeconds = seconds;
  },
  clearFormDataForLessonPlan(state) {
    state.lessonPlansForm = {
      name: '',
      description: '',
      organizationIds: [],
      startsAt: null,
      endsAt: null,
      contents: [],
      state: 'open',
      pictureId: null,
    };
  },
  incrementContinuousPlayCount(state) {
    state.lessons.continuousPlayCount = state.lessons.continuousPlayCount + 1;
  },
  resetContinuousPlayCount(state) {
    state.lessons.continuousPlayCount = 0;
  },
  setContinousPlay(state) {
    state.lessons.isContinuousPlay = true;
  },
  updateSelectedUsersForGroupLearning(state, selectedUsers) {
    state.groupLearning.selectedUsers = selectedUsers;
    state.groupLearning.isSelectedUsersEdited = true;
  },
  removeUserForGroupLearning(state, newUsers) {
    state.groupLearning.selectedUsers = newUsers;
  },
  clearSelectedUsersForGroupLearning(state) {
    state.groupLearning.selectedUsers = [];
  },
  clearIsSelectedUsersEditedForGroupLearning(state) {
    state.groupLearning.isSelectedUsersEdited = false;
  },
  setFormDataForTrainingEvent(state, trainingEvent) {
    state.trainingEvent = trainingEvent;
  },
  updateParticipantsForTrainingEvent(state, selectedUsers) {
    state.trainingEvent.participants = selectedUsers;
  },
  removeParticipantsForTrainingEvent(state, participants) {
    participants.forEach((participant) => {
      state.trainingEvent.participants =
        state.trainingEvent.participants.filter(
          (storedParticipant) => storedParticipant.id !== participant.id,
        );
    });
  },
  clearFormOfTrainingEvent(state) {
    state.trainingEvent = {
      name: '',
      startsAt: null,
      endsAt: null,
      location: '',
      description: '',
      state: 'open',
      links: [],
      trainingTags: [],
      participants: [],
    };
  },
};

export const getters = {
  continuousPlayCount(state) {
    return state.lessons.continuousPlayCount;
  },
  isContinuousPlay(state) {
    return state.lessons.isContinuousPlay;
  },
  selectedLessons(state) {
    return state.lessonPlansForm.contents.filter(
      (content) => content.type === 'lesson',
    );
  },
  selectedCourses(state) {
    return state.lessonPlansForm.contents.filter(
      (content) => content.type === 'course',
    );
  },
};

export const actions = {
  async removeUser({ state, commit }, userIds) {
    const newUsers = await state.groupLearning.selectedUsers.filter(
      (user) => !userIds.includes(user.id),
    );

    commit('removeUserForGroupLearning', newUsers);
  },
  // 研修計画コピー作成用にフォームデータをコピー
  copyLessonPlanToCreate(
    { commit, state },
    { lessonPlan = undefined, contents = undefined, organizations = undefined },
  ) {
    let copiedLessonPlan = null;

    if (lessonPlan) {
      copiedLessonPlan = lessonPlan;
    } else {
      copiedLessonPlan = structuredClone(state.lessonPlansForm);
    }

    if (contents) {
      copiedLessonPlan.contents = contents;
    }

    if (organizations) {
      copiedLessonPlan.organizationIds = organizations.map(
        (organization) => organization.id,
      );
    }

    commit('setFormDataForLessonPlan', copiedLessonPlan);
  },
  copyTrainingEventToCreate(
    { commit, state },
    { trainingEvent = undefined, participants = undefined },
  ) {
    let copiedTrainingEvent = null;

    if (trainingEvent) {
      copiedTrainingEvent = trainingEvent;
    } else {
      copiedTrainingEvent = structuredClone(state.trainingEvent);
    }

    commit('setFormDataForTrainingEvent', copiedTrainingEvent);

    if (participants) {
      commit('updateParticipantsForTrainingEvent', participants);
    } else {
      commit('updateParticipantsForTrainingEvent', []);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
