/**
 * チャットプラス用タグ埋め込み
 * ログイン中のみチャットを表示する
 */
export const insertChatplus = () => {
  const code = process.env.VUE_APP_CHATPLUS_CODE;

  if (!code) {
    return;
  }

  document['__cp_d'] = 'https://app.chatplus.jp';
  document['__cp_c'] = code;

  const scriptTag = document.createElement('script');
  scriptTag.async = true;
  scriptTag.src = 'https://app.chatplus.jp/cp.js';

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
};
