<template>
  <div class="add-participant-modal">
    <div class="close-icon-wrapper">
      <p class="close-icon" @click="close" />
    </div>
    <h2 class="modal-title">参加者を追加する</h2>
    <p class="modal-description">研修に参加するスタッフを選択してください。</p>
    <div class="participant-container">
      <div class="search-and-select-wrapper">
        <p class="user-count">{{ `${selectedUsers.length}人選択中` }}</p>
        <div class="right-colum">
          <div
            v-click-outside="hideSelectOrganizationsMenu"
            class="organization-search"
          >
            <input
              class="organization-search-input"
              type="text"
              placeholder="事業所で絞り込み"
              :value="formattedOrganizationsName"
              inputmode="search"
              readonly
              @click="showSelectOrganizationsMenu"
            />
            <span class="arrow-icon"></span>
            <SelectOrganizationsMenu
              v-if="isSelectOrganizationsMenuShown"
              class="select-organizations-menu"
              :selected-organizations="selectedOrganizations"
              @onSelectedOrganizationsUpdated="updateSelectedOrganizations"
            />
          </div>
          <input
            v-model="searchWords"
            class="search-input"
            type="search"
            placeholder="氏名、ユーザーID"
            inputmode="search"
            @keydown.enter="refreshUsers"
            @compositionstart="composing = true"
            @compositionend="composing = false"
          />
          <SLButton
            text="検索"
            class="ml-2 search-button"
            black
            :on-click="refreshUsers"
          />
        </div>
      </div>
      <p v-if="isFetching" class="mt-2 loading-message">読み込み中…</p>
      <p v-else-if="isError" class="mt-2 error-message">
        データの取得に失敗しました
      </p>
      <div v-else class="table-container">
        <table class="participant-table">
          <thead>
            <tr>
              <th
                class="header-checkbox"
                :class="selectState"
                :style="hideStyle"
                @click="updateAllUsersSelection"
              ></th>
              <th class="header-code">ユーザーID</th>
              <th class="header-role">権限</th>
              <th class="header-name">氏名</th>
              <th class="header-organization">所属事業所</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="users.length === 0">
              <td class="non-display">－</td>
              <td>－</td>
              <td>－</td>
              <td>－</td>
            </tr>
            <AddParticipantsModalRowForTrainingEvent
              v-for="user in users"
              v-else
              :key="user.id"
              :user="user"
              :is-selected="isSelectedUser(user.id)"
              @onToggleSelection="updateSelectedUsers"
            />
          </tbody>
        </table>
      </div>
      <p v-if="!isFetching && users.length === 0">
        該当するユーザーが見つかりませんでした
      </p>
      <div class="button-wrapper">
        <SLButton
          black
          text="キャンセル"
          class="cancel-button"
          :on-click="close"
        />
        <SLButton
          type="submit"
          text="参加者を追加する"
          class="submit-button"
          :on-click="updateParticipants"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddParticipantsModalRowForTrainingEvent from '@/components/Modal/trainingEvent/AddParticipantsModalRowForTrainingEvent';
import SelectOrganizationsMenu from '@/components/staff/trainings/trainingEvents/SelectOrganizationsMenu.vue';
import ClickOutside from 'vue-click-outside';
import { mapState } from 'vuex';
import { shortenText } from '@/libs/textFormatter';

export default {
  name: 'AddParticipantsModalForTrainingEvent',
  components: {
    AddParticipantsModalRowForTrainingEvent,
    SelectOrganizationsMenu,
  },
  directives: {
    ClickOutside,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      users: [],
      selectedUsers: [],
      organizations: this.payload.organizations,
      searchWords: '',
      isSelectOrganizationsMenuShown: false,
      selectedOrganizations: [],
      isFetching: false,
      isError: false,
      composing: false,
    };
  },
  computed: {
    ...mapState('pages', {
      participants: (state) => state.trainingEvent.participants,
    }),
    hideStyle() {
      // ユーザーがいない場合はチェックボックスを非表示にする
      return this.users.length === 0 ? 'display: none' : '';
    },
    isSelectedAllUsers() {
      return this.users.every((user) =>
        this.selectedUsers.some(({ id }) => id === user.id),
      );
    },
    selectState() {
      if (this.selectedUsers.length === 0) {
        return 'no-selected';
      }

      return this.isSelectedAllUsers ? 'all-selected' : 'partly-selected';
    },
    searchUsersQuery() {
      const splitPattern = /[,]+/;
      const searchKeywords = this.searchWords.split(splitPattern);
      return searchKeywords.join(' ');
    },
    // 事業所検索のinputに表示する文字列を作成
    formattedOrganizationsName() {
      if (this.selectedOrganizations.length === 0) {
        return '';
      }
      const maxLength = this.$mq === 'pc' ? 10 : 6;
      const firstOrganizationName = shortenText(
        this.selectedOrganizations[0].name,
        maxLength,
      );
      if (this.selectedOrganizations.length > 1) {
        return `${firstOrganizationName} 他${
          this.selectedOrganizations.length - 1
        }件`;
      } else {
        return firstOrganizationName;
      }
    },
  },
  watch: {
    selectedOrganizations() {
      this.refreshUsers();
    },
  },
  async mounted() {
    this.refreshUsers();
    this.selectedUsers = this.participants ? [...this.participants] : [];
  },
  methods: {
    updateAllUsersSelection() {
      if (this.isSelectedAllUsers) {
        this.selectedUsers = [];
      } else {
        this.selectAllUsers();
      }
    },
    selectAllUsers() {
      this.selectedUsers = [...this.users];
    },
    updateSelectedUsers(user) {
      if (this.selectedUsers.some(({ id }) => id === user.id)) {
        this.selectedUsers = this.selectedUsers.filter(({ id }) => {
          return id !== user.id;
        });
      } else {
        this.selectedUsers = [...this.selectedUsers, user];
      }
    },
    isSelectedUser(userId) {
      return this.selectedUsers.some((user) => user.id === userId);
    },
    close() {
      this.$store.dispatch('modal/close');
    },
    updateParticipants() {
      this.$store.commit('pages/updateParticipantsForTrainingEvent', [
        ...this.selectedUsers,
      ]);

      this.close();
    },
    showSelectOrganizationsMenu() {
      this.isSelectOrganizationsMenuShown = true;
    },
    hideSelectOrganizationsMenu() {
      this.isSelectOrganizationsMenuShown = false;
    },
    async updateSelectedOrganizations(selectedOrganizations) {
      this.selectedOrganizations = selectedOrganizations;
    },
    async refreshUsers() {
      // IME入力時にはリフレッシュしない
      if (this.composing) {
        return;
      }

      this.isFetching = true;
      this.isError = false;

      try {
        this.users = await this.payload.getUsers(
          this.searchUsersQuery,
          this.selectedOrganizations,
        );
      } catch (error) {
        this.isError = true;
        console.warn(error);
      }
      this.isFetching = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-participant-modal {
  padding: 40px 35px;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #333;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  z-index: 10010;

  @include mq(lg) {
    padding: 16px;
    top: 3%;
    max-width: 360px;
  }

  .close-icon-wrapper {
    position: relative;
    width: 100%;
    height: 30px;

    .close-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url('~@/assets/img/close_icon.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .modal-title {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .modal-description {
    margin-top: 20px;
    font-size: 1rem;
  }

  .loading-message,
  .error-message {
    display: flex;
    height: 360px;
    align-items: center;
    justify-content: center;
  }

  .participant-container {
    width: 90%;

    @include mq(lg) {
      width: 100%;
    }

    .search-and-select-wrapper {
      margin-top: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mq(lg) {
        margin-bottom: 6px;
        align-items: flex-start;
        flex-direction: column-reverse;
      }

      .user-count {
        margin-left: 6px;
        font-size: 1rem;
        align-self: end;

        @include mq(lg) {
          margin-left: 0px;
          align-self: start;
        }
      }

      .right-colum {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mq(lg) {
          margin-bottom: 20px;
          width: 100%;
          gap: 10px;
        }

        .organization-search {
          width: 100%;
          max-width: 180px;
          position: relative;

          @include mq(lg) {
            flex-basis: 50%;
          }

          .organization-search-input {
            position: relative;
            cursor: pointer;
          }

          .arrow-icon {
            margin: auto;
            margin-right: 4px;
            width: 2rem;
            height: 1rem;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-image: url('~@/assets/img/arrow_under.png');
            background-repeat: no-repeat;
            background-size: 2.5rem;
            background-position: center;
            pointer-events: none;
          }
        }

        input {
          width: 180px;
          height: 30px;
          border: 1px solid #e6e6e6;
          border-radius: 4px;
          font-size: 0.9rem;

          @include mq(lg) {
            height: 40px;
            width: 100%;
          }

          &.search-input {
            margin-left: 12px;
            padding: 0 10px 0 30px;
            background-image: url('~@/assets/img/search_icon.png');
            background-size: 1.2rem;
            background-repeat: no-repeat;
            background-position: 5px center;

            @include mq(lg) {
              margin-left: 0px;
              padding: 0 6px 0 24px;
              flex-basis: 50%;
              background-size: 1rem;
            }
          }
        }

        .search-button {
          margin-left: 12px;
          width: 60px;
          height: 30px;

          @include mq(lg) {
            margin-left: 10px;
            width: 80px;
            display: none;
          }
        }
      }
    }

    .table-container {
      margin-top: 14px;
      width: 100%;
      max-height: 360px;
      overflow-y: auto;

      @include mq(lg) {
        max-height: 200px;
      }

      .participant-table {
        width: 100%;
        border: solid 1px #ccc;
        border-collapse: collapse;

        tr {
          width: 100%;
          border-bottom: solid 1px #ccc;

          th {
            padding: 14px 6px;
            position: relative;
            text-align: left;
            background-color: #f2f2f2;
            border-right: solid 1px #ccc;

            &.header-checkbox {
              @include custom-base-checkbox;
              width: 5%;

              @include mq(lg) {
                width: 10%;
                max-width: 30px;
              }

              &.all-selected {
                @include checkmark-checkbox;
              }

              &.partly-selected {
                @include hyphen-checkbox;
              }
            }

            &.header-code {
              width: 20%;
              min-width: 100px;

              @include mq(lg) {
                display: none;
              }
            }

            &.header-role {
              width: 15%;
              min-width: 95px;

              @include mq(lg) {
                min-width: 55px;
              }
            }

            &.header-name,
            &.header-organization {
              width: 30%;
              min-width: 100px;
            }
          }

          td {
            padding: 14px 6px;
            border-right: solid 1px #ccc;

            &.non-display {
              @include mq(md) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.button-wrapper {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;

  .submit-button {
    margin-left: 20px;
  }
}
</style>
