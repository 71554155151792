export default [
  {
    path: '/recommends',
    redirect: {
      name: 'Home',
    },
  },
  {
    path: '/about',
    beforeEnter: () => {
      window.location = 'https://special-l.link/';
    },
    meta: {
      isPublic: true,
    },
  },
  // 年間研修管理機能実装の際に、研修計画関連のURLを変更したため追加
  {
    path: '/staff/lesson-plans',
    redirect: {
      name: 'TrainingLists',
    },
  },
  {
    path: '/staff/lesson-plans/create',
    redirect: {
      name: 'LessonPlanCreate',
    },
  },
  {
    path: '/staff/lesson-plans/:lessonPlanId',
    redirect: {
      name: 'LessonPlanDetailForOrganizer',
    },
  },
  {
    path: '/staff/lesson-plans/:lessonPlanId/edit',
    redirect: {
      name: 'LessonPlanEdit',
    },
  },
  {
    path: '/staff/lesson-plans/:lessonPlanId/group-learnings/create',
    redirect: {
      name: 'GroupLearningCreate',
    },
  },
  {
    path: '/staff/lesson-plans/:lessonPlanId/group-learnings/:groupLearningId',
    redirect: {
      name: 'GroupLearningDetail',
    },
  },
  {
    path: '/staff/lesson-plans/:lessonPlanId/group-learnings/:groupLearningId/edit',
    redirect: {
      name: 'GroupLearningEdit',
    },
  },
];
