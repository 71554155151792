<template>
  <input v-if="showInput" id="_s_uid" type="hidden" :value="user.id" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'SATORIContainer',
  data() {
    return {
      showInput: false,
    };
  },
  computed: {
    ...mapState('user', {
      user: (state) => state.user,
    }),
    ...mapGetters('user', ['isLogin']),
  },
  watch: {
    isLogin(isLogin) {
      const scriptElement = document.getElementById(
        'satori__creative_container',
      );
      if (!scriptElement) {
        return;
      }

      if (!isLogin) {
        scriptElement.style.display = 'none';
        this.showInput = false;
      } else {
        scriptElement.style.display = 'block';
        this.showInput = true;
      }
    },
  },
};
</script>
