export default {
  path: 'training-events',
  component: () => import('@/views/staff/trainings/trainingEvents/index.vue'),
  meta: {
    title: 'カスタム研修',
    breadcrumb: null,
  },
  children: [
    {
      path: 'create',
      name: 'TrainingEventCreate',
      component: () =>
        import('@/views/staff/trainings/trainingEvents/Create.vue'),
      meta: {
        isPublic: false,
        title: 'カスタム研修の作成',
        desc: 'カスタム研修の作成',
        breadcrumb: 'カスタム研修の作成',
      },
    },
    {
      path: ':trainingEventId',
      component: () =>
        import('@/views/staff/trainings/trainingEvents/DetailIndex.vue'),
      props: true,
      meta: {
        breadcrumb() {
          // 研修計画名を取得
          const trainingEventName = localStorage.getItem('breadcrumbName');
          return trainingEventName ?? '研修計画の詳細';
        },
      },
      children: [
        {
          path: '',
          name: 'TrainingEventDetail',
          component: () =>
            import('@/views/staff/trainings/trainingEvents/Detail.vue'),
          props: true,
          meta: {
            isPublic: false,
            title: '対象のカスタム研修',
            desc: 'カスタム研修の詳細',
            breadcrumb: null,
          },
        },
        {
          path: 'edit',
          name: 'TrainingEventEdit',
          component: () =>
            import('@/views/staff/trainings/trainingEvents/Edit.vue'),
          props: true,
          meta: {
            isPublic: false,
            title: 'カスタム研修の編集',
            desc: 'カスタム研修の編集',
            breadcrumb: 'カスタム研修の編集',
          },
        },
      ],
    },
  ],
};
