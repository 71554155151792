import axios from 'axios';

export default () => {
  // レポートを取得
  const getForStudent = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}`,
    );
    return response.data;
  };

  const getAllForStudent = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 事業所内のユーザー達のレポート一覧を取得
  const getSummariesByOrganizationForOrganizer = async ({
    organizationId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/reports/summary`,
      {
        params: {
          organizationId,
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getAllUsersAndOrganizationForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/reports`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getAllLessonPlanForOrganizer = async ({ limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/lessonPlans/reports/summary`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getAllUsersAndOrganizationAndLessonPlanForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/lessonPlanReports`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getTurn = async ({ role, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@${role}/reports/@turn`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const getTurnBylessonPlan = async ({ role, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@${role}/lessonPlans/reports/@turn`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 所属する指定事業所のレポートの要件取得
  const getRequirementByOrganizationForOrganizer = async ({
    organizationId,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/reports/requirement`,
    );
    return response.data;
  };

  const updateForStudent = async ({ id, contents, isDraft }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}`,
      {
        contents,
        isDraft,
      },
    );
    return response.data;
  };

  const updateForOrganizer = async ({ id, feedback, state }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/reports/${id}`,
      {
        feedback,
        state,
      },
    );
    return response.data;
  };

  // 所属する指定事業所のレポートの要件更新
  const updateRequirementByOrganizationForOrganizer = async ({
    organizationId,
    isEnabled,
    contents,
  }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/reports/requirement`,
      {
        isEnabled,
        contents,
      },
    );
    return response.data;
  };

  // レポートを完了状態にする
  const setToCompletedForStudent = async ({ id }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}/completed`,
    );
    return response.data;
  };

  const registerPictureForStudent = async ({ id, pictureFile }) => {
    const formData = new FormData();
    formData.append('picture', pictureFile);

    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}/pictures`,
      formData,
    );
    return response.data;
  };

  // レポートに紐付けられた画像一覧を取得
  const getPicturesByReportForStudent = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}/pictures`,
    );
    return response.data;
  };

  const getPicturesByReportForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/reports/${id}/pictures`,
    );
    return response.data;
  };

  const removePictureForStudent = async ({ id, pictureId }) => {
    const response = await axios.delete(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}/pictures/${pictureId}`,
    );
    return response.data;
  };

  const reorderPicturesForStudent = async ({ id, pictureIds }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/@student/reports/${id}/pictures/order`,
      pictureIds,
    );
    return response.data;
  };

  // レッスンカテゴリー内のユーザーのレッスンカテゴリーレポート一覧取得
  const getAllByLessonCategoryForStudent = async ({
    lessonCategoryId,
    limit,
    offset,
    states,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonCategories/${lessonCategoryId}/reports`,
      {
        params: {
          limit,
          offset,
          states,
        },
      },
    );
    return response.data;
  };

  const createForStudent = async ({ lessonCategoryId, contents, isDraft }) => {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/~/@student/lessonCategories/${lessonCategoryId}/reports`,
      {
        contents,
        isDraft,
      },
    );
    return response.data;
  };

  const getAllByOrganizationForOrganizer = async ({
    organizationId,
    lessonCategoryId,
    limit,
    offset,
    states,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/reports`,
      {
        params: {
          lessonCategoryId,
          limit,
          offset,
          states,
        },
      },
    );
    return response.data;
  };

  const getByIdForOrganizer = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/reports/${id}`,
    );
    return response.data;
  };

  return {
    getForStudent,
    getAllForStudent,
    getSummariesByOrganizationForOrganizer,
    getAllUsersAndOrganizationForOrganizer,
    getAllLessonPlanForOrganizer,
    getAllUsersAndOrganizationAndLessonPlanForOrganizer,
    getTurn,
    getTurnBylessonPlan,
    getRequirementByOrganizationForOrganizer,
    updateForStudent,
    updateForOrganizer,
    updateRequirementByOrganizationForOrganizer,
    setToCompletedForStudent,
    registerPictureForStudent,
    getPicturesByReportForStudent,
    getPicturesByReportForOrganizer,
    removePictureForStudent,
    reorderPicturesForStudent,
    getAllByLessonCategoryForStudent,
    createForStudent,
    getAllByOrganizationForOrganizer,
    getByIdForOrganizer,
  };
};
