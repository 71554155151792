import { event } from 'vue-gtag';

export const sendEvent = ({
  eventCategory,
  actionName,
  eventLabel = undefined,
  value = undefined,
  nonInteraction = false,
}) => {
  if (!eventCategory || !actionName) {
    return;
  }

  /* eslint-disable camelcase */
  // ライブラリの仕様でプロパティ名がsnake_caseになっているため
  event(actionName, {
    event_category: eventCategory,
    event_label: eventLabel,
    value,
    non_interaction: nonInteraction,
  });
  /* eslint-enable camelcase */
};
