<template>
  <div class="network-messages">
    <p v-if="isFetching" class="loading-message">読み込み中…</p>
    <p v-else-if="isError" class="error-message">データの取得に失敗しました</p>
  </div>
</template>

<script>
export default {
  name: 'NetworkMessages',
  props: {
    isFetching: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-message,
.error-message {
  font-size: 0.9em;
  font-weight: bold;
}
</style>
