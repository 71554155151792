<template>
  <div id="select-organizations-menu">
    <div>
      <input v-model="searchQuery" type="text" placeholder="事業所名" />
    </div>
    <ul
      v-if="innerSelectedOrganizations.length > 0"
      class="mt-4 selection-wrapper"
    >
      <li class="selection">
        <span
          v-for="selectedOrganization in innerSelectedOrganizations"
          :key="selectedOrganization.id"
          class="selection-item"
        >
          <span class="selection-item-name">
            {{ selectedOrganization.name }}
          </span>
          <span
            class="ml-2 close-icon"
            @click.stop="toggleOrganizationSelection(selectedOrganization)"
          ></span>
        </span>
      </li>
    </ul>
    <div
      v-if="innerSelectedOrganizations.length > 0"
      class="clear-all-button-wrapper"
    >
      <span
        class="mt-2 clear-all-button"
        @click.stop="clearAllSelectedOrganizations"
      >
        × 条件削除
      </span>
    </div>

    <div class="organization-list mt-5">
      <div
        v-for="organization in filteredOrganizations"
        :key="organization.id"
        class="organizations-row"
        :class="organizationRowStyle(organization)"
        @click="toggleOrganizationSelection(organization)"
      >
        <span class="checkbox"></span>
        <span class="ml-1 name">
          {{ organization.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectOrganizationsMenu',
  props: {
    selectedOrganizations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      innerSelectedOrganizations: [],
      searchQuery: '',
    };
  },
  computed: {
    ...mapState('user', {
      organizations: (state) => state.organizations,
    }),
    selectionStyle() {
      return this.isSelected ? 'selected' : '';
    },
    // 検索キーワードに一つでもマッチする事業所を返す
    filteredOrganizations() {
      if (!this.searchQuery) {
        return this.organizations;
      }

      // 半角スペース、"[コンマ]"で検索ワードを分割する
      const splitPattern = /[\s,]+/;
      const searchKeywords = this.searchQuery.split(splitPattern);

      return this.organizations.filter((organization) => {
        return searchKeywords.some((keyword) => {
          return organization.name.includes(keyword);
        });
      });
    },
  },
  watch: {
    innerSelectedOrganizations() {
      this.$emit(
        'onSelectedOrganizationsUpdated',
        this.innerSelectedOrganizations,
      );
    },
  },
  mounted() {
    this.innerSelectedOrganizations = this.selectedOrganizations;
  },
  methods: {
    clearAllSelectedOrganizations() {
      this.innerSelectedOrganizations = [];
    },
    isSelected(organization) {
      return this.innerSelectedOrganizations.some((selectedOrganization) => {
        return selectedOrganization.id === organization.id;
      });
    },
    toggleOrganizationSelection(organization) {
      if (this.isSelected(organization)) {
        this.innerSelectedOrganizations =
          this.innerSelectedOrganizations.filter(({ id }) => {
            return id !== organization.id;
          });
      } else {
        this.innerSelectedOrganizations = [
          ...this.innerSelectedOrganizations,
          organization,
        ];
      }
    },
    organizationRowStyle(organization) {
      return this.isSelected(organization) ? 'selected' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
#select-organizations-menu {
  padding: 20px;
  width: 260px;
  max-height: 470px;
  position: absolute;
  top: 35px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  box-shadow: 0px 3px 6px 0 #00000029;
  z-index: 11000;
  overflow: auto;

  @include mq(md) {
    max-height: 350px;
  }

  input {
    padding: 0 10px 0 30px;
    width: 100%;
    height: 32px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    background-image: url('~@/assets/img/search_icon.png');
    background-size: 1.2rem;
    background-repeat: no-repeat;
    background-position: 5px center;

    @include mq(lg) {
      width: 90%;
      height: 40px;
    }
  }

  .selection-wrapper {
    padding: 6px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;

    .selection {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-item {
        margin-top: 4px;
        padding: 4px 10px 4px 16px;
        display: flex;
        align-items: center;
        background-color: #eee;
        border-radius: 15px;
        color: #4d4d4d;

        &-name {
          @include one-line-ellipsis;
        }

        &:first-child {
          margin-top: 0;
        }

        .close-icon {
          width: 18px;
          min-width: 18px;
          height: 18px;
          border-radius: 50%;
          border: none;
          background-color: #fff;
          background-image: url('~@/assets/img/close_icon.png');
          background-size: 0.9rem;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }
      }
    }
  }

  .clear-all-button-wrapper {
    text-align: right;

    .clear-all-button {
      text-align: right;
      border: none;
      background-color: unset;
      color: #666666;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .organization-list {
    .organizations-row {
      margin-top: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      .checkbox {
        @include custom-base-checkbox;
        width: 10%;
      }

      &.selected {
        .checkbox {
          @include checkmark-checkbox;
        }
      }

      .name {
        @include one-line-ellipsis;
        width: 90%;
        color: #4d4d4d;
      }
    }
  }
}
</style>
