<template>
  <div>
    <div>
      <div class="cookie-banner">
        <p>
          当サイトでは、利用状況の分析などのためにCookieを利用しています。<br />
          Cookieの仕組みや送信先などについては、以下の
          <a href="https://leanonme.co.jp/privacy/#privacy" target="_blank"
            >「プライバシーポリシー」</a
          >
          から
          <a href="https://leanonme.co.jp/privacy/#cookie" target="_blank"
            >「Cookieポリシーについて」</a
          >
          をご確認いただき、Cookieの利用に同意の上、当サイトをご利用ください。
        </p>
        <div class="answer">
          <label>
            <input v-model="notShowAgain" type="checkbox" />
            今後表示しない</label
          >
          <SLButton
            class="btn"
            text="同意する"
            :on-click="saveCookieConsentToken"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCookieConsent } from '@/libs/cookieConsentManager';

export default {
  name: 'CookieBanner',
  data() {
    return {
      notShowAgain: false,
    };
  },
  methods: {
    saveCookieConsentToken() {
      try {
        if (this.notShowAgain) {
          localStorage.setItem('cookieConsent', useCookieConsent().token);
          this.$emit('close');
          return;
        }

        sessionStorage.setItem('cookieConsent', useCookieConsent().token);
        this.$emit('close');
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-banner {
  padding: 20px;
  width: calc(100% / 2);
  max-width: 500px;
  position: fixed;
  left: 2%;
  bottom: 3%;
  background-color: #333333d9;
  border-radius: 5px;
  z-index: 10100;
  text-align: left;
  font-size: 1rem;

  @include mq(md) {
    margin: 2% 1%;
    padding: 20px;
    width: 98%;
    max-width: 100%;
    left: 0;
    bottom: 0;
    font-size: 1.2rem;
  }

  p {
    margin-bottom: 10px;
    letter-spacing: 0.05em;
    color: #fff;

    a {
      font-weight: bold;
      color: #fc9b9b;
      text-decoration: underline;
    }
  }

  .answer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include mq(md) {
      margin-bottom: 6px;
    }

    label {
      margin-right: 10px;
      display: flex;
      align-items: center;
      color: #fff;
    }

    input {
      margin-right: 10px;

      @include mq(md) {
        scale: 1.5;
      }
    }
  }

  .btn {
    width: 100px;
    height: 30px;
    font-weight: normal;

    &:hover {
      background-color: #feabaa;
      border: #feabaa;
      opacity: 1;
    }

    @include mq(md) {
      width: 120px;
      height: 48px;
      font-size: 1.2rem;
      border-radius: 50px;
    }
  }
}
</style>
