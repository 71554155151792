<template>
  <div class="remove-training-modal">
    <div class="close-icon" @click="close" />
    <div class="messages">
      <h2>{{ modalHeadline }}</h2>
      <div>
        「
        <PrefixIcon
          :text="deletingTrainingPrefix"
          :black="payload.trainingType === 'カスタム研修'"
        />
        {{ deletingTrainingName }}」がリストから削除されます。
      </div>
      <div class="buttons">
        <SLButton
          ref="cancelButton"
          black
          text="キャンセル"
          :on-click="close"
          tabindex="1"
        />
        <SLButton text="削除する" :on-click="removeTraining" tabindex="2" />
      </div>
    </div>
  </div>
</template>

<script>
import PrefixIcon from '@/components/common/PrefixIcon.vue';

export default {
  name: 'RemoveTrainingModal',
  components: {
    PrefixIcon,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalHeadline() {
      return `${this.payload.trainingType}を削除しますか？`;
    },
    deletingTrainingPrefix() {
      return this.payload.prefix;
    },
    deletingTrainingName() {
      return this.payload.trainingName;
    },
  },
  mounted() {
    // モーダルを開いた時に、モーダル内にフォーカスする
    this.$refs.cancelButton.$el.focus();
  },
  methods: {
    async removeTraining() {
      await this.payload.onRemove(this.payload.id);
      await this.payload.onClose();
      this.close();
    },
    close() {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-training-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 95%;
  max-width: 450px;
  height: auto;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/img/close_icon.png');
    background-size: 20px;
    background-position: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .messages {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttons {
      margin-top: 24px;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      > button {
        width: 120px;
      }
    }
  }
  .prefix-highlight {
    padding: 0 5px;
    background: linear-gradient(transparent 0%, #ff7171 0%);
    color: #ffffff;
    border-radius: 3px;
  }
}
</style>
