<template>
  <footer class="footer">
    <div class="footer-main clearfix">
      <div class="footer-logo"></div>
      <div class="link-category">
        <p class="footer-title">Menu</p>
        <div class="footer-link clearfix">
          <ul>
            <li>
              <router-link
                :to="{ name: 'Home' }"
                @click.native="onClickInnerLink('Home')"
                >ホーム</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'InformationList' }"
                @click.native="onClickInnerLink('InformationList')"
                >お知らせ</router-link
              >
            </li>
            <li>
              <span @click="onClickExternalLink('https://special-l.link')">
                Special Learningとは?
              </span>
            </li>
            <li>
              <router-link
                :to="{ name: 'UserContentsList' }"
                @click.native="onClickInnerLink('UserContentsList')"
                >コンテンツ一覧</router-link
              >
            </li>
            <li v-if="isLogin">
              <span
                @click="
                  onClickExternalLink('https://manual.special-learning.jp/')
                "
              >
                利用マニュアル
              </span>
            </li>
            <li>
              <router-link
                :to="{ name: 'QuestionsAndAnswers' }"
                @click.native="onClickInnerLink('QuestionsAndAnswers')"
                >よくある質問</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'Terms' }"
                @click.native="onClickInnerLink('Terms')"
                >利用規約</router-link
              >
            </li>
            <li>
              <span
                @click="
                  onClickExternalLink('https://leanonme.co.jp/privacy/#privacy')
                "
              >
                プライバシーポリシー
              </span>
            </li>
            <li>
              <span
                @click="
                  onClickExternalLink(
                    'https://leanonme.co.jp/privacy/#web_accessibility',
                  )
                "
              >
                ウェブアクセシビリティ
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="link-category last">
        <p class="footer-title">Company</p>
        <div class="footer-link clearfix">
          <ul>
            <li>
              <span @click="onClickExternalLink('https://leanonme.co.jp')">
                運営会社
              </span>
            </li>
            <li>
              <span @click="onClickExternalLink('https://leanonme.co.jp/news')">
                お知らせ
              </span>
            </li>
            <li>
              <span
                @click="
                  onClickExternalLink('https://leanonme.co.jp/philosophy')
                "
              >
                理念
              </span>
            </li>
            <li>
              <span
                @click="onClickExternalLink('https://leanonme.co.jp/service')"
              >
                事業内容
              </span>
            </li>
            <li>
              <span
                @click="onClickExternalLink('https://leanonme.co.jp/company')"
              >
                企業情報
              </span>
            </li>
            <li>
              <span
                @click="
                  onClickExternalLink(
                    'https://leanonme.co.jp/about-lean-on-me/recruit',
                  )
                "
              >
                採用情報
              </span>
            </li>
            <li>
              <span
                @click="onClickExternalLink('https://special-l.link/#contact')"
              >
                お問合わせ
              </span>
            </li>
          </ul>
        </div>
      </div>
      <a
        v-if="isShowScrollButton"
        v-scroll-to="'#header'"
        class="scroll-top"
        :class="{ active: isShowScrollButton }"
      ></a>
    </div>
    <div class="copyright">
      Copyright 2016-{{ currentYear }} Lean on Me Inc. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { sendEvent } from '@/libs/analytics';
import { mapGetters } from 'vuex';
import repository from '@/api/apiRepositoryFactory';

export default {
  name: 'FooterView',
  data() {
    return {
      currentYear: 0,
      scrollY: 0,
      seminarCount: 0,
    };
  },
  computed: {
    ...mapGetters('user', ['isLogin']),
    isShowScrollButton() {
      return this.scrollY > 1000;
    },
  },
  created() {
    //年数を取得
    const date = new Date();
    this.currentYear = date.getFullYear();
  },
  mounted() {
    // スクロールを取得
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
    if (this.isLogin) {
      this.getSeminarCount();
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    // スクロール値の取得
    onScroll() {
      this.scrollY = window.pageYOffset;
    },
    onClickInnerLink(route) {
      const href = this.$router.resolve({
        name: route,
      }).href;

      sendEvent({
        eventCategory: 'links',
        actionName: 'click_footer_link',
        eventLabel: href,
      });
    },
    onClickExternalLink(url) {
      sendEvent({
        eventCategory: 'links',
        actionName: 'click_footer_link',
        eventLabel: url,
      });

      window.open(url, '_blank', 'noreferrer');
    },
    // セミナー総数を取得する
    async getSeminarCount() {
      const seminarApi = repository.seminars();

      try {
        this.seminarCount = await seminarApi.getCountAllForUser();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  background-color: #f2f2f2;

  .footer-main {
    width: 1024px;
    margin: 0 auto;
    padding: 20px 5px;
    position: relative;

    @include mq(md) {
      width: 100%;
      padding: 30px 10px;
    }

    @include mq(xl) {
      width: 1200px;
    }

    .footer-logo {
      width: 250px;
      height: 130px;
      margin-right: 80px;
      background-image: url('~@/assets/img/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      float: left;

      @include mq(md) {
        display: none;
      }
    }

    .link-category {
      margin-bottom: 30px;
      float: left;

      &.last {
        margin-bottom: 0;
      }

      .footer-title {
        width: 100%;
        margin: 0 auto;
        font-size: 1.5rem;
        font-weight: bold;

        @include mq(md) {
          width: 90%;
        }
      }

      .footer-link {
        @include mq(md) {
          width: 90%;
          margin: 0 auto;
        }

        ul {
          float: left;
          margin: 5px 40px 0 10px;
          column-count: 2;

          @include mq(md) {
            margin: auto;
            column-count: auto;
          }

          li {
            cursor: pointer;

            @include mq(md) {
              float: left;
              margin: 5px 10px 5px 0;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }

  .scroll-top {
    display: none;

    &.active {
      display: block;
      position: fixed;
      bottom: 175px;
      right: 20px;
      width: 50px;
      height: 50px;
      background-image: url('~@/assets/img/scroll.png');
      background-size: cover;
      z-index: 100;
      transition: all 0.5s;
      opacity: 1;
      cursor: pointer;

      @include mq(md) {
        display: none;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .copyright {
    margin: 0;
    padding: 5px;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.2rem;
    background-color: #fff;

    @include mq(md) {
      padding: 15px 0;
    }
  }
}
</style>
