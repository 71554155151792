import axios from 'axios';

export default () => {
  const getAll = async ({ limit, offset, sort, order }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonCategories`,
      {
        params: {
          limit,
          offset,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  const getAllForUser = async ({ limit, offset, sort, order }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories`,
      {
        params: {
          limit,
          offset,
          sort,
          order,
        },
      },
    );
    return response.data;
  };

  const getForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/${id}`,
    );
    return response.data;
  };

  const getByCode = async ({ code }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonCategories/@code=${encodeURI(
        code,
      )}`,
    );
    return response.data;
  };

  const getByOrganizationGroupsForUser = async ({ organizationGroupId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/organizationGroups/${organizationGroupId}/lessonCategories`,
    );
    return response.data;
  };

  const getByCodeForUser = async ({ code }) => {
    const response = await axios.get(
      `${
        process.env.VUE_APP_API_URL
      }/users/~/lessonCategories/@code=${encodeURI(code)}`,
    );
    return response.data;
  };

  const getSummary = async ({ lessonCategoryId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonCategories/${lessonCategoryId}/lessons/summary`,
    );
    return response.data;
  };

  const getSummaryForUser = async ({ lessonCategoryId }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/${lessonCategoryId}/lessons/summary`,
    );
    return response.data;
  };

  const getStudyMemoForUser = async ({ id }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/${id}/studyMemo`,
    );
    return response.data;
  };

  // 事業所内の指定ユーザーが受講済みのレッスンが一つでもあるレッスンカテゴリー一覧取得
  const getStudiedAllByUserAndOrganizationForOrganizer = async ({
    organizationId,
    userId,
    limit,
    offset,
  }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/@organizer/organizations/${organizationId}/users/${userId}/lessonCategories/@studied`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // 目次（中分類）内のレッスンカテゴリーの一覧を取得する
  const getAllByMediumSection = async function ({
    mediumSectionId,
    limit,
    offset,
  } = {}) {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/mediumSections/${mediumSectionId}/lessonCategories`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // ユーザーが受講可能な目次（中分類）内のレッスンカテゴリーの一覧を取得する
  const getAllByMediumSectionForUser = async ({
    mediumSectionId,
    limit,
    offset,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/mediumSections/${mediumSectionId}/lessonCategories`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  // セミナーに紐づくレッスンカテゴリー一覧を取得する
  const getAllBySeminarForUser = async ({ seminarId, limit, offset }) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/seminars/${seminarId}/lessonCategories`,
      {
        params: {
          limit,
          offset,
        },
      },
    );
    return response.data;
  };

  const countPublicAll = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/lessonCategories/count`,
    );
    return response.data;
  };

  const countAllForUser = async () => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/count`,
    );
    return response.data;
  };

  // 目次（中分類）内のレッスンカテゴリー数を取得する
  const countAllByMediumSection = async ({ mediumSectionId, query } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/mediumSections/${mediumSectionId}/lessonCategories/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  // ユーザーが受講可能な目次（中分類）内のレッスンカテゴリー数を取得する
  const countAllByMediumSectionForUser = async ({
    mediumSectionId,
    query,
  } = {}) => {
    const response = await axios.get(
      `${process.env.VUE_APP_API_URL}/users/~/mediumSections/${mediumSectionId}/lessonCategories/count`,
      {
        params: {
          q: query,
        },
      },
    );
    return response.data;
  };

  const updateStudyMemoForUser = async ({ id, contents }) => {
    const response = await axios.put(
      `${process.env.VUE_APP_API_URL}/users/~/lessonCategories/${id}/studyMemo`,
      {
        contents,
      },
    );
    return response.data;
  };

  return {
    getAll,
    getAllForUser,
    getForUser,
    getByCode,
    getByOrganizationGroupsForUser,
    getByCodeForUser,
    getSummary,
    getSummaryForUser,
    getStudyMemoForUser,
    getStudiedAllByUserAndOrganizationForOrganizer,
    getAllByMediumSection,
    getAllByMediumSectionForUser,
    getAllBySeminarForUser,
    countPublicAll,
    countAllForUser,
    countAllByMediumSection,
    countAllByMediumSectionForUser,
    updateStudyMemoForUser,
  };
};
