<template>
  <div id="external-link-modal">
    <div class="close-icon" @click="close" />
    <div class="modal-wrapper">
      <h2>外部サイトへ移動します</h2>
      <div class="mt-4 messages">
        <p>
          これより先は、Special
          Learning以外のサイトに移動します。以下の外部サイトに移動しますか？
        </p>
        <span class="mt-4">リンク先URL:</span>
        <span class="external-link-url">{{ payload.url }}</span>
      </div>
      <div class="buttons">
        <SLButton
          ref="cancelButton"
          black
          text="キャンセル"
          :on-click="close"
          tabindex="1"
        />
        <SLButton text="移動する" :on-click="goToLink" tabindex="2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExternalLinkModal',
  props: {
    payload: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // モーダルを開いた時に、モーダル内にフォーカスする
    this.$refs.cancelButton.$el.focus();
  },
  methods: {
    goToLink() {
      window.open(this.payload.url, '_blank', 'noreferrer');
      this.close();
    },
    close() {
      this.$store.dispatch('modal/close');
    },
  },
};
</script>

<style lang="scss" scoped>
#external-link-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px;
  width: 95%;
  max-width: 450px;
  height: auto;
  z-index: 10010;
  background-color: #fff;
  border-radius: 10px;

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/img/close_icon.png');
    background-size: 20px;
    background-position: 15px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .messages {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .external-link-url {
        @include two-line-ellipsis;
        text-decoration: underline;
      }
    }

    .buttons {
      margin-top: 24px;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      > button {
        width: 120px;
      }
    }
  }
}
</style>
